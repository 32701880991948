var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "form row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('Certificate kind'),
      "vid": "certificate_kind",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('euro-select', {
          attrs: {
            "searchable": "",
            "multiselect-props": _vm.certificateKindSelectProps,
            "label": "".concat(_vm.$t('Certificate kind'), "*"),
            "error-messages": errors
          },
          model: {
            value: _vm.form.certificate_kind,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "certificate_kind", $$v);
            },
            expression: "form.certificate_kind"
          }
        })];
      }
    }])
  })], 1), _c('div', {
    staticClass: "col-12"
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('Doctor'),
      "vid": "doctor"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('euro-input', {
          attrs: {
            "label": _vm.$t('Doctor'),
            "error-messages": errors
          },
          model: {
            value: _vm.form.doctor,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "doctor", $$v);
            },
            expression: "form.doctor"
          }
        })];
      }
    }])
  })], 1), _c('div', {
    staticClass: "col-12"
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('Job duty'),
      "vid": "job_duty",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var errors = _ref3.errors;
        return [_c('euro-input', {
          attrs: {
            "label": "".concat(_vm.$t('Job duty'), "*"),
            "error-messages": errors
          },
          model: {
            value: _vm.form.job_duty,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "job_duty", $$v);
            },
            expression: "form.job_duty"
          }
        })];
      }
    }])
  })], 1), _c('div', {
    staticClass: "col-6"
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('Valid from'),
      "vid": "valid_from",
      "rules": "required|before_if:@date_to"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var errors = _ref4.errors;
        return [_c('date-picker', {
          attrs: {
            "label": "".concat(_vm.$t('Valid from'), "*"),
            "overrides": _vm.validFromOverrides,
            "error-messages": errors
          },
          model: {
            value: _vm.form.valid_from,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "valid_from", $$v);
            },
            expression: "form.valid_from"
          }
        })];
      }
    }])
  })], 1), _c('div', {
    staticClass: "col-6"
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('Valid to'),
      "vid": "valid_to",
      "rules": "required|after_if:@date_from}"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var errors = _ref5.errors;
        return [_c('date-picker', {
          attrs: {
            "label": "".concat(_vm.$t('Valid to'), "*"),
            "error-messages": errors,
            "overrides": _vm.validToOverrides
          },
          model: {
            value: _vm.form.valid_to,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "valid_to", $$v);
            },
            expression: "form.valid_to"
          }
        })];
      }
    }])
  })], 1), _c('div', {
    staticClass: "col-12"
  }, [_c('validation-provider', {
    staticClass: "w-100",
    attrs: {
      "name": _vm.$t('Attachment'),
      "vid": "attachment_tus"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var _vm$form, _vm$form2, _vm$form3;
        var errors = _ref6.errors;
        return [_c('div', {
          attrs: {
            "id": "attachmentFile"
          }
        }, [_c('euro-tus-file-input', {
          attrs: {
            "label": "".concat(_vm.$t('Attachment'), "*"),
            "error-messages": errors,
            "disabled": !_vm.form.job_duty || !((_vm$form = _vm.form) !== null && _vm$form !== void 0 && _vm$form.certificate_kind),
            "accept": "application/pdf",
            "hint": _vm.$t('Accept .pdf - max size 20MB'),
            "attachment": _vm.existingForm.attachment
          },
          on: {
            "change": function change(tusId) {
              _vm.form.attachment_tus = tusId;
            }
          }
        }), !_vm.form.job_duty || !((_vm$form2 = _vm.form) !== null && _vm$form2 !== void 0 && _vm$form2.certificate_kind) || !((_vm$form3 = _vm.form) !== null && _vm$form3 !== void 0 && _vm$form3.valid_to) || !_vm.form.valid_from ? _c('b-tooltip', {
          attrs: {
            "target": "attachmentFile"
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("Enter the above required data first")) + " ")]) : _vm._e()], 1)];
      }
    }])
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }