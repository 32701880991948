<template>
  <div class="form row">
    <div class="col-12">
      <validation-provider v-slot="{ errors }" :name="$t('Country')" vid="country" rules="required">
        <euro-select v-model="form.country" :options="countriesSelect" :error-messages="errors" :label="`${$t('Country')}*`"
          searchable>
          <template #label>
            <i v-b-tooltip :title="$t('This is the country where the education document has been obtained')"
              :class="icons.lineAwesome.info"></i>
          </template>
        </euro-select>
      </validation-provider>
    </div>
    <div class="col-12">
      <validation-provider v-slot="{ errors }" :name="$t('Education level')" vid="level" rules="required">
        <euro-select v-model="form.level" :options="educationKindSelect" :label="`${$t('Education level')}*`"
          :error-messages="errors"></euro-select>
      </validation-provider>
    </div>
    <div class="col-12">
      <validation-provider v-slot="{ errors }" :name="$t('Attainment date')" vid="attainment_date">
        <date-picker v-model="form.attainment_date" :label="$t('Attainment date')" :overrides="attainmentDateOverrides"
          :error-messages="errors"></date-picker>
      </validation-provider>
    </div>
    <div class="col-12">
      <validation-provider v-slot="{ errors }" :name="$t('Attachment')" vid="attachment_tus" class="w-100">
        <div id="attachmentFile">
          <euro-tus-file-input :label="$t('Attachment')" :error-messages="errors"
            :disabled="(form?.level !== 0 && !form?.level) || !form?.country" accept="application/pdf"
            :hint="$t('Accept .pdf - max size 20MB')" :attachment="existingForm.attachment" @change="(tusId) => {
              form.attachment_tus = tusId;
            }
              "></euro-tus-file-input>
          <b-tooltip v-if="(form?.level !== 0 && !form?.level) || !form?.country" target="attachmentFile">
            {{ $t("Enter the above required data first") }}
          </b-tooltip>
        </div>
      </validation-provider>
    </div>
  </div>
</template>

<script>
import DatePicker from "@/view/components/datePicker/datePicker.vue";
import { getCounterparts } from "@/core/abstractions/company";
import icons from "@/core/config/icons";

export default {
  components: {
    DatePicker,
  },

  props: {
    existingForm: {
      type: Object,
      default: null,
    },
    countries: {
      type: Array,
      required: true,
    },
    educationKind: {
      type: Array,
      required: true,
    },
  },

  data: () => ({
    icons,
    form: {
      country: "",
      counterpart: "",
      level: "",
      attainment_date: "",
      attachment_tus: "",
    },
    counterparts: [],
  }),

  computed: {
    countriesSelect() {
      return this.countries
        .map((x) => ({ text: this.$t(x.name), value: x.iso }))
        .sort((a, b) => a.text.localeCompare(b.text));
    },

    educationKindSelect() {
      return this.educationKind.map((el) => ({ value: el.value, text: el.display_name }));
    },

    attainmentDateOverrides() {
      return {
        maxDate: new Date(),
      };
    },

    counterpartsByCountry() {
      if (!this.form.country) {
        return this.counterparts;
      }

      return this.counterparts.filter((el) => el.country_of_establishment === this.form.country);
    },
  },

  watch: {
    form: {
      deep: true,
      handler(val) {
        this.$emit("input", val);
      },
    },
  },

  async mounted() {
    if (this.existingForm) {
      this.form = { ...this.form, ...this.existingForm };
    }
  },

  methods: {
    async loadCounterparts() {
      this.counterparts = await getCounterparts({ isAdministrativeStructure: false });
    },

    onCounterpartCreated(counterpart) {
      this.loadCounterparts();
      this.form.counterpart = counterpart.id;
    },
  },
};
</script>
