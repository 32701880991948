<template>
  <div class="form row">
    <div class="col-12">
      <validation-provider v-slot="{ errors }" :name="$t('Certificate kind')" vid="certificate_kind" rules="required">
        <euro-select v-model="form.certificate_kind" searchable :multiselect-props="certificateKindSelectProps"
          :label="`${$t('Certificate kind')}*`" :error-messages="errors" />
      </validation-provider>
    </div>
    <div class="col-12">
      <validation-provider v-slot="{ errors }" :name="$t('Doctor')" vid="doctor">
        <euro-input v-model="form.doctor" :label="$t('Doctor')" :error-messages="errors"></euro-input>
      </validation-provider>
    </div>
    <div class="col-12">
      <validation-provider v-slot="{ errors }" :name="$t('Job duty')" vid="job_duty" rules="required">
        <euro-input v-model="form.job_duty" :label="`${$t('Job duty')}*`" :error-messages="errors"></euro-input>
      </validation-provider>
    </div>
    <div class="col-6">
      <validation-provider v-slot="{ errors }" :name="$t('Valid from')" vid="valid_from"
        rules="required|before_if:@date_to">
        <date-picker v-model="form.valid_from" :label="`${$t('Valid from')}*`" :overrides="validFromOverrides"
          :error-messages="errors" />
      </validation-provider>
    </div>
    <div class="col-6">
      <validation-provider v-slot="{ errors }" :name="$t('Valid to')" vid="valid_to"
        rules="required|after_if:@date_from}">
        <date-picker v-model="form.valid_to" :label="`${$t('Valid to')}*`" :error-messages="errors"
          :overrides="validToOverrides" />
      </validation-provider>
    </div>
    <div class="col-12">
      <validation-provider v-slot="{ errors }" :name="$t('Attachment')" vid="attachment_tus" class="w-100">
        <div id="attachmentFile">
          <euro-tus-file-input :label="`${$t('Attachment')}*`" :error-messages="errors"
            :disabled="!form.job_duty || !form?.certificate_kind" accept="application/pdf"
            :hint="$t('Accept .pdf - max size 20MB')" :attachment="existingForm.attachment" @change="tusId => {
              form.attachment_tus = tusId;
            }
              "></euro-tus-file-input>
          <b-tooltip v-if="!form.job_duty || !form?.certificate_kind || !form?.valid_to || !form.valid_from"
            target="attachmentFile">
            {{ $t("Enter the above required data first") }}
          </b-tooltip>
        </div>
      </validation-provider>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { backendErrorSwal } from "@/core/helpers/swal";
import DateService from "@/core/services/date.service";
import DatePicker from "@/view/components/datePicker/datePicker.vue";
import physicalPersonSuitabilityWorkService from "@/core/services/physical-person/physical-person-suitability-work.service";

export default {
  components: {
    DatePicker,
  },

  props: {
    physicalPerson: {
      type: Object,
      default: null,
    },
    existingForm: {
      type: Object,
      default: null,
    },
    certificateOptions: {
      type: Array,
      default: () => [],
    },
  },

  data: () => ({
    certificateKindOptions: [],
    form: {
      job_duty: "",
      certificate_kind: "",
      doctor: "",
      date_of_issue: null,
      valid_from: null,
      valid_to: null,
      attachment_tus: null,
    },
  }),

  computed: {
    computedCertificateOptions() {
      return this.certificateKindOptions;
    },
    certificateKindSelectProps() {
      return {
        options: this.computedCertificateOptions,
        label: "display_name",
        "track-by": "value",
        multiple: false,
        "allow-empty": false,
        searchable: true,
        "show-labels": false,
      };
    },
    validFromOverrides() {
      const out = {
        useCurrent: false,
      };

      if (this.form.date_of_issue) {
        out.minDate = moment(this.form.date_of_issue).toDate();
      }

      if (this.form.valid_to && this.form.valid_to != "Invalid date") {
        out.maxDate = moment(this.form.valid_to).toDate();
      }

      return out;
    },

    validToOverrides() {
      const out = {
        useCurrent: false,
      };

      if (this.form.valid_from) {
        out.minDate = DateService.toDate(this.form.valid_from);
      }

      return out;
    },

    dateOfIssueOverrides() {
      return {
        maxDate: moment().toDate(),
      };
    },
  },

  watch: {
    form: {
      deep: true,
      handler(val) {
        this.$emit("input", val);
      },
    },
  },

  async mounted() {
    if (this.existingForm) {
      this.form = { ...this.form, ...this.existingForm };
    }
    if (!this.certificateOptions?.length) {
      try {
        const {
          actions: {
            POST: {
              certificate_kind: { choices },
            },
          },
        } = await physicalPersonSuitabilityWorkService.getOptions();

        this.certificateKindOptions = choices;
      } catch (err) {
        backendErrorSwal(err?.response?.data?.detail, err);
      }
    }
  },

  methods: {
    moment,
  },
};
</script>
