<template>
  <validation-observer ref="validationObserver" v-slot="{ handleSubmit }">
    <b-modal id="create-registration-certificate-modal" hide-footer @hidden="onModalHidden">
      <template #modal-header>
        <div class="w-100 h-100 d-flex justify-content-between align-items-center">
          <template v-if="isEdit">
            <h4 class="mb-0">{{ $t("Update National CCIAA Certificate") }}</h4>
          </template>
          <template v-else>
            <h4 class="mb-0">{{ $t("Create National CCIAA Certificate") }}</h4>
          </template>
          <div class="d-flex align-items-center">
            <b-button variant="success" class="mr-2" :class="{ 'spinner spinner-light spinner-right': isSaving }"
              @click="handleSubmit(submit)">
              {{ $t("Save") }}
            </b-button>
            <b-button @click="closeModal">
              {{ $t('Cancel') }}
            </b-button>
          </div>
        </div>
      </template>

      <legal-person-registration-certificate-form :legal-person="legalPerson"
        :default-country="legalPerson.country_of_establishment" :existing-form="existingForm"
        @input="onLegalPersonRegistrationCertificateFormInput"></legal-person-registration-certificate-form>
    </b-modal>
  </validation-observer>
</template>

<script>
import LegalPersonRegistrationCertificateForm from "@/view/components/forms/legal-person/LegalPersonRegistrationCertificateForm.vue";
import CompanyRegistrationCertificateService from "@/core/services/company/company-registration-certificate.service";
import icons from "@/core/config/icons";
import { backendErrorSwal } from "@/core/helpers/swal";
import Swal from "sweetalert2";

export default {
  components: {
    LegalPersonRegistrationCertificateForm,
  },

  props: {
    legalPerson: {
      type: Object,
      required: true,
    },
    countries: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    existingForm: {
      type: Object,
      default: () => true,
    }
  },

  data() {
    return {
      icons,
      form: null,
      isSaving: false,
      apiErrors: null,
    };
  },

  computed: {
    isEdit() {
      return Object.keys(this.existingForm).length > 0
    }
  },

  watch: {
    apiErrors: {
      deep: true,
      handler(val) {
        const formObs = this.$refs.validationObserver;
        const newErrors = {
          country: [...(val?.country ?? [])],
          registration_number: [...(val?.registration_number ?? [])],
          from_date: [...(val?.from_date ?? [])],
          to_date: [...(val?.to_date ?? [])],
          address: [...(val?.address ?? [])],
          evat: [...(val?.evat ?? [])],
          attachment_tus: [...(val?.attachment_tus ?? [])],
        };
        formObs.setErrors({ ...newErrors });
      },
    },
  },

  methods: {
    closeModal() {
      this.$bvModal.hide("create-registration-certificate-modal");
    },

    onModalHidden() {
      this.form = null;
    },

    async submit() {
      this.isSaving = true;
      try {
        if (this.isEdit) {
          await CompanyRegistrationCertificateService.update(this.form, this.form.id)
        } else {
          await CompanyRegistrationCertificateService.create({ ...this.form, company: this.legalPerson.id });
        }
        this.$emit("refresh");
        this.closeModal();

        let title = this.$t("Document created")
        if (this.isEdit) {
          title = this.$t("Document Updated")
        }
        Swal.fire(this.$t("Success"), title, "success");
      } catch (err) {
        console.log(err);
        if (err?.response?.status === 400) {
          this.apiErrors = err.response.data;
          let message = this.$t("Failed to create the document. Missing required fields.");
          backendErrorSwal(message, err);
        } else {
          let message = err?.response?.data?.detail ?? this.$t("Failed to create the document. Please try again.")
          if (this.isEdit) {
            message = err?.response?.data?.detail ?? this.$t("Failed to update the document. Please try again.")
          }
          backendErrorSwal(message, err);
        }
      } finally {
        this.isSaving = false;
      }
    },

    onLegalPersonRegistrationCertificateFormInput(form) {
      this.form = form;
    },
  },
};
</script>
