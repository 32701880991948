<template>
  <div class="form row">
    <div class="col-12">
      <validation-provider v-slot="{ errors }" :name="$t('Language')" vid="language" rules="required">
        <euro-select v-model="form.language" :options="languagesSelect" :label="`${$t('Select a language')}*`"
          :error-messages="errors" searchable></euro-select>
      </validation-provider>
    </div>
    <div class="col-12">
      <validation-provider v-slot="{ errors }" :name="$t('Language level')" vid="level" rules="required">
        <euro-select v-model="form.level" :options="languageKindSelect" :label="`${$t('Language level')}*`"
          :error-messages="errors"></euro-select>
      </validation-provider>
    </div>
    <div class="col-12">
      <validation-provider v-slot="{ errors }" :name="$t('Certification')" vid="certification">
        <b-form-checkbox v-model="form.certification" class="my-5" :value="true" :unchecked-value="false"
          :error-messages="errors" size="lg">
          {{ $t("Certification") }}
        </b-form-checkbox>
      </validation-provider>
    </div>
    <template v-if="form.certification">
      <div class="col-12">
        <validation-provider v-slot="{ errors }" :name="$t('Country')" vid="country" rules="">
          <euro-select v-model="form.country" :options="countriesSelect" :label="$t('Country')" :error-messages="errors"
            searchable></euro-select>
        </validation-provider>
      </div>
      <!-- <div class="col-12">
          <validation-provider v-slot="{ errors }" :name="$t('Counterpart')" vid="counterpart">
            <euro-select
              v-model="form.counterpart"
              :label="$t('Counterpart')"
              :error-messages="errors"
              :multiselect-props="{
                options: counterpartsByCountry,
                label: 'name',
                'track-by': 'id',
                multiple: false,
                searchable: true,
              }"
            >
            </euro-select>
          </validation-provider>
        </div> -->
      <div class="col-6">
        <validation-provider v-slot="{ errors }" :name="$t('Valid from')" vid="valid_from" rules="">
          <date-picker v-model="form.valid_from" :label="$t('Valid from')" :error-messages="errors"></date-picker>
        </validation-provider>
      </div>
      <div class="col-6">
        <validation-provider v-slot="{ errors }" :name="$t('Valid to')" vid="valid_to" rules="">
          <date-picker v-model="form.valid_to" :label="$t('Valid to')" :error-messages="errors"></date-picker>
        </validation-provider>
      </div>
      <div class="col-12">
        <validation-provider v-slot="{ errors }" :name="$t('Attainment date')" vid="attainment_date">
          <date-picker v-model="form.attainment_date" :label="$t('Attainment date')" :error-messages="errors"
            :overrides="attainmentDateOverrides"></date-picker>
        </validation-provider>
      </div>
      <div class="col-12">
        <validation-provider v-slot="{ errors }" :name="$t('Attachment')" vid="attachment_tus" class="w-100">
          <div id="attachmentFile">
            <euro-tus-file-input :label="$t('Attachment')" :error-messages="errors" accept="application/pdf"
              :disabled="!canSubmit" :hint="$t('Accept .pdf - max size 20MB')" :attachment="existingForm.attachment"
              @change="tusId => {
                form.attachment_tus = tusId;
              }
                "></euro-tus-file-input>
            <b-tooltip v-if="!canSubmit" target="attachmentFile">
              {{ $t("Enter the above required data first") }}
            </b-tooltip>
          </div>
        </validation-provider>
      </div>
    </template>
  </div>
</template>

<script>
import PhysicalPersonLanguageService from "@/core/services/physical-person/physical-person-language.service";
import DatePicker from "@/view/components/datePicker/datePicker.vue";
import { getCounterparts } from "@/core/abstractions/company";
import { mapState } from "vuex";
import icons from "@/core/config/icons";

export default {
  components: {
    DatePicker,
  },

  props: {
    existingForm: {
      type: Object,
      default: null,
    },
  },

  data: () => ({
    icons,
    form: {
      country: null,
      counterpart: "",
      level: "",
      language: "",
      certification: false,
      attachment_tus: "",
      valid_from: null,
      valid_to: null,
      attainment_date: null,
    },
    counterparts: [],
    languageLevels: [],
  }),

  computed: {
    ...mapState("constants", ["countries", "languages"]),

    canSubmit() {
      return this.form.level && this.form.language;
    },

    languagesSelect() {
      return this.languages.map(el => ({ value: el.iso_639_1, text: el.name_en }));
    },

    languageKindSelect() {
      return this.languageLevels.map(({value, display_name}) => ({ value, text: `${value} - ${display_name}` }));
    },

    countriesSelect() {
      return this.countries.map(el => ({ value: el.iso, text: el.name }));
    },

    counterpartsByCountry() {
      if (!this.form.country) {
        return this.counterparts;
      }

      return this.counterparts.filter(el => el.country_of_establishment === this.form.country);
    },

    attainmentDateOverrides() {
      if (!this.form.valid_from || this.form.valid_from == "Invalid date") {
        return {};
      }

      return {
        minDate: this.form.valid_from,
      };
    },
  },

  watch: {
    form: {
      deep: true,
      handler(val) {
        this.$emit("input", val);
      },
    },
  },

  created() {
    PhysicalPersonLanguageService.getLevels().then(res => (this.languageLevels = res));
  },

  async mounted() {
    if (this.existingForm) {
      this.form = { ...this.form, ...this.existingForm };
    }

    // await this.loadCounterparts();
  },

  methods: {
    async loadCounterparts() {
      this.counterparts = await getCounterparts({ isAdministrativeStructure: false });
    },

    onCounterpartCreated(counterpart) {
      this.loadCounterparts();
      this.form.counterpart = counterpart.id;
    },
  },
};
</script>
