var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "form row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('Country'),
      "vid": "country",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('euro-select', {
          attrs: {
            "label": "".concat(_vm.$t('Country'), "*"),
            "error-messages": errors,
            "options": _vm.countriesSelectable,
            "searchable": ""
          },
          model: {
            value: _vm.form.country,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "country", $$v);
            },
            expression: "form.country"
          }
        })];
      }
    }])
  })], 1), _c('div', {
    staticClass: "col-12"
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('Insurance Company'),
      "vid": "insurance_company",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('euro-select', {
          attrs: {
            "label": "".concat(_vm.$t('Counterpart'), "*"),
            "error-messages": errors,
            "multiselect-props": {
              options: _vm.counterpartsByCountry,
              label: 'name',
              'track-by': 'id',
              multiple: false,
              searchable: true
            }
          },
          model: {
            value: _vm.form.insurance_company,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "insurance_company", $$v);
            },
            expression: "form.insurance_company"
          }
        })];
      }
    }])
  })], 1), _c('div', {
    staticClass: "col-12"
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('Insurance notes'),
      "vid": "insurance"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var errors = _ref3.errors;
        return [_c('euro-input', {
          attrs: {
            "error-messages": errors,
            "label": _vm.$t('Insurance notes'),
            "hint": _vm.$t('Notes about your insurance policy')
          },
          model: {
            value: _vm.form.insurance,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "insurance", $$v);
            },
            expression: "form.insurance"
          }
        })];
      }
    }])
  })], 1), _c('div', {
    staticClass: "col-12"
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('Insurance Number'),
      "vid": "number",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var errors = _ref4.errors;
        return [_c('euro-input', {
          attrs: {
            "error-messages": errors,
            "label": "".concat(_vm.$t('Insurance Number'), "*"),
            "hint": _vm.$t('The identification number of your insurance policy')
          },
          model: {
            value: _vm.form.number,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "number", $$v);
            },
            expression: "form.number"
          }
        })];
      }
    }])
  })], 1), _c('div', {
    staticClass: "col-6"
  }, [_c('validation-provider', {
    attrs: {
      "vid": "from_date",
      "name": _vm.$t('From Date'),
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var errors = _ref5.errors;
        return [_c('date-picker', {
          attrs: {
            "error-messages": errors,
            "overrides": _vm.fromDateOverrides,
            "label": "".concat(_vm.$t('From Date'), "*")
          },
          model: {
            value: _vm.form.from_date,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "from_date", $$v);
            },
            expression: "form.from_date"
          }
        })];
      }
    }])
  })], 1), _c('div', {
    staticClass: "col-6"
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('To Date'),
      "vid": "to_date",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var errors = _ref6.errors;
        return [_c('date-picker', {
          attrs: {
            "error-messages": errors,
            "overrides": _vm.toDateOverrides,
            "label": "".concat(_vm.$t('To Date'), "*")
          },
          model: {
            value: _vm.form.to_date,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "to_date", $$v);
            },
            expression: "form.to_date"
          }
        })];
      }
    }])
  })], 1), _c('div', {
    staticClass: "col-12"
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('Currency'),
      "vid": "insured_max_currency",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var errors = _ref7.errors;
        return [_c('euro-select', {
          attrs: {
            "error-messages": errors,
            "label": "".concat(_vm.$t('Currency'), "*"),
            "options": _vm.currenciesSelectable,
            "searchable": ""
          },
          model: {
            value: _vm.form.insured_max_currency,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "insured_max_currency", $$v);
            },
            expression: "form.insured_max_currency"
          }
        })];
      }
    }])
  })], 1), _c('div', {
    staticClass: "col-12"
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('Insured Max'),
      "vid": "insured_max",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref8) {
        var errors = _ref8.errors;
        return [_c('euro-input', {
          attrs: {
            "error-messages": errors,
            "label": "".concat(_vm.$t('Insured Max'), "*"),
            "type": "number"
          },
          model: {
            value: _vm.form.insured_max,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "insured_max", $$v);
            },
            expression: "form.insured_max"
          }
        })];
      }
    }])
  })], 1), _c('div', {
    staticClass: "col-12"
  }, [_c('validation-provider', {
    staticClass: "w-100",
    attrs: {
      "name": _vm.$t('Attachment'),
      "vid": "attachment_tus"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref9) {
        var _vm$form, _vm$form2, _vm$form3, _vm$form4, _vm$form5, _vm$form6, _vm$form7, _vm$form8, _vm$form9, _vm$form10;
        var errors = _ref9.errors;
        return [_c('div', {
          attrs: {
            "id": "attachmentFile"
          }
        }, [_c('euro-tus-file-input', {
          attrs: {
            "label": "".concat(_vm.$t('Attachment'), "*"),
            "error-messages": errors,
            "vid": "attachment_tus",
            "accept": "application/pdf",
            "disabled": !((_vm$form = _vm.form) !== null && _vm$form !== void 0 && _vm$form.insurance_company) || !((_vm$form2 = _vm.form) !== null && _vm$form2 !== void 0 && _vm$form2.number) || !((_vm$form3 = _vm.form) !== null && _vm$form3 !== void 0 && _vm$form3.from_date) || !((_vm$form4 = _vm.form) !== null && _vm$form4 !== void 0 && _vm$form4.insured_max_currency) || !((_vm$form5 = _vm.form) !== null && _vm$form5 !== void 0 && _vm$form5.insured_max),
            "hint": _vm.$t('Accept .pdf - max size 20MB'),
            "attachment": _vm.existingForm.attachment
          },
          on: {
            "change": _vm.onAttachmentChange
          }
        }), !((_vm$form6 = _vm.form) !== null && _vm$form6 !== void 0 && _vm$form6.insurance_company) || !((_vm$form7 = _vm.form) !== null && _vm$form7 !== void 0 && _vm$form7.number) || !((_vm$form8 = _vm.form) !== null && _vm$form8 !== void 0 && _vm$form8.from_date) || !((_vm$form9 = _vm.form) !== null && _vm$form9 !== void 0 && _vm$form9.insured_max_currency) || !((_vm$form10 = _vm.form) !== null && _vm$form10 !== void 0 && _vm$form10.insured_max) ? _c('b-tooltip', {
          attrs: {
            "target": "attachmentFile"
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("Enter the above required data first")) + " ")]) : _vm._e()], 1)];
      }
    }])
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }