var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('validation-observer', {
    ref: "validationObserver",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var handleSubmit = _ref.handleSubmit;
        return [_c('b-modal', {
          attrs: {
            "id": "associate-contract-modal",
            "hide-footer": "",
            "size": "lg"
          },
          on: {
            "shown": _vm.loadContract
          },
          scopedSlots: _vm._u([{
            key: "modal-header",
            fn: function fn() {
              return [_c('div', {
                staticClass: "w-100 h-100 d-flex justify-content-between align-items-center"
              }, [_c('div', [_c('h4', {
                staticClass: "mb-0"
              }, [_vm._v(_vm._s(_vm.$t("Edit Associate Contract")))])]), _c('div', {
                staticClass: "d-flex align-items-center"
              }, [_c('b-button', {
                staticClass: "mr-2",
                class: {
                  'spinner spinner-light spinner-right': _vm.isSaving
                },
                attrs: {
                  "variant": "success",
                  "disabled": _vm.oldVersion
                },
                on: {
                  "click": function click($event) {
                    return handleSubmit(_vm.submit);
                  }
                }
              }, [_vm._v(" " + _vm._s(_vm.$t("Save")) + " ")]), _c('b-button', {
                on: {
                  "click": _vm.closeModal
                }
              }, [_vm._v(_vm._s(_vm.$t('Cancel')))])], 1)])];
            },
            proxy: true
          }], null, true)
        }, [_c('b-overlay', {
          attrs: {
            "show": _vm.isLoading
          }
        }, [_vm.currentContract ? _c('div', [_c('div', {
          staticClass: "form row"
        }, [_vm.showVersions ? _c('div', {
          staticClass: "col-2"
        }, [_c('div', {
          staticClass: "mt-3 font-weight-bold"
        }, [_vm._v(_vm._s(_vm.$t("History")))])]) : _vm._e(), _vm.showVersions ? _c('div', {
          staticClass: "col-10"
        }, [_c('euro-select', {
          attrs: {
            "class-name": "mb-0",
            "options": _vm.versions
          },
          model: {
            value: _vm.version,
            callback: function callback($$v) {
              _vm.version = $$v;
            },
            expression: "version"
          }
        })], 1) : _vm._e(), _vm.showVersions ? _c('div', {
          staticClass: "col-12"
        }, [_c('div', {
          staticClass: "separator separator-dashed my-5"
        })]) : _vm._e()]), _c('div', {
          staticClass: "form row"
        }, [_c('div', {
          staticClass: "col-12"
        }, [_c('h5', {
          staticClass: "mb-6"
        }, [_vm._v(_vm._s(_vm.currentContract.national_contract.name))])])]), !_vm.oldVersion ? _c('div', [[_c('div', {
          staticClass: "form row"
        }, [_c('div', {
          staticClass: "col-md-12"
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('Level'),
            "vid": "national_contract_level",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref2) {
              var errors = _ref2.errors;
              return [_c('div', {
                staticClass: "d-flex align-items-center",
                staticStyle: {
                  "min-height": "50px"
                }
              }, [_c('div', {
                staticStyle: {
                  "flex-grow": "1",
                  "max-width": "50%",
                  "padding-right": "20px"
                }
              }, [_c('label', [_vm._v(_vm._s(_vm.$t('Level')))]), _c('euro-select', {
                attrs: {
                  "error-messages": errors.concat(_vm.errorMessages['national_contract_level'] || []),
                  "options": _vm.nationalContractLevelsSelectable,
                  "searchable": "",
                  "disabled": _vm.oldVersion
                },
                model: {
                  value: _vm.form.national_contract_level,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "national_contract_level", $$v);
                  },
                  expression: "form.national_contract_level"
                }
              })], 1), _c('div', {
                staticStyle: {
                  "flex-basis": "50%",
                  "display": "flex",
                  "justify-content": "flex-start",
                  "padding-left": "20px"
                }
              }, [_c('euro-checkbox', {
                attrs: {
                  "text": _vm.$t('Indeterminate'),
                  "is-switch": "",
                  "size": "lg",
                  "disabled": _vm.oldVersion
                },
                on: {
                  "change": _vm.handleIndeterminateChange
                },
                model: {
                  value: _vm.form.isIndeterminate,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "isIndeterminate", $$v);
                  },
                  expression: "form.isIndeterminate"
                }
              })], 1)])];
            }
          }], null, true)
        })], 1)]), _c('div', {
          staticClass: "form row"
        }, [_c('div', {
          staticClass: "col-md-6"
        }, [_c('validation-provider', {
          attrs: {
            "vid": "start_date",
            "name": _vm.$t('Employement start date'),
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref3) {
              var errors = _ref3.errors;
              return [_c('date-picker', {
                attrs: {
                  "label": "".concat(_vm.$t('Start date'), "*"),
                  "overrides": {
                    useCurrent: false
                  },
                  "error-messages": errors.concat(_vm.errorMessages['start_date'] || []),
                  "disabled": ""
                },
                model: {
                  value: _vm.form.start_date,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "start_date", $$v);
                  },
                  expression: "form.start_date"
                }
              })];
            }
          }], null, true)
        })], 1), _c('div', {
          staticClass: "col-md-6"
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('Termination date'),
            "vid": "end_date",
            "rules": "after:@start_date"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref4) {
              var errors = _ref4.errors;
              return [_c('date-picker', {
                attrs: {
                  "error-messages": errors.concat(_vm.errorMessages['end_date'] || []),
                  "overrides": _vm.endDateOverrides,
                  "disabled": _vm.form.isIndeterminate || _vm.oldVersion,
                  "label": "".concat(_vm.$t('Termination date'))
                },
                model: {
                  value: _vm.form.end_date,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "end_date", $$v);
                  },
                  expression: "form.end_date"
                }
              })];
            }
          }], null, true)
        })], 1)])], _c('div', {
          staticClass: "col-12"
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('Attachment'),
            "vid": "attachment_tus"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref5) {
              var errors = _ref5.errors;
              return [_c('euro-tus-file-input', {
                attrs: {
                  "label": _vm.$t('Attachment'),
                  "error-messages": errors.concat(_vm.errorMessages['attachment'] || []),
                  "accept": "application/pdf",
                  "hint": _vm.$t('Accept .pdf - max size 20MB'),
                  "disabled": _vm.oldVersion
                },
                on: {
                  "change": function change(tusId) {
                    _vm.form.attachment_tus = tusId;
                  }
                }
              })];
            }
          }], null, true)
        })], 1), _c('div', {
          staticClass: "col-12"
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('Notes'),
            "vid": "name",
            "rules": "max:200"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref6) {
              var errors = _ref6.errors;
              return [_c('euro-text-area', {
                attrs: {
                  "label": _vm.$t('Notes'),
                  "disabled": _vm.oldVersion,
                  "error-messages": errors.concat(_vm.errorMessages['description'] || []),
                  "rows": 2
                },
                model: {
                  value: _vm.form.description,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "description", $$v);
                  },
                  expression: "form.description"
                }
              })];
            }
          }], null, true)
        })], 1), _c('div', {
          staticClass: "col-12"
        }, [_c('div', {
          staticClass: "separator separator-dashed mb-10 mt-4"
        })]), _c('div', {
          staticClass: "col-12"
        }, [_c('div', {
          staticClass: "d-flex justify-content-between align-items-center"
        }, [_c('div', [_c('h4', {
          staticClass: "mb-6 font-weight-bold text-dark"
        }, [_vm._v(_vm._s(_vm.$t("Select Renewal Dates")))])]), !_vm.oldVersion && !_vm.disablePpCreation ? _c('div', [_c('b-button', {
          attrs: {
            "variant": "light-primary btn-sm"
          },
          on: {
            "click": _vm.addRenewalDate
          }
        }, [_c('span', {
          staticClass: "svg-icon menu-icon"
        }, [_c('inline-svg', {
          attrs: {
            "src": "/media/svg/icons/Code/Plus.svg"
          }
        })], 1), _vm._v(" " + _vm._s(_vm.$t("Renewal date")) + " ")])], 1) : _vm._e()])])], 2) : _vm._e(), _vm._l(_vm.form.renewals, function (renewal, renewalIdx) {
          return _c('div', {
            key: "".concat(renewalIdx, "-ren")
          }, [renewalIdx > 0 ? _c('div', [_c('div', {
            staticClass: "separator separator-dashed my-5"
          })]) : _vm._e(), _c('div', {
            staticClass: "d-flex"
          }, [_c('div', {
            staticClass: "flex-grow-1"
          }, [_c('validation-provider', {
            attrs: {
              "name": _vm.$t('Employement start date'),
              "vid": "date_valid_from[".concat(renewalIdx, "]")
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(_ref7) {
                var errors = _ref7.errors;
                return [_c('date-picker', {
                  attrs: {
                    "label": _vm.$t('Employement start date'),
                    "error-messages": errors.concat(_vm.errorMessages['renewal_date'] || []),
                    "overrides": _vm.renewalDateOverrides,
                    "disabled": _vm.oldVersion
                  },
                  model: {
                    value: renewal.renewal_date,
                    callback: function callback($$v) {
                      _vm.$set(renewal, "renewal_date", $$v);
                    },
                    expression: "renewal.renewal_date"
                  }
                })];
              }
            }], null, true)
          })], 1), _c('div', {
            staticClass: "flex-grow-1 px-6"
          }, [_c('validation-provider', {
            attrs: {
              "name": _vm.$t('Termination date'),
              "vid": "date_valid_to[".concat(renewalIdx, "]"),
              "rules": {
                after: renewal.renewal_date
              }
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(_ref8) {
                var errors = _ref8.errors;
                return [_c('date-picker', {
                  attrs: {
                    "label": _vm.$t('Termination date'),
                    "overrides": {
                      useCurrent: false
                    },
                    "error-messages": errors.concat(_vm.errorMessages['end_date'] || []),
                    "disabled": _vm.oldVersion
                  },
                  model: {
                    value: renewal.end_date,
                    callback: function callback($$v) {
                      _vm.$set(renewal, "end_date", $$v);
                    },
                    expression: "renewal.end_date"
                  }
                })];
              }
            }], null, true)
          })], 1), !_vm.oldVersion ? _c('div', {
            staticClass: "flex-grow-0"
          }, [_c('div', {
            staticClass: "form-row"
          }, [_c('label', {
            staticClass: "col-12 col-form-label"
          }, [_vm._v(" ")]), _c('b-btn', {
            attrs: {
              "variant": "light",
              "disabled": _vm.form.renewals.length == 1
            },
            on: {
              "click": function click($event) {
                return _vm.removeRenewalDate(renewalIdx);
              }
            }
          }, [_c('span', {
            staticClass: "svg-icon mr-0"
          }, [_c('inline-svg', {
            attrs: {
              "src": "/media/svg/icons/Code/Minus.svg"
            }
          })], 1)])], 1)]) : _vm._e()])]);
        }), _c('div', {
          staticClass: "form row"
        }, [_c('div', {
          staticClass: "col-12"
        }, [_c('div', {
          staticClass: "separator separator-dashed mb-10 mt-4"
        })]), _c('div', {
          staticClass: "col-12"
        }, [_c('div', {
          staticClass: "d-flex justify-content-between align-items-center"
        }, [_c('div', [_c('h4', {
          staticClass: "mb-6 font-weight-bold text-dark"
        }, [_vm._v(_vm._s(_vm.$t("Set work location")))])]), !_vm.oldVersion ? _c('div', [_c('b-button', {
          attrs: {
            "variant": "light-primary btn-sm",
            "block": ""
          },
          on: {
            "click": _vm.addWorkLocation
          }
        }, [_c('span', {
          staticClass: "svg-icon"
        }, [_c('inline-svg', {
          attrs: {
            "src": "/media/svg/icons/Code/Plus.svg"
          }
        })], 1), _vm._v(" " + _vm._s(_vm.$t("Work location")) + " ")])], 1) : _vm._e()])])]), _vm._l(_vm.form.work_locations, function (location, locationIdx) {
          return _c('div', {
            key: "".concat(locationIdx, "-loc"),
            staticClass: "from row"
          }, [locationIdx > 0 ? _c('div', {
            staticClass: "col-12"
          }, [_c('div', {
            staticClass: "separator separator-dashed my-5"
          })]) : _vm._e(), _c('div', {
            staticClass: "col-12"
          }, [_vm.form.work_locations.length > 1 ? _c('div', {
            staticClass: "d-flex align-items-center"
          }, [_c('h6', {
            staticClass: "mb-0 font-weight-bold text-dark"
          }, [_vm._v(_vm._s(_vm.$t("Location")))]), !_vm.oldVersion ? _c('b-button', {
            attrs: {
              "variant": "light-danger btn-sm ml-6",
              "disabled": _vm.form.work_locations.length < 2
            },
            on: {
              "click": function click($event) {
                return _vm.removeWorkLocation(locationIdx);
              }
            }
          }, [_c('span', {
            staticClass: "svg-icon"
          }, [_c('inline-svg', {
            attrs: {
              "src": "/media/svg/icons/Code/Minus.svg"
            }
          })], 1), _vm._v(" " + _vm._s(_vm.$t("Remove")) + " ")]) : _vm._e()], 1) : _vm._e()]), _c('div', {
            staticClass: "col-12"
          }, [_c('div', {
            staticClass: "row"
          }, [_c('div', {
            staticClass: "col-9"
          }, [_c('validation-provider', {
            attrs: {
              "name": _vm.$t('Work location'),
              "vid": "work_location[".concat(locationIdx, "]"),
              "rules": "required"
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(_ref9) {
                var errors = _ref9.errors;
                return [_c('euro-select', {
                  attrs: {
                    "label": "".concat(_vm.$t('Work location'), "*"),
                    "error-messages": errors.concat(_vm.errorMessages['work_location'] || []),
                    "options": _vm.companyAddressesSelectable,
                    "searchable": "",
                    "disabled": _vm.oldVersion
                  },
                  model: {
                    value: location.work_location,
                    callback: function callback($$v) {
                      _vm.$set(location, "work_location", $$v);
                    },
                    expression: "location.work_location"
                  }
                })];
              }
            }], null, true)
          })], 1), _c('div', {
            staticClass: "col-3 pt-14"
          }, [_c('euro-checkbox', {
            attrs: {
              "text": _vm.$t('Remote work'),
              "is-switch": "",
              "size": "lg",
              "disabled": _vm.oldVersion
            },
            model: {
              value: location.remote_work,
              callback: function callback($$v) {
                _vm.$set(location, "remote_work", $$v);
              },
              expression: "location.remote_work"
            }
          })], 1)])]), _c('div', {
            staticClass: "col-12"
          }, [_c('div', {
            staticClass: "row"
          }, [_c('div', {
            staticClass: "col-4"
          }, [_c('validation-provider', {
            attrs: {
              "name": _vm.$t('Valid from'),
              "vid": "valid_from[".concat(locationIdx, "]")
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(_ref10) {
                var errors = _ref10.errors;
                return [_c('date-picker', {
                  attrs: {
                    "label": _vm.$t('Valid from'),
                    "disabled": location.is_permanent,
                    "error-messages": errors.concat(_vm.errorMessages['valid_from'] || []),
                    "overrides": _vm.locationValidFromDateOverrides
                  },
                  model: {
                    value: location.valid_from,
                    callback: function callback($$v) {
                      _vm.$set(location, "valid_from", $$v);
                    },
                    expression: "location.valid_from"
                  }
                })];
              }
            }], null, true)
          })], 1), _c('div', {
            staticClass: "col-4"
          }, [_c('validation-provider', {
            attrs: {
              "name": _vm.$t('Valid to'),
              "vid": "valid_to[".concat(locationIdx, "]"),
              "rules": "after:@valid_from[".concat(locationIdx, "]")
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(_ref11) {
                var errors = _ref11.errors;
                return [_c('date-picker', {
                  attrs: {
                    "disabled": location.is_permanent,
                    "label": _vm.$t('Valid to'),
                    "overrides": {
                      useCurrent: false
                    },
                    "error-messages": errors.concat(_vm.errorMessages['valid_to'] || [])
                  },
                  model: {
                    value: location.valid_to,
                    callback: function callback($$v) {
                      _vm.$set(location, "valid_to", $$v);
                    },
                    expression: "location.valid_to"
                  }
                })];
              }
            }], null, true)
          })], 1), _c('div', {
            staticClass: "col-4 pt-11"
          }, [_c('euro-checkbox', {
            staticClass: "mt-2",
            attrs: {
              "text": _vm.$t('Permanent'),
              "is-switch": "",
              "size": "lg",
              "disabled": _vm.oldVersion
            },
            on: {
              "change": function change($event) {
                return _vm.onLocationPermanentChange(location);
              }
            },
            model: {
              value: location.is_permanent,
              callback: function callback($$v) {
                _vm.$set(location, "is_permanent", $$v);
              },
              expression: "location.is_permanent"
            }
          })], 1)])])]);
        }), _c('div', {
          staticClass: "form row"
        }, [_c('div', {
          staticClass: "col-12"
        }, [_c('div', {
          staticClass: "separator separator-dashed mb-10 mt-4"
        })]), _c('div', {
          staticClass: "col-12"
        }, [_c('h4', {
          staticClass: "mb-6 font-weight-bold text-dark"
        }, [_vm._v(_vm._s(_vm.$t("Salary conditions")))])]), _c('div', {
          staticClass: "col-4"
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('Monthly superminimum'),
            "vid": "monthly_superminimum",
            "rules": {
              required: true,
              min_value: 0
            }
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref12) {
              var errors = _ref12.errors;
              return [_c('euro-input', {
                attrs: {
                  "type": "number",
                  "step": "any",
                  "label": "".concat(_vm.$t('Monthly superminimum'), " (").concat(_vm.form.currency, ")"),
                  "error-messages": errors.concat(_vm.errorMessages['monthly_superminimum'] || []),
                  "disabled": _vm.oldVersion
                },
                model: {
                  value: _vm.form.monthly_superminimum,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "monthly_superminimum", $$v);
                  },
                  expression: "form.monthly_superminimum"
                }
              })];
            }
          }], null, true)
        })], 1), _c('div', {
          staticClass: "col-4"
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('Advanced seniority pay'),
            "vid": "advanced_seniority_pay",
            "rules": {
              required: true,
              min_value: 0
            }
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref13) {
              var errors = _ref13.errors;
              return [_c('euro-input', {
                attrs: {
                  "type": "number",
                  "step": "any",
                  "label": "".concat(_vm.$t('Advanced seniority pay'), " (").concat(_vm.form.currency, ")"),
                  "error-messages": errors.concat(_vm.errorMessages['advanced_seniority_pay'] || []),
                  "disabled": _vm.oldVersion
                },
                model: {
                  value: _vm.form.advanced_seniority_pay,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "advanced_seniority_pay", $$v);
                  },
                  expression: "form.advanced_seniority_pay"
                }
              })];
            }
          }], null, true)
        })], 1), _c('div', {
          staticClass: "col-12"
        }, [_c('div', {
          staticClass: "separator separator-dashed mt-5 mb-10"
        })]), _c('div', {
          staticClass: "col-12"
        }, [_c('h5', {
          staticClass: "mb-6 font-weight-bold text-dark"
        }, [_vm._v(_vm._s(_vm.$t("Travel and posting abroad reimbursement")))]), _c('p', {
          staticClass: "text-muted"
        }, [_vm._v(" " + _vm._s(_vm.$t("Is the employee entitled to reimbursement of expenses when travelling abroad?"))), _c('br')])]), _c('div', {
          staticClass: "col-12"
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('Reimbursement'),
            "vid": "reimbursment"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref14) {
              var errors = _ref14.errors;
              return [_c('euro-radio', {
                class: {
                  'mb-0': _vm.form.reimbursment == 'FULL' || _vm.form.reimbursment == 'PARTIAL'
                },
                attrs: {
                  "error-messages": errors.concat(_vm.errorMessages['reimbursment'] || []),
                  "options": _vm.reimbursmentSelectable,
                  "disabled": _vm.oldVersion
                },
                model: {
                  value: _vm.form.reimbursment,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "reimbursment", $$v);
                  },
                  expression: "form.reimbursment"
                }
              })];
            }
          }], null, true)
        })], 1), _vm.form.reimbursment == 'FULL' ? _c('div', {
          staticClass: "col-4"
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('Daily compensation'),
            "vid": "daily_posting_compensation",
            "rules": {
              required: true,
              min_value: 0
            }
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref15) {
              var errors = _ref15.errors;
              return [_c('euro-input', {
                attrs: {
                  "type": "number",
                  "label": "".concat(_vm.$t('Daily compensation'), " (").concat(_vm.form.currency, ")"),
                  "step": "any",
                  "error-messages": errors.concat(_vm.errorMessages['daily_posting_compensation'] || []),
                  "disabled": _vm.oldVersion
                },
                model: {
                  value: _vm.form.daily_posting_compensation,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "daily_posting_compensation", $$v);
                  },
                  expression: "form.daily_posting_compensation"
                }
              })];
            }
          }], null, true)
        })], 1) : _vm._e(), _vm.form.reimbursment == 'PARTIAL' ? _c('div', {
          staticClass: "col-4"
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('Travel compensation amount'),
            "vid": "travel_compensation_amount",
            "rules": {
              required: true,
              min_value: 0
            }
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref16) {
              var errors = _ref16.errors;
              return [_c('euro-input', {
                staticClass: "mr-5",
                attrs: {
                  "disabled": _vm.form.reimbursment === 'NOT' || _vm.oldVersion,
                  "label": "".concat(_vm.$t('Amount limit'), " (").concat(_vm.form.currency, ")"),
                  "type": "number",
                  "step": "any",
                  "error-messages": errors.concat(_vm.errorMessages['travel_compensation_amount'] || [])
                },
                model: {
                  value: _vm.form.travel_compensation_amount,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "travel_compensation_amount", $$v);
                  },
                  expression: "form.travel_compensation_amount"
                }
              })];
            }
          }], null, true)
        })], 1) : _vm._e(), _c('div', {
          staticClass: "col-12"
        }, [_c('div', {
          staticClass: "separator separator-dashed mt-0 mb-10"
        })]), _c('div', {
          staticClass: "col-12 mb-4"
        }, [_c('div', {
          staticClass: "d-flex align-items-center justify-content-between"
        }, [_c('div', [_c('h4', {
          staticClass: "font-weight-bold text-dark"
        }, [_vm._v(_vm._s(_vm.$t("Bonus")))]), _c('p', {
          staticClass: "text-muted"
        }, [_vm._v(_vm._s(_vm.$t("An extra payment that can be awarded occasionally.")))])]), !_vm.oldVersion ? _c('div', [_c('b-btn', {
          attrs: {
            "variant": "light-primary btn-sm ml-4"
          },
          on: {
            "click": _vm.addBonus
          }
        }, [_c('span', {
          staticClass: "svg-icon"
        }, [_c('inline-svg', {
          attrs: {
            "src": "/media/svg/icons/Code/Plus.svg"
          }
        })], 1), _vm._v(" " + _vm._s(_vm.$t("Add Bonus")) + " ")])], 1) : _vm._e()])]), _c('div', {
          staticClass: "col-12"
        }, _vm._l(_vm.form.bonuses, function (bonus, bonusIdx) {
          return _c('div', {
            key: bonusIdx
          }, [bonusIdx > 0 ? _c('div', {
            staticClass: "w-100"
          }, [_c('div', {
            staticClass: "separator separator-dashed my-2"
          })]) : _vm._e(), _c('div', {
            staticClass: "d-flex"
          }, [_c('div', {
            staticClass: "flex-grow-1"
          }, [_c('validation-provider', {
            attrs: {
              "name": _vm.$t('Bonus'),
              "vid": "bonus[".concat(bonusIdx, "]"),
              "rules": "required|min_value:0"
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(_ref17) {
                var errors = _ref17.errors;
                return [_c('euro-input', {
                  attrs: {
                    "type": "number",
                    "step": "any",
                    "label": "".concat(_vm.$t('Amount'), " (").concat(_vm.form.currency, ")"),
                    "error-messages": errors.concat(_vm.errorMessages['bonus'] || []),
                    "disabled": _vm.oldVersion
                  },
                  model: {
                    value: bonus.bonus,
                    callback: function callback($$v) {
                      _vm.$set(bonus, "bonus", $$v);
                    },
                    expression: "bonus.bonus"
                  }
                })];
              }
            }], null, true)
          })], 1), _c('div', {
            staticClass: "flex-grow-1 px-4"
          }, [_c('validation-provider', {
            attrs: {
              "name": _vm.$t('Valid from'),
              "vid": "bonus_valid_from[".concat(bonusIdx, "]"),
              "rules": {
                required: bonus.bonus
              }
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(_ref18) {
                var errors = _ref18.errors;
                return [_c('date-picker', {
                  attrs: {
                    "label": _vm.$t('Valid from'),
                    "error-messages": errors.concat(_vm.errorMessages['bonus_valid_from'] || []),
                    "overrides": _vm.bonusValidFromDateOverrides,
                    "disabled": _vm.oldVersion
                  },
                  model: {
                    value: bonus.bonus_valid_from,
                    callback: function callback($$v) {
                      _vm.$set(bonus, "bonus_valid_from", $$v);
                    },
                    expression: "bonus.bonus_valid_from"
                  }
                })];
              }
            }], null, true)
          })], 1), _c('div', {
            staticClass: "flex-grow-1 pr-4"
          }, [_c('validation-provider', {
            attrs: {
              "name": _vm.$t('Valid to'),
              "vid": "bonus_valid_to[".concat(bonusIdx, "]"),
              "rules": "after:@bonus_valid_from[".concat(bonusIdx, "]")
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(_ref19) {
                var errors = _ref19.errors;
                return [_c('date-picker', {
                  attrs: {
                    "label": _vm.$t('Valid to'),
                    "overrides": {
                      useCurrent: false
                    },
                    "error-messages": errors.concat(_vm.errorMessages['bonus_valid_to'] || []),
                    "disabled": _vm.oldVersion
                  },
                  model: {
                    value: bonus.bonus_valid_to,
                    callback: function callback($$v) {
                      _vm.$set(bonus, "bonus_valid_to", $$v);
                    },
                    expression: "bonus.bonus_valid_to"
                  }
                })];
              }
            }], null, true)
          })], 1), _c('div', {
            staticClass: "flex-grow-0"
          }, [_c('div', {
            staticClass: "form-row"
          }, [_c('label', {
            staticClass: "col-12 col-form-label"
          }, [_vm._v(" ")]), !_vm.oldVersion ? _c('b-btn', {
            staticClass: "d-flex align-items-center",
            attrs: {
              "variant": "danger"
            },
            on: {
              "click": function click($event) {
                return _vm.removeBonus(bonusIdx);
              }
            }
          }, [_c('span', {
            staticClass: "svg-icon mr-2"
          }, [_c('inline-svg', {
            attrs: {
              "src": "/media/svg/icons/Code/Minus.svg"
            }
          })], 1), _c('span', [_vm._v(_vm._s(_vm.$t("Remove")))])]) : _vm._e()], 1)])])]);
        }), 0)])], 2) : _c('div', [_c('div', {
          staticClass: "font-weight-bold mb-2"
        }, [_vm._v(_vm._s(_vm.$t("Changes")))]), _vm._l(_vm.versionChanges, function (difference) {
          var _vm$texts$difference$;
          return _c('div', {
            key: difference.field,
            staticClass: "alert alert-light mb-3"
          }, [_c('div', {
            staticClass: "font-weight-bold"
          }, [_vm._v(_vm._s((_vm$texts$difference$ = _vm.texts[difference.field]) !== null && _vm$texts$difference$ !== void 0 ? _vm$texts$difference$ : difference.field) + ":")]), difference.field == 'national_contract_level' ? _c('div', [_vm._v(" " + _vm._s(_vm.$t("Changed from")) + " "), _c('span', {
            staticClass: "label label-lg label-inline label-light-success font-weight-bolder mr-1"
          }, [_vm._v(_vm._s(_vm.nationalContractLevelsName(_vm.previousHistoryContract.national_contract_level)))]), _vm._v(" " + _vm._s(_vm.$t("to")) + " "), _c('span', {
            staticClass: "label label-lg label-inline label-light-success font-weight-bolder"
          }, [_vm._v(_vm._s(_vm.nationalContractLevelsName(_vm.historyContract.national_contract_level)))])]) : difference.field == 'travel_posting_reimbursement' ? _c('div', [_vm._v(" " + _vm._s(_vm.$t("Reimbursement")) + ": "), _vm.historyContract.travel_posting_reimbursement == _vm.FULL ? _c('span', [_vm._v(_vm._s(_vm.$t("Full reimbursement")))]) : _vm.historyContract.travel_posting_reimbursement == _vm.PARTIAL ? _c('span', [_vm._v(_vm._s(_vm.$t("Partial reimbursement")))]) : _vm.historyContract.travel_posting_reimbursement == _vm.NOT ? _c('span', [_vm._v(_vm._s(_vm.$t("No reimbursement")))]) : _vm._e()]) : difference.field == 'employeecontractworklocation_set' ? _c('div', _vm._l(_vm.historyContract.work_locations, function (location) {
            return _c('div', {
              key: location.id,
              staticClass: "mb-2"
            }, [_vm._v(" " + _vm._s(_vm.$t('Remote work')) + ": " + _vm._s(location.remote_work)), _c('br'), _vm._v(" " + _vm._s(_vm.$t('Permanent')) + ": " + _vm._s(location.is_permanent)), _c('br'), _vm._v(" " + _vm._s(_vm.$t('Valid from')) + ": " + _vm._s(_vm.DateService.format(location.valid_from, _vm.dateFormat))), _c('br'), location.valid_to ? _c('div', [_vm._v(_vm._s(_vm.$t('Valid to')) + ": " + _vm._s(_vm.DateService.format(location.valid_to, _vm.dateFormat)))]) : _vm._e()]);
          }), 0) : difference.field == 'employeecontractbonus_set' ? _c('div', _vm._l(_vm.historyContract.bonuses, function (bonus) {
            return _c('div', {
              key: bonus.id,
              staticClass: "mb-2"
            }, [_vm._v(" " + _vm._s(_vm.$t('Amount')) + ": " + _vm._s(bonus.bonus) + " " + _vm._s(_vm.form.currency)), _c('br'), _vm._v(" " + _vm._s(_vm.$t('Valid from')) + ": " + _vm._s(_vm.DateService.format(bonus.bonus_valid_from, _vm.dateFormat))), _c('br'), bonus.bonus_valid_to ? _c('div', [_vm._v(_vm._s(_vm.$t('Valid to')) + ": " + _vm._s(_vm.DateService.format(bonus.bonus_valid_to, _vm.dateFormat)))]) : _vm._e()]);
          }), 0) : _c('div', [_c('span', [_vm._v(_vm._s(difference.value))])])]);
        })], 2)])], 1)];
      }
    }])
  });

}
var staticRenderFns = []

export { render, staticRenderFns }