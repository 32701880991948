<template>
  <div>
    <div class="form row">
      <div class="col-12">
        <validation-provider v-slot="{ errors }" :name="$t('Country')" vid="country" rules="required">
          <euro-select v-model="form.country" searchable :options="countriesSelects" :label="`${$t('Country')}*`"
            :error-messages="errors" />
        </validation-provider>
      </div>
      <div class="col-12">
        <validation-provider v-slot="{ errors }" :name="$t('Language')" vid="doc_language">
          <euro-select v-model="form.doc_language" :label="$t('Language')" :options="languageSelect"
            :error-messages="errors" searchable>
          </euro-select>
        </validation-provider>
      </div>
      <!--
      <div class="col-12 col-lg-6">
        <validation-provider v-slot="{ errors }" :name="$t('Counterpart')" vid="counterpart" rules="">
          <div id="counterpartsSelect">
            <euro-select
              v-model="form.counterpart"
              searchable
              :label="`${$t('Counterpart')}*`"
              :disabled="!administrativeStructuresSelectable?.length && !form?.country"
              :error-messages="errors"
              :options="administrativeStructuresSelectable"
            ></euro-select>
            <b-tooltip v-if="!administrativeStructuresSelectable?.length && !form?.country" target="counterpartsSelect">
              {{ $t("Select a country first") }}
            </b-tooltip>
          </div>
        </validation-provider>
      </div>-->
      <div class="col-12 col-lg-6">
        <validation-provider v-slot="{ errors }" :name="$t('Passport number')" vid="number" rules="required">
          <euro-input v-model="form.number" :label="`${$t('Passport number')}*`" :error-messages="errors"></euro-input>
        </validation-provider>
      </div>
      <div class="col-12 col-lg-6">
        <validation-provider v-slot="{ errors }" :name="$t('Release date')" vid="date_of_issue"
          rules="required|before_now">
          <date-picker v-model="form.date_of_issue" :error-messages="errors" :label="`${$t('Release date')}*`"
            :overrides="dateOfIssueOverrides">
          </date-picker>
        </validation-provider>
      </div>
      <div class="col-12 col-lg-6">
        <validation-provider v-slot="{ errors }" :name="$t('Valid from')" vid="valid_from"
          rules="required|after_if:@date_of_issue|before_if:@valid_to">
          <date-picker v-model="form.valid_from"
            :error-messages="errors" :overrides="validFromOverrides" :label="`${$t('Valid from')}*`">
          </date-picker>
        </validation-provider>
      </div>
      <div class="col-12 col-lg-6">
        <validation-provider v-slot="{ errors }" :name="$t('Valid to')" vid="valid_to"
          rules="required|after_if:@valid_from}|after_if:@date_of_issue}">
          <date-picker v-model="form.valid_to" :error-messages="errors" :overrides="validToOverrides"
            :label="`${$t('Valid to')}*`">
          </date-picker>
        </validation-provider>
      </div>
      <div class="col-12">
        <validation-provider v-slot="{ errors }" :name="$t('Attachment')" vid="attachment_tus" class="w-100">
          <div id="attachmentFile">
            <euro-tus-file-input :label="`${$t('Attachment')}*`" :error-messages="errors" accept="application/pdf"
              :disabled="!form?.number?.length" :hint="$t('Accept .pdf - max size 20MB')"
              :attachment="existingForm.attachment" @change="tusId => {
                form.attachment_tus = tusId;
              }
                "></euro-tus-file-input>
            <b-tooltip v-if="!form?.number?.length" target="attachmentFile">
              {{ $t("Enter passport number first") }}
            </b-tooltip>
          </div>
        </validation-provider>
      </div>
    </div>
    <template v-if="physicalPerson">
      <div class="separator separator-dashed my-6"></div>
      <div class="text-center font-weight-bold mb-5 pt-2">
        {{ $t("Please make sure you are inserting the correct data for") }}:
      </div>
      <div class="card">
        <div class="card-body px-2 py-2">
          <table class="table table-borderless mb-0">
            <tbody>
              <tr>
                <td>{{ $t("Name") }}</td>
                <td>{{ physicalPerson?.name }}</td>
              </tr>
              <tr>
                <td>{{ $t("Surname") }}</td>
                <td>{{ physicalPerson?.surname }}</td>
              </tr>
              <tr>
                <td>{{ $t("Date of birth") }}</td>
                <td>{{ physicalPerson.date_birth ? DateService.format(physicalPerson.date_birth, date_format) : "" }}</td>
              </tr>
              <tr>
                <td>{{ $t("Place of birth") }}</td>
                <td>{{ physicalPerson?.place_birth?.formatted_address }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import moment from "moment";
import { AdministrativeStructuresMixin } from "@/core/mixins";
import DateService from "@/core/services/date.service";
import { mapGetters } from "vuex";
import DatePicker from "@/view/components/datePicker/datePicker.vue";
export default {
  components: {
    DatePicker,
  },

  mixins: [AdministrativeStructuresMixin],
  props: {
    existingForm: {
      type: Object,
      default: null,
    },
    languages: {
      type: Array,
      required: true,
    },
    countries: {
      type: Array,
      required: true,
    },
    physicalPerson: {
      type: Object,
      default: null,
    },
  },

  data: () => ({
    form: {
      country: "",
      counterpart: "",
      number: "",
      date_of_issue: null,
      valid_from: null,
      valid_to: null,
      doc_language: "",
      attachment_tus: "",
    },
    DateService,
    confirm: false,
  }),

  computed: {
    ...mapGetters("user", ["date_format"]),

    validFromOverrides() {
      const out = {};
      if (this.form.date_of_issue) {
        out.minDate = moment(this.form.date_of_issue).toDate();
      }

      if (this.form.valid_to && this.form.valid_to != "Invalid date") {
        out.maxDate = moment(this.form.valid_to).toDate();
      }

      return out;
    },

    validToOverrides() {
      const out = {};

      if (this.form.valid_from) {
        out.minDate = DateService.toDate(this.form.valid_from);
      }

      return out;
    },

    dateOfIssueOverrides() {
      return {
        maxDate: moment().toDate(),
      };
    },

    languageSelect() {
      return this.languages.map(el => ({ value: el.iso_639_1, text: el.name_en }));
    },

    countriesSelects() {
      return this.countries.map(el => ({ value: el.iso, text: el.name }));
    },
  },

  watch: {
    form: {
      deep: true,
      handler(val) {
        this.$emit("input", val);
      },
    },
    "form.country"(val, oldVal) {
      if (!val || (!!oldVal && oldVal !== val)) {
        this.form.counterpart = "";
      }
      if (val) {
        this.loadAdministrativesStructuresByCountry(val);
      } else {
        this.clearAdministrativeStrucutres();
      }
    },
    "form.date_of_issue"() {
      if (!this.form.valid_from || this.form.valid_from < this.form.date_of_issue) {
        this.form.valid_from = this.form.date_of_issue;
      }
    }
  },

  mounted() {
    if (this.existingForm) {
      this.form = { ...this.form, ...this.existingForm };
    }
  },

  methods: {
    moment,
  },
};
</script>
