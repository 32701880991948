<template>
  <div>
    <div class="form row">
      <div class="col-12">
        <validation-provider v-slot="{ errors }" :name="$t('Passport')" vid="passport" rules="required">
          <euro-select v-model="form.passport" :label="`${$t('Passport')}*`" :options="passportSelect"
            :error-messages="errors" searchable>
          </euro-select>
        </validation-provider>
      </div>
      <div class="col-12">
        <validation-provider v-slot="{ errors }" :name="$t('Destination Country')" vid="destination_country"
          rules="required">
          <euro-select v-model="form.destination_country" searchable :options="countriesSelects"
            :label="`${$t('Destination Country')}*`" :error-messages="errors" />
        </validation-provider>
      </div>
      <div class="col-12 col-lg-7">
        <validation-provider v-slot="{ errors }" :name="$t('Visa Kind')" vid="visa_kind" rules="required">
          <euro-select v-model="form.visa_kind" :label="`${$t('Visa Kind')}*`" :options="visaKindSelect"
            :error-messages="errors" searchable>
          </euro-select>
        </validation-provider>
      </div>
      <div class="col-12 col-lg-5">
        <validation-provider v-slot="{ errors }" :name="$t('Duration of Stay (Days)')" vid="staying_days"
          rules="required">
          <euro-input v-model="form.staying_days" :label="`${$t('Duration of Stay (Days)')}*`" type="number"
            :error-messages="errors"></euro-input>
        </validation-provider>
      </div>
      <div class="col-12 col-lg-6">
        <validation-provider v-slot="{ errors }" :name="$t('Visa number')" vid="number" rules="required">
          <euro-input v-model="form.number" :label="`${$t('Visa number')}*`" :error-messages="errors"></euro-input>
        </validation-provider>
      </div>
      <div class="col-12 col-lg-6">
        <validation-provider v-slot="{ errors }" :name="$t('Release date')" vid="emission_date"
          rules="required|before_now">
          <date-picker v-model="form.emission_date" :error-messages="errors" :label="`${$t('Release date')}*`"
            :overrides="dateOfIssueOverrides">
          </date-picker>
        </validation-provider>
      </div>
      <div class="col-12 col-lg-6">
        <validation-provider v-slot="{ errors }" :name="$t('Valid from')" vid="valid_from"
          rules="required|after_if:@date_of_issue|before_if:@valid_to">
          <date-picker v-model="form.valid_from" :error-messages="errors" :overrides="validFromOverrides"
            :label="`${$t('Valid from')}*`">
          </date-picker>
        </validation-provider>
      </div>
      <div class="col-12 col-lg-6">
        <validation-provider v-slot="{ errors }" :name="$t('Valid to')" vid="valid_to"
          rules="required|after_if:@valid_from}|after_if:@date_of_issue}">
          <date-picker v-model="form.valid_to" :error-messages="errors" :overrides="validToOverrides"
            :label="`${$t('Valid to')}*`">
          </date-picker>
        </validation-provider>
      </div>
      <div class="col-12 col-lg-6">
        <label class="checkbox mt-4">
          <input v-model="unlimitedEntries" type="checkbox" name="select" />
          <span class="mr-4"></span>
          {{ $t('Unlimited entries') }}
        </label>
        <div class="font-size-xs mt-3">{{ $t('Check if the visa allow unlimited entries in the country') }}</div>
      </div>
      <div class="col-12 col-lg-6">
        <validation-provider v-slot="{ errors }" :name="$t('Number of entries')" vid="entries" rules="required">
          <euro-input v-model="form.entries" :label="`${$t('Number of entries')}*`" type="number" :error-messages="errors"
            :disabled="unlimitedEntries"></euro-input>
        </validation-provider>
      </div>
      <div class="col-12">
        <validation-provider v-slot="{ errors }" :name="$t('Notes')" vid="notes">
          <euro-text-area v-model="form.notes" :label="`${$t('Notes')}`" :error-messages="errors"></euro-text-area>
        </validation-provider>
      </div>
      <div class="col-12">
        <validation-provider v-slot="{ errors }" :name="$t('Attachment')" vid="attachment_tus" class="w-100">
          <div id="attachmentFile">
            <euro-tus-file-input :label="`${$t('Attachment')}*`" :error-messages="errors" accept="application/pdf"
              :disabled="!form?.number?.length" :hint="$t('Accept .pdf - max size 20MB')"
              :attachment="existingForm.attachment" @change="tusId => {
                form.attachment_tus = tusId;
              }
                "></euro-tus-file-input>
            <b-tooltip v-if="!form?.number?.length" target="attachmentFile">
              {{ $t("Enter passport number first") }}
            </b-tooltip>
          </div>
        </validation-provider>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import DateService from "@/core/services/date.service";
import { mapGetters } from "vuex";
import DatePicker from "@/view/components/datePicker/datePicker.vue";
import PhysicalPersonVisaService from "@/core/services/physical-person/physical-person-visa-service";
export default {
  components: {
    DatePicker,
  },

  props: {
    existingForm: {
      type: Object,
      default: null,
    },
    languages: {
      type: Array,
      required: true,
    },
    countries: {
      type: Array,
      required: true,
    },
    passports: {
      type: Array,
      required: true,
    },
  },

  data: () => ({
    form: {
      destination_country: "",
      passport: null,
      number: "",
      visa_kind: "",
      notes: "",
      date_of_issue: null,
      valid_from: null,
      valid_to: null,
      attachment_tus: "",
      entries: 1,
      staying_days: null
    },
    DateService,
    confirm: false,
    visaKindSelect: [],
    unlimitedEntries: false,
  }),

  computed: {
    ...mapGetters("user", ["date_format"]),

    validFromOverrides() {
      const out = {};
      if (this.form.date_of_issue) {
        out.minDate = moment(this.form.date_of_issue).toDate();
      }

      if (this.form.valid_to && this.form.valid_to != "Invalid date") {
        out.maxDate = moment(this.form.valid_to).toDate();
      }

      return out;
    },

    validToOverrides() {
      const out = {};

      if (this.form.valid_from) {
        out.minDate = DateService.toDate(this.form.valid_from);
      }

      return out;
    },

    dateOfIssueOverrides() {
      return {
        maxDate: moment().toDate(),
      };
    },

    passportSelect() {
      return this.passports.map(el => ({ value: el.id, text: el.country + " " + el.number }));
    },

    countriesSelects() {
      return this.countries.map(el => ({ value: el.iso, text: el.name }));
    },
  },

  watch: {
    form: {
      deep: true,
      handler(val) {
        this.$emit("input", val);
      },
    },
    "form.country"(val, oldVal) {
      if (!val || (!!oldVal && oldVal !== val)) {
        this.form.counterpart = "";
      }
      if (val) {
        this.loadAdministrativesStructuresByCountry(val);
      } else {
        this.clearAdministrativeStrucutres();
      }
    },
    passportSelect: {
      immediate: true,
      handler(newVal) {
        // if there's no initial value for passport and there are options
        if (this.form.passport === null && newVal.length > 0) {
          this.form.passport = newVal[0].value; // adjust as per your object structure
        }
      },
    }
  },

  async mounted() {
    if (this.existingForm) {
      this.form = { ...this.form, ...this.existingForm };
    }
    const visaKinds = await PhysicalPersonVisaService.getVisaTypes();
    this.visaKindSelect = visaKinds.map(el => ({ value: el.value, text: el.display_name }));
  },

  methods: {
    moment,
  },
};
</script>
