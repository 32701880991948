import CompanyService from "@/core/services/company/company.service";
import CompanyGroupsService from "@/core/services/company/company-groups.service";
import CompanyAddressService from "@/core/services/company/company-address.service";

async function isCompanyInGroup(parentCompanyId, childCompanyId) {
  const parent = await CompanyService.getOneOldAddressFormat(parentCompanyId);
  const groups = parent.data.company_group;
  const holderGroup = groups.find(g => g.holder == parentCompanyId);
  if (!holderGroup) return false;

  return holderGroup.companies.includes(childCompanyId);
}

async function addCompanyToGroup(parentCompanyId, childCompanyId) {
  const parent = await CompanyService.getOneOldAddressFormat(parentCompanyId);
  const groups = parent.data.company_group;
  let holderGroup = groups.find(g => g.holder == parentCompanyId);
  if (!holderGroup) {
    return await CompanyGroupsService.create({
      holder: parentCompanyId,
      companies: [parentCompanyId, childCompanyId],
    });
  }

  if (!holderGroup.companies.includes(childCompanyId)) {
    return await CompanyGroupsService.update(
      { ...holderGroup, companies: [...holderGroup.companies, childCompanyId] },
      holderGroup.id,
    );
  }

  return holderGroup;
}

async function setAsClient(company) {
  return await CompanyService.update({ secondment_client: company.is_client_on_secondments, name: company.name }, company.id);
}

async function setAsPartner(company) {
  return await CompanyService.update({ secondment_partner: company.is_partner_on_secondments, name: company.name }, company.id);
}

async function setAsContractor(company) {
  return await CompanyService.update({ secondment_contractor: company.is_contractor_on_secondments, name: company.name }, company.id);
}

async function addCompanyAddress(address) {
  return await CompanyAddressService.create(address);
}

/**
 * DOCUMENT_COUNTERPART is an ENUM like object containing all the possible options for the documentCounterpart filter.
 */
const DOCUMENT_COUNTERPART = {
  VAT: "DOCUMENTS: VAT",
  FISCAL_ID: "DOCUMENTS: FISCALID",
  SOCIAL_SECURITY_INSTITUTION: "SOCIAL SECURITY INSTITUTION",
};
Object.freeze(DOCUMENT_COUNTERPART);

/**
 * getCounterparts returns a list of counterparts given the filters. It's not paginated.
 */
async function getCounterparts({ country = null, document = null, isAdministrativeStructure = null } = {}) {
  const { count } = await CompanyService.all({
    perPage: 1,
    page: 1,
    country,
    documentCounterpart: document,
    isAdministrative: isAdministrativeStructure,
  });
  const { results } = await CompanyService.all({
    page: 1,
    perPage: count,
    country,
    documentCounterpart: document,
    isAdministrative: isAdministrativeStructure,
  });
  return results;
}

export {
  isCompanyInGroup,
  addCompanyToGroup,
  setAsClient,
  setAsPartner,
  setAsContractor,
  getCounterparts,
  DOCUMENT_COUNTERPART,
  addCompanyAddress,
};
