var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "form row"
  }, [_c('div', {
    staticClass: "col-6"
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('Country'),
      "vid": "country",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('euro-select', {
          attrs: {
            "multiselect-props": {
              options: _vm.filteredCountries,
              label: 'name',
              'track-by': 'iso',
              searchable: true
            },
            "error-messages": errors,
            "searchable": "",
            "label": "".concat(_vm.$t('Country'), "*")
          },
          model: {
            value: _vm.form.country,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "country", $$v);
            },
            expression: "form.country"
          }
        })];
      }
    }])
  })], 1), _c('div', {
    staticClass: "col-6"
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('Driving license number'),
      "vid": "number",
      "rules": "required|drivingLicenseNumber"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('euro-input', {
          attrs: {
            "label": "".concat(_vm.$t('Driving license number'), "*"),
            "error-messages": errors
          },
          model: {
            value: _vm.form.number,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "number", $$v);
            },
            expression: "form.number"
          }
        })];
      }
    }])
  })], 1), _c('div', {
    staticClass: "col-6"
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('Driving license codes'),
      "vid": "license_code"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var errors = _ref3.errors;
        return [_c('euro-select', {
          attrs: {
            "label": _vm.$t('Driving license codes'),
            "error-messages": errors,
            "multiselect-props": {
              multiple: true,
              label: 'display_name',
              'track-by': 'value',
              options: _vm.drivingLicenseCodeKinds
            }
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_c('i', {
                directives: [{
                  name: "b-tooltip",
                  rawName: "v-b-tooltip"
                }],
                class: _vm.icons.lineAwesome.info,
                attrs: {
                  "title": _vm.$t('Select all that apply at n.12 of the EU driving license')
                }
              })];
            },
            proxy: true
          }], null, true),
          model: {
            value: _vm.form.license_code,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "license_code", $$v);
            },
            expression: "form.license_code"
          }
        })];
      }
    }])
  })], 1), _vm.isDuplicateNumberVisible ? _c('div', {
    staticClass: "col-6"
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('Duplicate driving license number'),
      "vid": "duplicate_number",
      "rules": _vm.duplicateNumberRules
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var errors = _ref4.errors;
        return [_c('euro-input', {
          attrs: {
            "label": _vm.$t('Duplicate driving license number'),
            "error-messages": errors,
            "help-text": _vm.$t('Enter duplicate license number, mandatory when license is a duplicate')
          }
        })];
      }
    }], null, false, 2081748352)
  })], 1) : _vm._e(), _c('div', {
    staticClass: "col-12"
  }, [_c('validation-provider', {
    staticClass: "w-100",
    attrs: {
      "name": _vm.$t('Attachment front'),
      "vid": "attachment_tus"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var _vm$form, _vm$form$number, _vm$form2, _vm$form2$number;
        var errors = _ref5.errors;
        return [_c('div', {
          attrs: {
            "id": "attachmentFile"
          }
        }, [_c('euro-tus-file-input', {
          attrs: {
            "label": "".concat(_vm.$t('Attachment front'), "*"),
            "placeholder": _vm.$t('Attachment'),
            "error-messages": errors,
            "rules": "required",
            "accept": "application/pdf",
            "disabled": !((_vm$form = _vm.form) !== null && _vm$form !== void 0 && (_vm$form$number = _vm$form.number) !== null && _vm$form$number !== void 0 && _vm$form$number.length),
            "hint": _vm.$t('Accept .pdf - max size 20MB'),
            "attachment": _vm.existingForm.attachment
          },
          on: {
            "change": function change(tusId) {
              _vm.form.attachment_tus = tusId;
            }
          }
        }), !((_vm$form2 = _vm.form) !== null && _vm$form2 !== void 0 && (_vm$form2$number = _vm$form2.number) !== null && _vm$form2$number !== void 0 && _vm$form2$number.length) ? _c('b-tooltip', {
          attrs: {
            "target": "attachmentFile"
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("Enter the driving license number first")) + " ")]) : _vm._e()], 1)];
      }
    }])
  })], 1), _c('div', {
    staticClass: "col-12"
  }, [_c('validation-provider', {
    staticClass: "w-100",
    attrs: {
      "name": _vm.$t('Attachment back'),
      "vid": "attachment_back_tus"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var errors = _ref6.errors;
        return [_c('div', {
          staticClass: "row m-1 my-3"
        }, [_c('span', {
          staticClass: "col- switch"
        }, [_c('label', [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.attachmentBack,
            expression: "attachmentBack"
          }],
          attrs: {
            "type": "checkbox",
            "name": "select"
          },
          domProps: {
            "checked": Array.isArray(_vm.attachmentBack) ? _vm._i(_vm.attachmentBack, null) > -1 : _vm.attachmentBack
          },
          on: {
            "change": function change($event) {
              var $$a = _vm.attachmentBack,
                $$el = $event.target,
                $$c = $$el.checked ? true : false;
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v);
                if ($$el.checked) {
                  $$i < 0 && (_vm.attachmentBack = $$a.concat([$$v]));
                } else {
                  $$i > -1 && (_vm.attachmentBack = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                }
              } else {
                _vm.attachmentBack = $$c;
              }
            }
          }
        }), _c('span')])]), _c('label', {
          staticClass: "col- col-form-label"
        }, [_vm._v(_vm._s(_vm.$t('Add attachment back')) + " "), _c('i', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip"
          }],
          class: _vm.icons.lineAwesome.info,
          attrs: {
            "title": _vm.$t('Switch on for uploading front and back of the document separately. You can also combine front/back in a single file in the attachment field.')
          }
        })])])];
      }
    }])
  })], 1), _vm.attachmentBack ? _c('div', {
    staticClass: "col-12"
  }, [_c('validation-provider', {
    staticClass: "w-100",
    attrs: {
      "name": _vm.$t('Attachment'),
      "vid": "attachment_back_tus"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var _vm$form3, _vm$form3$number, _vm$form4, _vm$form4$number;
        var errors = _ref7.errors;
        return [_c('div', {
          attrs: {
            "id": "attachmentFileBack"
          }
        }, [_c('euro-tus-file-input', {
          attrs: {
            "label": _vm.$t('Attachment back'),
            "error-messages": errors,
            "accept": "application/pdf",
            "disabled": !((_vm$form3 = _vm.form) !== null && _vm$form3 !== void 0 && (_vm$form3$number = _vm$form3.number) !== null && _vm$form3$number !== void 0 && _vm$form3$number.length),
            "hint": _vm.$t('Accept .pdf - max size 20MB'),
            "attachment": _vm.existingForm.attachment_back
          },
          on: {
            "change": function change(tusId) {
              _vm.form.attachment_back_tus = tusId;
            }
          }
        }), !((_vm$form4 = _vm.form) !== null && _vm$form4 !== void 0 && (_vm$form4$number = _vm$form4.number) !== null && _vm$form4$number !== void 0 && _vm$form4$number.length) ? _c('b-tooltip', {
          attrs: {
            "target": "attachmentFileBack"
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("Enter the above required data first")) + " ")]) : _vm._e()], 1)];
      }
    }], null, false, 2365078922)
  })], 1) : _vm._e(), _vm._m(0), _c('div', {
    staticClass: "col-6"
  }, [_c('div', {
    staticClass: "font-weight-bolder pb-4"
  }, [_vm._v(" " + _vm._s(_vm.$t("Driving license categories"))), _c('br'), _c('small', [_vm._v(_vm._s(_vm.$t("* Fill at least one driving license category")))])])]), _c('div', {
    staticClass: "col-6 text-right"
  }, [_c('button', {
    staticClass: "btn btn-light-primary font-weight-bolder",
    attrs: {
      "to": {
        name: 'addNewForm'
      }
    },
    on: {
      "click": _vm.addNewForm
    }
  }, [_c('i', {
    staticClass: "flaticon2-calendar-8 mr-2"
  }), _vm._v(_vm._s(_vm.$t("Add New")) + " ")])]), _vm.form.category_set.length ? _c('div', {
    staticClass: "col-12"
  }, [_c('validation-provider', {
    attrs: {
      "vid": "category_set"
    }
  }, [_c('b-table', {
    attrs: {
      "small": "",
      "items": _vm.form.category_set,
      "fields": [{
        label: _vm.$t('Category'),
        key: 'category'
      }, {
        label: _vm.$t('Valid from'),
        key: 'date_from'
      }, {
        label: _vm.$t('Valid to'),
        key: 'date_to'
      }, {
        label: '',
        key: 'delete'
      }]
    },
    scopedSlots: _vm._u([{
      key: "cell(category)",
      fn: function fn(_ref8) {
        var item = _ref8.item;
        return [_c('euro-select', {
          staticClass: "col-10",
          attrs: {
            "options": _vm.getCategoriesSelected
          },
          model: {
            value: item.category,
            callback: function callback($$v) {
              _vm.$set(item, "category", $$v);
            },
            expression: "item.category"
          }
        })];
      }
    }, {
      key: "cell(date_from)",
      fn: function fn(_ref9) {
        var item = _ref9.item;
        return [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('Valid from'),
            "vid": "category-".concat(item.category, "-valid-from"),
            "rules": {
              required_if: ["category-".concat(item.category, "-valid-to")]
            }
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref10) {
              var errors = _ref10.errors;
              return [_c('date-picker', {
                attrs: {
                  "error-messages": errors,
                  "label": ""
                },
                model: {
                  value: item.date_from,
                  callback: function callback($$v) {
                    _vm.$set(item, "date_from", $$v);
                  },
                  expression: "item.date_from"
                }
              })];
            }
          }], null, true)
        })];
      }
    }, {
      key: "cell(date_to)",
      fn: function fn(_ref11) {
        var item = _ref11.item;
        return [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('Valid from'),
            "vid": "category-".concat(item.category, "-valid-to"),
            "rules": {
              required_if: ["category-".concat(item.category, "-valid-from")]
            }
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref12) {
              var errors = _ref12.errors;
              return [_c('date-picker', {
                attrs: {
                  "error-messages": errors,
                  "label": "",
                  "overrides": item.date_from ? {
                    minDate: _vm.moment(item.date_from)
                  } : null
                },
                model: {
                  value: item.date_to,
                  callback: function callback($$v) {
                    _vm.$set(item, "date_to", $$v);
                  },
                  expression: "item.date_to"
                }
              })];
            }
          }], null, true)
        })];
      }
    }, {
      key: "cell(delete)",
      fn: function fn(row) {
        return [_c('b-button', {
          staticClass: "delete-button mb-7",
          attrs: {
            "variant": "danger"
          },
          on: {
            "click": function click($event) {
              return _vm.removeForm(row.item);
            }
          }
        }, [_vm._v(_vm._s(_vm.$t('Delete')))])];
      }
    }], null, false, 2897378912)
  })], 1)], 1) : _vm._e()])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "separator separator-dashed my-5"
  })]);

}]

export { render, staticRenderFns }