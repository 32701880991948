var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "form row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('Country'),
      "vid": "country",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('euro-select', {
          ref: "countryMultiselect",
          attrs: {
            "label": "".concat(_vm.$t('CCIAA Nation'), "*"),
            "error-messages": errors,
            "options": _vm.countriesSelectable,
            "searchable": ""
          },
          model: {
            value: _vm.form.country,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "country", $$v);
            },
            expression: "form.country"
          }
        })];
      }
    }])
  })], 1), _c('div', {
    staticClass: "col-6"
  }, [_c('validation-provider', {
    attrs: {
      "vid": "from_date",
      "name": _vm.$t('From Date')
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('date-picker', {
          attrs: {
            "overrides": _vm.fromDateOverrides,
            "error-messages": errors,
            "placeholder": "YYYY-MM-DD",
            "label": "".concat(_vm.$t('From Date'))
          },
          model: {
            value: _vm.form.from_date,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "from_date", $$v);
            },
            expression: "form.from_date"
          }
        })];
      }
    }])
  })], 1), _c('div', {
    staticClass: "col-6"
  }, [_c('validation-provider', {
    attrs: {
      "vid": "to_date",
      "name": _vm.$t('To Date')
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var errors = _ref3.errors;
        return [_c('date-picker', {
          attrs: {
            "overrides": _vm.toDateOverrides,
            "error-messages": errors,
            "label": _vm.$t('To Date')
          },
          model: {
            value: _vm.form.to_date,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "to_date", $$v);
            },
            expression: "form.to_date"
          }
        })];
      }
    }])
  })], 1), _c('div', {
    staticClass: "col-12"
  }, [_c('validation-provider', {
    attrs: {
      "vid": "emission_date",
      "name": _vm.$t('Emission Date'),
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var errors = _ref4.errors;
        return [_c('date-picker', {
          attrs: {
            "overrides": _vm.emissionDateOverrides,
            "error-messages": errors,
            "placeholder": "YYYY-MM-DD",
            "label": "".concat(_vm.$t('Emission Date'), "*")
          },
          model: {
            value: _vm.form.emission_date,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "emission_date", $$v);
            },
            expression: "form.emission_date"
          }
        })];
      }
    }])
  })], 1), _c('div', {
    staticClass: "col-12"
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('Registration Number'),
      "vid": "registration_number",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var errors = _ref5.errors;
        return [_c('euro-input', {
          attrs: {
            "label": "".concat(_vm.$t('Registration Number'), "*"),
            "error-messages": errors
          },
          model: {
            value: _vm.form.registration_number,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "registration_number", $$v);
            },
            expression: "form.registration_number"
          }
        })];
      }
    }])
  })], 1), _c('div', {
    staticClass: "col-12"
  }, [_c('b-overlay', {
    attrs: {
      "show": _vm.loading,
      "rounded": "sm"
    }
  }, [_vm.addressesByCountry.length > 0 ? _c('div', [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('Company Address'),
      "vid": "address",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var errors = _ref6.errors;
        return [_c('euro-select', {
          attrs: {
            "label": "".concat(_vm.$t('Company Address'), "*"),
            "error-messages": errors,
            "options": _vm.addressesSelectable,
            "searchable": ""
          },
          model: {
            value: _vm.form.address,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "address", $$v);
            },
            expression: "form.address"
          }
        })];
      }
    }], null, false, 2481052472)
  })], 1) : _c('div', [_c('span', {
    staticClass: "mr-12"
  }, [_vm._v(_vm._s(_vm.$t("Add address for the selected country")) + "*:")]), _c('router-link', {
    staticClass: "btn btn-light-primary font-weight-bolder",
    attrs: {
      "to": {
        name: 'detail-legal-address',
        params: {
          companyID: _vm.legalPerson.id,
          openModal: true
        }
      }
    }
  }, [_c('span', {
    staticClass: "d-flex"
  }, [_c('span', {
    staticClass: "svg-icon svg-icon-sm"
  }, [_c('inline-svg', {
    attrs: {
      "src": "/media/svg/icons/Communication/Address-card.svg"
    }
  })], 1), _vm._v(" " + _vm._s(_vm.$t("Add address")) + " ")])])], 1)])], 1), _c('div', {
    staticClass: "col-12"
  }, [_c('b-overlay', {
    attrs: {
      "show": _vm.loading,
      "rounded": "sm"
    }
  }, [_vm.vatsByCountry.length > 0 ? _c('div', [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('Vat Number'),
      "vid": "evat",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var errors = _ref7.errors;
        return [_c('euro-select', {
          attrs: {
            "label": "".concat(_vm.$t('Vat Number'), "*"),
            "error-messages": errors,
            "options": _vm.vatSelectable,
            "searchable": ""
          },
          model: {
            value: _vm.form.evat,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "evat", $$v);
            },
            expression: "form.evat"
          }
        })];
      }
    }], null, false, 3596015962)
  })], 1) : _c('div', {
    staticClass: "mt-5 mb-5"
  }, [_c('span', {
    staticClass: "mr-30"
  }, [_vm._v(_vm._s(_vm.$t("Add VAT for the selected country")) + "*:")]), _c('router-link', {
    staticClass: "btn btn-light-primary font-weight-bolder",
    attrs: {
      "to": {
        name: 'detail-legal-vat',
        params: {
          companyID: _vm.legalPerson.id,
          openModal: true
        }
      }
    }
  }, [_c('span', {
    staticClass: "d-flex"
  }, [_c('span', {
    staticClass: "svg-icon svg-icon-sm"
  }, [_c('inline-svg', {
    attrs: {
      "src": "/media/svg/icons/Files/File.svg"
    }
  })], 1), _vm._v(" " + _vm._s(_vm.$t("Add VAT")) + " ")])])], 1)])], 1), _c('div', {
    staticClass: "col-12"
  }, [_c('validation-provider', {
    staticClass: "w-100",
    attrs: {
      "name": _vm.$t('Attachment'),
      "vid": "attachment_tus"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref8) {
        var _vm$form, _vm$form2, _vm$form2$registratio, _vm$form3, _vm$form4, _vm$form5, _vm$form6, _vm$form6$registratio, _vm$form7, _vm$form8;
        var errors = _ref8.errors;
        return [_c('div', {
          attrs: {
            "id": "attachmentFile"
          }
        }, [_c('euro-tus-file-input', {
          attrs: {
            "label": "".concat(_vm.$t('Attachment'), "*"),
            "error-messages": errors,
            "accept": "application/pdf",
            "disabled": !((_vm$form = _vm.form) !== null && _vm$form !== void 0 && _vm$form.country) || !((_vm$form2 = _vm.form) !== null && _vm$form2 !== void 0 && (_vm$form2$registratio = _vm$form2.registration_number) !== null && _vm$form2$registratio !== void 0 && _vm$form2$registratio.length) || !((_vm$form3 = _vm.form) !== null && _vm$form3 !== void 0 && _vm$form3.address) || !((_vm$form4 = _vm.form) !== null && _vm$form4 !== void 0 && _vm$form4.evat),
            "hint": _vm.$t('Accept .pdf - max size 20MB'),
            "attachment": _vm.existingForm.attachment
          },
          on: {
            "change": _vm.onEuroTusFileInputChange
          }
        }), !((_vm$form5 = _vm.form) !== null && _vm$form5 !== void 0 && _vm$form5.country) || !((_vm$form6 = _vm.form) !== null && _vm$form6 !== void 0 && (_vm$form6$registratio = _vm$form6.registration_number) !== null && _vm$form6$registratio !== void 0 && _vm$form6$registratio.length) || !((_vm$form7 = _vm.form) !== null && _vm$form7 !== void 0 && _vm$form7.address) || !((_vm$form8 = _vm.form) !== null && _vm$form8 !== void 0 && _vm$form8.evat) ? _c('b-tooltip', {
          attrs: {
            "target": "attachmentFile"
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("Enter the above required data first")) + " ")]) : _vm._e()], 1)];
      }
    }])
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }