<template>
  <validation-observer ref="validationObserver" v-slot="{ handleSubmit }">
    <b-modal id="create-paycheck-modal" hide-footer @hidden="onModalHidden">
      <template #modal-header>
        <div class="w-100 h-100 d-flex justify-content-between align-items-center">
          <template v-if="isEdit">
            <h4 class="mb-0">{{ $t("Update Employee Paycheck") }}</h4>
          </template>
          <template v-else>
            <h4 class="mb-0">{{ $t("Create Employee Paycheck") }}</h4>
          </template>
          <div class="d-flex align-items-center">
            <b-button variant="success" class="mr-2" :class="{ 'spinner spinner-light spinner-right': isSaving }"
              @click="handleSubmit(submit)">
              {{ $t("Save") }}
            </b-button>
            <b-button @click="closeModal">
              {{ $t('Cancel') }}
            </b-button>
          </div>
        </div>
      </template>

      <physical-person-paycheck-form :physical-person="physicalPerson" :existing-form="existingForm" accept=".pdf"
        @remove-attachment="removeAttachment" @input="onPaycheckFormInput"></physical-person-paycheck-form>
    </b-modal>
  </validation-observer>
</template>
<script>
import PhysicalPersonPaycheckForm from "@/view/components/forms/physical-person/PhysicalPersonPaycheckForm.vue";
import PhysicalPersonPaycheckService from "@/core/services/physical-person/physical-person-employee-paycheck.service";
import icons from "@/core/config/icons";
import { backendErrorSwal } from "@/core/helpers/swal";
import Swal from "sweetalert2";
import { getValidationErrors } from "@/core/helpers";
import moment from "moment";
import EmployeeService from '@/core/services/employee/employee.service';

export default {
  components: {
    PhysicalPersonPaycheckForm,
  },

  props: {
    physicalPerson: {
      type: Object,
      required: true,
    },
    existingForm: {
      type: Object,
      default: () => true,
    }
  },

  data() {
    return {
      icons,
      item: null,
      isSaving: false,
      apiErrors: null,
      form: null,
    };
  },

  computed: {
    isEdit() {
      return Object.keys(this.existingForm).length > 0
    },
  },

  watch: {
    apiErrors: {
      deep: true,
      handler(val) {
        const formObs = this.$refs.validationObserver;
        const newErrors = getValidationErrors(val, this.item);
        formObs.setErrors({ ...newErrors });
      },
    },
  },

  methods: {
    moment,
    closeModal() {
      this.$bvModal.hide("create-paycheck-modal");
    },

    onModalHidden() {
      this.item = null;
    },

    formatDate(date) {
      return moment(date).date('01').format("YYYY-MM-DD");
    },

    async submit() {
      this.isSaving = true;
      let period = this.formatDate(this.item.date);
      try {
        if (this.isEdit) {
          const form = {
            employee: this.item.employee_id, date: period, attachments_tus: this.item.attachments_tus,
            description: this.item.description
          }
          await PhysicalPersonPaycheckService.update(form, this.item.id);
        } else {
          this.item.date = period;
          await PhysicalPersonPaycheckService.create({ employee: this.physicalPerson.employee_id, ...this.item });
        }
        this.$emit("refresh");
        this.closeModal();

        let title = this.$t("Document created")
        if (this.isEdit) {
          title = this.$t("Document Updated")
        }
        Swal.fire(this.$t("Success"), title, "success");
      } catch (err) {
        if (err?.response?.status === 400) {
          this.apiErrors = err.response.data;
        } else {
          let message = err?.response?.data?.detail ?? this.$t("Failed to create the document. Please try again.")
          if (this.isEdit) {
            message = err?.response?.data?.detail ?? this.$t("Failed to update the document. Please try again.")
          }
          backendErrorSwal(message, err);
        }
      } finally {
        this.isSaving = false;
      }
    },

    onPaycheckFormInput(p) {
      this.item = p;
    },

    removeAttachment(id) {
      EmployeeService.deletePaymentCheckAttachment(id).then(() => {
        this.$emit("refresh");
      });
    }
  },
};
</script>

<style scoped>
.modal-header {
  padding: 0;
}
</style>