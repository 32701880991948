var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "form row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('Passport'),
      "vid": "passport",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('euro-select', {
          attrs: {
            "label": "".concat(_vm.$t('Passport'), "*"),
            "options": _vm.passportSelect,
            "error-messages": errors,
            "searchable": ""
          },
          model: {
            value: _vm.form.passport,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "passport", $$v);
            },
            expression: "form.passport"
          }
        })];
      }
    }])
  })], 1), _c('div', {
    staticClass: "col-12"
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('Destination Country'),
      "vid": "destination_country",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('euro-select', {
          attrs: {
            "searchable": "",
            "options": _vm.countriesSelects,
            "label": "".concat(_vm.$t('Destination Country'), "*"),
            "error-messages": errors
          },
          model: {
            value: _vm.form.destination_country,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "destination_country", $$v);
            },
            expression: "form.destination_country"
          }
        })];
      }
    }])
  })], 1), _c('div', {
    staticClass: "col-12 col-lg-7"
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('Visa Kind'),
      "vid": "visa_kind",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var errors = _ref3.errors;
        return [_c('euro-select', {
          attrs: {
            "label": "".concat(_vm.$t('Visa Kind'), "*"),
            "options": _vm.visaKindSelect,
            "error-messages": errors,
            "searchable": ""
          },
          model: {
            value: _vm.form.visa_kind,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "visa_kind", $$v);
            },
            expression: "form.visa_kind"
          }
        })];
      }
    }])
  })], 1), _c('div', {
    staticClass: "col-12 col-lg-5"
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('Duration of Stay (Days)'),
      "vid": "staying_days",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var errors = _ref4.errors;
        return [_c('euro-input', {
          attrs: {
            "label": "".concat(_vm.$t('Duration of Stay (Days)'), "*"),
            "type": "number",
            "error-messages": errors
          },
          model: {
            value: _vm.form.staying_days,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "staying_days", $$v);
            },
            expression: "form.staying_days"
          }
        })];
      }
    }])
  })], 1), _c('div', {
    staticClass: "col-12 col-lg-6"
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('Visa number'),
      "vid": "number",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var errors = _ref5.errors;
        return [_c('euro-input', {
          attrs: {
            "label": "".concat(_vm.$t('Visa number'), "*"),
            "error-messages": errors
          },
          model: {
            value: _vm.form.number,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "number", $$v);
            },
            expression: "form.number"
          }
        })];
      }
    }])
  })], 1), _c('div', {
    staticClass: "col-12 col-lg-6"
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('Release date'),
      "vid": "emission_date",
      "rules": "required|before_now"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var errors = _ref6.errors;
        return [_c('date-picker', {
          attrs: {
            "error-messages": errors,
            "label": "".concat(_vm.$t('Release date'), "*"),
            "overrides": _vm.dateOfIssueOverrides
          },
          model: {
            value: _vm.form.emission_date,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "emission_date", $$v);
            },
            expression: "form.emission_date"
          }
        })];
      }
    }])
  })], 1), _c('div', {
    staticClass: "col-12 col-lg-6"
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('Valid from'),
      "vid": "valid_from",
      "rules": "required|after_if:@date_of_issue|before_if:@valid_to"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var errors = _ref7.errors;
        return [_c('date-picker', {
          attrs: {
            "error-messages": errors,
            "overrides": _vm.validFromOverrides,
            "label": "".concat(_vm.$t('Valid from'), "*")
          },
          model: {
            value: _vm.form.valid_from,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "valid_from", $$v);
            },
            expression: "form.valid_from"
          }
        })];
      }
    }])
  })], 1), _c('div', {
    staticClass: "col-12 col-lg-6"
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('Valid to'),
      "vid": "valid_to",
      "rules": "required|after_if:@valid_from}|after_if:@date_of_issue}"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref8) {
        var errors = _ref8.errors;
        return [_c('date-picker', {
          attrs: {
            "error-messages": errors,
            "overrides": _vm.validToOverrides,
            "label": "".concat(_vm.$t('Valid to'), "*")
          },
          model: {
            value: _vm.form.valid_to,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "valid_to", $$v);
            },
            expression: "form.valid_to"
          }
        })];
      }
    }])
  })], 1), _c('div', {
    staticClass: "col-12 col-lg-6"
  }, [_c('label', {
    staticClass: "checkbox mt-4"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.unlimitedEntries,
      expression: "unlimitedEntries"
    }],
    attrs: {
      "type": "checkbox",
      "name": "select"
    },
    domProps: {
      "checked": Array.isArray(_vm.unlimitedEntries) ? _vm._i(_vm.unlimitedEntries, null) > -1 : _vm.unlimitedEntries
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.unlimitedEntries,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && (_vm.unlimitedEntries = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.unlimitedEntries = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.unlimitedEntries = $$c;
        }
      }
    }
  }), _c('span', {
    staticClass: "mr-4"
  }), _vm._v(" " + _vm._s(_vm.$t('Unlimited entries')) + " ")]), _c('div', {
    staticClass: "font-size-xs mt-3"
  }, [_vm._v(_vm._s(_vm.$t('Check if the visa allow unlimited entries in the country')))])]), _c('div', {
    staticClass: "col-12 col-lg-6"
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('Number of entries'),
      "vid": "entries",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref9) {
        var errors = _ref9.errors;
        return [_c('euro-input', {
          attrs: {
            "label": "".concat(_vm.$t('Number of entries'), "*"),
            "type": "number",
            "error-messages": errors,
            "disabled": _vm.unlimitedEntries
          },
          model: {
            value: _vm.form.entries,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "entries", $$v);
            },
            expression: "form.entries"
          }
        })];
      }
    }])
  })], 1), _c('div', {
    staticClass: "col-12"
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('Notes'),
      "vid": "notes"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref10) {
        var errors = _ref10.errors;
        return [_c('euro-text-area', {
          attrs: {
            "label": "".concat(_vm.$t('Notes')),
            "error-messages": errors
          },
          model: {
            value: _vm.form.notes,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "notes", $$v);
            },
            expression: "form.notes"
          }
        })];
      }
    }])
  })], 1), _c('div', {
    staticClass: "col-12"
  }, [_c('validation-provider', {
    staticClass: "w-100",
    attrs: {
      "name": _vm.$t('Attachment'),
      "vid": "attachment_tus"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref11) {
        var _vm$form, _vm$form$number, _vm$form2, _vm$form2$number;
        var errors = _ref11.errors;
        return [_c('div', {
          attrs: {
            "id": "attachmentFile"
          }
        }, [_c('euro-tus-file-input', {
          attrs: {
            "label": "".concat(_vm.$t('Attachment'), "*"),
            "error-messages": errors,
            "accept": "application/pdf",
            "disabled": !((_vm$form = _vm.form) !== null && _vm$form !== void 0 && (_vm$form$number = _vm$form.number) !== null && _vm$form$number !== void 0 && _vm$form$number.length),
            "hint": _vm.$t('Accept .pdf - max size 20MB'),
            "attachment": _vm.existingForm.attachment
          },
          on: {
            "change": function change(tusId) {
              _vm.form.attachment_tus = tusId;
            }
          }
        }), !((_vm$form2 = _vm.form) !== null && _vm$form2 !== void 0 && (_vm$form2$number = _vm$form2.number) !== null && _vm$form2$number !== void 0 && _vm$form2$number.length) ? _c('b-tooltip', {
          attrs: {
            "target": "attachmentFile"
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("Enter passport number first")) + " ")]) : _vm._e()], 1)];
      }
    }])
  })], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }