var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "form row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('Country'),
      "vid": "country",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('euro-select', {
          attrs: {
            "searchable": "",
            "options": _vm.countriesSelects,
            "label": "".concat(_vm.$t('Country'), "*"),
            "error-messages": errors
          },
          model: {
            value: _vm.form.country,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "country", $$v);
            },
            expression: "form.country"
          }
        })];
      }
    }])
  })], 1), _c('div', {
    staticClass: "col-12"
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('Language'),
      "vid": "doc_language"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('euro-select', {
          attrs: {
            "label": _vm.$t('Language'),
            "options": _vm.languageSelect,
            "error-messages": errors,
            "searchable": ""
          },
          model: {
            value: _vm.form.doc_language,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "doc_language", $$v);
            },
            expression: "form.doc_language"
          }
        })];
      }
    }])
  })], 1), _c('div', {
    staticClass: "col-6"
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('ID document number'),
      "vid": "number",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var errors = _ref3.errors;
        return [_c('euro-input', {
          attrs: {
            "label": "".concat(_vm.$t('ID document number'), "*"),
            "error-messages": errors
          },
          model: {
            value: _vm.form.number,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "number", $$v);
            },
            expression: "form.number"
          }
        })];
      }
    }])
  })], 1), _c('div', {
    staticClass: "col-6"
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('Release date'),
      "vid": "date_of_issue",
      "rules": "required|before_now"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var errors = _ref4.errors;
        return [_c('date-picker', {
          attrs: {
            "overrides": _vm.dateOfIssueOverrides,
            "label": "".concat(_vm.$t('Release date'), "*"),
            "error-messages": errors
          },
          model: {
            value: _vm.form.date_of_issue,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "date_of_issue", $$v);
            },
            expression: "form.date_of_issue"
          }
        })];
      }
    }])
  })], 1), _c('div', {
    staticClass: "col-6"
  }, [_c('div', {
    attrs: {
      "id": "validFrom"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('Valid from'),
      "vid": "valid_from"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var errors = _ref5.errors;
        return [_c('date-picker', {
          attrs: {
            "value": _vm.form.date_of_issue ? _vm.form.valid_from = _vm.form.date_of_issue : '',
            "label": "".concat(_vm.$t('Valid from')),
            "disabled": true,
            "error-messages": errors
          }
        }), !_vm.form.date_of_issue ? _c('b-tooltip', {
          attrs: {
            "target": "validFrom"
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("Enter Release Date")) + " ")]) : _vm._e()];
      }
    }])
  })], 1)]), _c('div', {
    staticClass: "col-6"
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('Valid to'),
      "vid": "valid_to",
      "rules": "required|after_if:@date_of_issue}"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var errors = _ref6.errors;
        return [_c('date-picker', {
          attrs: {
            "label": "".concat(_vm.$t('Valid to'), "*"),
            "error-messages": errors,
            "overrides": _vm.validToOverrides
          },
          model: {
            value: _vm.form.valid_to,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "valid_to", $$v);
            },
            expression: "form.valid_to"
          }
        })];
      }
    }])
  })], 1), _vm.showTravel ? _c('div', {
    staticClass: "col-12"
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('Valid for travel'),
      "vid": "valid_travel",
      "rules": "required|after_if:@valid_to}"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var errors = _ref7.errors;
        return [_c('euro-radio', {
          attrs: {
            "options": _vm.TRAVEL,
            "label": "".concat(_vm.$t('Valid for travel'), "*"),
            "horizontal": "",
            "error-messages": errors,
            "size": "lg"
          },
          model: {
            value: _vm.form.valid_for_travel,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "valid_for_travel", $$v);
            },
            expression: "form.valid_for_travel"
          }
        })];
      }
    }], null, false, 2052197374)
  })], 1) : _vm._e(), _c('div', {
    staticClass: "col-12"
  }, [_c('validation-provider', {
    staticClass: "w-100",
    attrs: {
      "name": _vm.$t('Attachment'),
      "vid": "attachment_tus"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref8) {
        var _vm$form, _vm$form$number, _vm$form2, _vm$form2$number;
        var errors = _ref8.errors;
        return [_c('div', {
          attrs: {
            "id": "attachmentFile"
          }
        }, [_c('euro-tus-file-input', {
          attrs: {
            "label": _vm.attachmentFrontLabel + '*',
            "error-messages": errors,
            "accept": "application/pdf",
            "disabled": !((_vm$form = _vm.form) !== null && _vm$form !== void 0 && (_vm$form$number = _vm$form.number) !== null && _vm$form$number !== void 0 && _vm$form$number.length),
            "hint": _vm.$t('Accept .pdf - max size 20MB'),
            "attachment": _vm.existingForm.attachment
          },
          on: {
            "change": function change(tusId) {
              _vm.form.attachment_tus = tusId;
            }
          }
        }), !((_vm$form2 = _vm.form) !== null && _vm$form2 !== void 0 && (_vm$form2$number = _vm$form2.number) !== null && _vm$form2$number !== void 0 && _vm$form2$number.length) ? _c('b-tooltip', {
          attrs: {
            "target": "attachmentFile"
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("Enter document number first")) + " ")]) : _vm._e()], 1)];
      }
    }])
  })], 1), _c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "row m-1 my-3"
  }, [_c('span', {
    staticClass: "col- switch"
  }, [_c('label', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.attachmentBack,
      expression: "attachmentBack"
    }],
    attrs: {
      "type": "checkbox",
      "name": "select"
    },
    domProps: {
      "checked": Array.isArray(_vm.attachmentBack) ? _vm._i(_vm.attachmentBack, null) > -1 : _vm.attachmentBack
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.attachmentBack,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && (_vm.attachmentBack = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.attachmentBack = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.attachmentBack = $$c;
        }
      }
    }
  }), _c('span')])]), _c('label', {
    staticClass: "col- col-form-label"
  }, [_vm._v(_vm._s(_vm.$t('Add attachment back')))]), _c('span', {
    attrs: {
      "data-toggle": "tooltip",
      "title": _vm.$t('Switch on for uploading front and back of the document separately. You can also combine front/back in a single file in the attachment field.')
    }
  }, [_c('i', {
    staticClass: "fa fa-sm fa-info-circle text-primary"
  })])])]), _vm.attachmentBack ? _c('div', {
    staticClass: "col-12"
  }, [_c('validation-provider', {
    staticClass: "w-100",
    attrs: {
      "name": _vm.$t('Attachment'),
      "vid": "attachment_back_tus"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref9) {
        var _vm$form3, _vm$form3$number, _vm$form4, _vm$form4$number;
        var errors = _ref9.errors;
        return [_c('div', {
          attrs: {
            "id": "attachmentFileBack"
          }
        }, [_c('euro-tus-file-input', {
          attrs: {
            "label": _vm.$t('Attachment back'),
            "error-messages": errors,
            "accept": "application/pdf",
            "disabled": !((_vm$form3 = _vm.form) !== null && _vm$form3 !== void 0 && (_vm$form3$number = _vm$form3.number) !== null && _vm$form3$number !== void 0 && _vm$form3$number.length),
            "hint": _vm.$t('Accept .pdf - max size 20MB'),
            "attachment": _vm.existingForm.attachment_back
          },
          on: {
            "change": function change(tusId) {
              _vm.form.attachment_back_tus = tusId;
            }
          }
        }), !((_vm$form4 = _vm.form) !== null && _vm$form4 !== void 0 && (_vm$form4$number = _vm$form4.number) !== null && _vm$form4$number !== void 0 && _vm$form4$number.length) ? _c('b-tooltip', {
          attrs: {
            "target": "attachmentFileBack"
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("Enter the above required data first")) + " ")]) : _vm._e()], 1)];
      }
    }], null, false, 220106378)
  })], 1) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }