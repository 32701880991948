import api from "../api.service";

class CompanyGroupsService {
  get PATH() {
    return "company-group";
  }

  async getAll({
    company,
    perPage,
    page,
    sortBy = null,
    search = null,
    sortDesc = null
  }) {
    const offset = (page - 1) * perPage;
    const params = {
      limit: perPage,
      offset,
      search,
      company
    }
    if (sortBy) {
      params.ordering = sortBy;
    }
    if (sortDesc && sortBy) {
      sortBy = "-" + sortBy;
      params.ordering = sortBy;
    }

    const res = await api.get(`${this.PATH}/`, {
      params,
    });
    return res.data;
  }

  async create(body) {
    const res = await api.post(`${this.PATH}/`, body);
    return res.data;
  }

  async update(body, id) {
    const res = await api.patch(`${this.PATH}/${id}/`, body);
    return res.data;
  }

  async delete(id) {
    await api.delete(`${this.PATH}/${id}/`);
  }
}

export default new CompanyGroupsService();