var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "form row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('Country'),
      "vid": "country",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('euro-select', {
          attrs: {
            "options": _vm.countriesSelect,
            "error-messages": errors,
            "label": "".concat(_vm.$t('Country'), "*"),
            "searchable": ""
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_c('i', {
                directives: [{
                  name: "b-tooltip",
                  rawName: "v-b-tooltip"
                }],
                class: _vm.icons.lineAwesome.info,
                attrs: {
                  "title": _vm.$t('This is the country where the education document has been obtained')
                }
              })];
            },
            proxy: true
          }], null, true),
          model: {
            value: _vm.form.country,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "country", $$v);
            },
            expression: "form.country"
          }
        })];
      }
    }])
  })], 1), _c('div', {
    staticClass: "col-12"
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('Education level'),
      "vid": "level",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('euro-select', {
          attrs: {
            "options": _vm.educationKindSelect,
            "label": "".concat(_vm.$t('Education level'), "*"),
            "error-messages": errors
          },
          model: {
            value: _vm.form.level,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "level", $$v);
            },
            expression: "form.level"
          }
        })];
      }
    }])
  })], 1), _c('div', {
    staticClass: "col-12"
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('Attainment date'),
      "vid": "attainment_date"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var errors = _ref3.errors;
        return [_c('date-picker', {
          attrs: {
            "label": _vm.$t('Attainment date'),
            "overrides": _vm.attainmentDateOverrides,
            "error-messages": errors
          },
          model: {
            value: _vm.form.attainment_date,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "attainment_date", $$v);
            },
            expression: "form.attainment_date"
          }
        })];
      }
    }])
  })], 1), _c('div', {
    staticClass: "col-12"
  }, [_c('validation-provider', {
    staticClass: "w-100",
    attrs: {
      "name": _vm.$t('Attachment'),
      "vid": "attachment_tus"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var _vm$form, _vm$form2, _vm$form3, _vm$form4, _vm$form5, _vm$form6;
        var errors = _ref4.errors;
        return [_c('div', {
          attrs: {
            "id": "attachmentFile"
          }
        }, [_c('euro-tus-file-input', {
          attrs: {
            "label": _vm.$t('Attachment'),
            "error-messages": errors,
            "disabled": ((_vm$form = _vm.form) === null || _vm$form === void 0 ? void 0 : _vm$form.level) !== 0 && !((_vm$form2 = _vm.form) !== null && _vm$form2 !== void 0 && _vm$form2.level) || !((_vm$form3 = _vm.form) !== null && _vm$form3 !== void 0 && _vm$form3.country),
            "accept": "application/pdf",
            "hint": _vm.$t('Accept .pdf - max size 20MB'),
            "attachment": _vm.existingForm.attachment
          },
          on: {
            "change": function change(tusId) {
              _vm.form.attachment_tus = tusId;
            }
          }
        }), ((_vm$form4 = _vm.form) === null || _vm$form4 === void 0 ? void 0 : _vm$form4.level) !== 0 && !((_vm$form5 = _vm.form) !== null && _vm$form5 !== void 0 && _vm$form5.level) || !((_vm$form6 = _vm.form) !== null && _vm$form6 !== void 0 && _vm$form6.country) ? _c('b-tooltip', {
          attrs: {
            "target": "attachmentFile"
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("Enter the above required data first")) + " ")]) : _vm._e()], 1)];
      }
    }])
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }