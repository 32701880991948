<template>
  <div class="form row">
    <div class="col-12">
      <validation-provider v-slot="{ errors }" :name="$t('Country')" vid="country" rules="required">
        <euro-select v-model="form.country" searchable :options="countriesSelects" :label="`${$t('Country')}*`"
          :error-messages="errors" />
      </validation-provider>
    </div>
    <div class="col-12">
      <validation-provider v-slot="{ errors }" :name="$t('Language')" vid="doc_language">
        <euro-select v-model="form.doc_language" :label="$t('Language')" :options="languageSelect"
          :error-messages="errors" searchable>
        </euro-select>
      </validation-provider>
    </div>
    <div class="col-6">
      <validation-provider v-slot="{ errors }" :name="$t('ID document number')" vid="number" rules="required">
        <euro-input v-model="form.number" :label="`${$t('ID document number')}*`" :error-messages="errors"></euro-input>
      </validation-provider>
    </div>
    <div class="col-6">
      <validation-provider v-slot="{ errors }" :name="$t('Release date')" vid="date_of_issue" rules="required|before_now">
        <date-picker v-model="form.date_of_issue" :overrides="dateOfIssueOverrides" :label="`${$t('Release date')}*`"
          :error-messages="errors" />
      </validation-provider>
    </div>
    <div class="col-6">
      <div id="validFrom">
        <validation-provider v-slot="{ errors }" :name="$t('Valid from')" vid="valid_from">
          <date-picker :value="form.date_of_issue ? form.valid_from = form.date_of_issue : ''"
            :label="`${$t('Valid from')}`" :disabled="true" :error-messages="errors" />
          <b-tooltip v-if="!form.date_of_issue" target="validFrom">
            {{ $t("Enter Release Date") }}
          </b-tooltip>
        </validation-provider>
      </div>
    </div>
    <div class="col-6">
      <validation-provider v-slot="{ errors }" :name="$t('Valid to')" vid="valid_to"
        rules="required|after_if:@date_of_issue}">
        <date-picker v-model="form.valid_to" :label="`${$t('Valid to')}*`" :error-messages="errors"
          :overrides="validToOverrides" />
      </validation-provider>
    </div>
    <div v-if="showTravel" class="col-12">
      <validation-provider v-slot="{ errors }" :name="$t('Valid for travel')" vid="valid_travel"
        rules="required|after_if:@valid_to}">
        <euro-radio v-model="form.valid_for_travel" :options="TRAVEL" :label="`${$t('Valid for travel')}*`" horizontal
          :error-messages="errors" size="lg"></euro-radio>
      </validation-provider>
    </div>
    <div class="col-12">
      <validation-provider v-slot="{ errors }" :name="$t('Attachment')" vid="attachment_tus" class="w-100">
        <div id="attachmentFile">
          <euro-tus-file-input :label="attachmentFrontLabel + '*'" :error-messages="errors" accept="application/pdf"
            :disabled="!form?.number?.length" :hint="$t('Accept .pdf - max size 20MB')"
            :attachment="existingForm.attachment" @change="tusId => {
              form.attachment_tus = tusId;
            }
              "></euro-tus-file-input>
          <b-tooltip v-if="!form?.number?.length" target="attachmentFile">
            {{ $t("Enter document number first") }}
          </b-tooltip>
        </div>
      </validation-provider>
    </div>
    <div class="col-12">
      <div class="row m-1 my-3">
        <span class="col- switch">
          <label>
            <input v-model="attachmentBack" type="checkbox" name="select" />
            <span></span>
          </label>
        </span>
        <label class="col- col-form-label">{{ $t('Add attachment back') }}</label>
        <span data-toggle="tooltip"
          :title="$t('Switch on for uploading front and back of the document separately. You can also combine front/back in a single file in the attachment field.')">
          <i class="fa fa-sm fa-info-circle text-primary"></i>
        </span>
      </div>
    </div>
    <div v-if="attachmentBack" class="col-12">
      <validation-provider v-slot="{ errors }" :name="$t('Attachment')" vid="attachment_back_tus" class="w-100">
        <div id="attachmentFileBack">
          <euro-tus-file-input :label="$t('Attachment back')" :error-messages="errors" accept="application/pdf"
            :disabled="!form?.number?.length" :hint="$t('Accept .pdf - max size 20MB')"
            :attachment="existingForm.attachment_back" @change="tusId => {
              form.attachment_back_tus = tusId;
            }
              "></euro-tus-file-input>
          <b-tooltip v-if="!form?.number?.length" target="attachmentFileBack">
            {{ $t("Enter the above required data first") }}
          </b-tooltip>
        </div>
      </validation-provider>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { AdministrativeStructuresMixin } from "@/core/mixins";
import DateService from "@/core/services/date.service";
import DatePicker from "@/view/components/datePicker/datePicker.vue";
import { mapGetters } from 'vuex';

export default {
  components: {
    DatePicker,
  },

  mixins: [AdministrativeStructuresMixin],
  props: {
    existingForm: {
      type: Object,
      default: null,
    },
    languages: {
      type: Array,
      required: true,
    },
    countries: {
      type: Array,
      required: true,
    },
  },

  data: () => ({
    form: {
      country: "",
      counterpart: "",
      number: "",
      date_of_issue: null,
      valid_from: null,
      valid_to: null,
      doc_language: "",
      attachment_tus: null,
      attachment_back_tus: null,
      valid_for_travel: false
    },
    attachmentBack: false,
    TRAVEL: [],
    showTravel: null,
    europeList: null,
    DateService,
  }),

  computed: {
    ...mapGetters("constants", ["europeanGroup"]),

    validToOverrides() {
      const out = {};

      if (this.form.valid_from) {
        out.minDate = DateService.toDate(this.form.valid_from);
      }

      return out;
    },

    dateOfIssueOverrides() {
      return {
        maxDate: moment().toDate(),
      };
    },

    languageSelect() {
      return this.languages.map(el => ({ value: el.iso_639_1, text: el.name_en }));
    },

    countriesSelects() {
      return this.countries.map(el => ({ value: el.iso, text: el.name }));
    },
    attachmentFrontLabel() {
      return this.attachmentBack ? this.$t('Attachment front') : this.$t('Attachment')
    }
  },

  watch: {
    attachmentBack() {
      if (!this.attachmentBack) {
        this.form.attachment_back_tus = null
      }
    },
    form: {
      deep: true,
      handler(val) {
        this.$emit("input", val);
      },
    },
    "form.country"(val, oldVal) {
      if (!val || (!!oldVal && oldVal !== val)) {
        this.form.counterpart = "";
      }
      if (val) {
        this.loadAdministrativesStructuresByCountry(val);
      } else {
        this.clearAdministrativeStrucutres();
      }
      if (this.europeList.includes(val) && !this.showTravel) {
        this.showTravel = true;
      } else {
        this.showTravel = false;
      }
    },
  },

  mounted() {
    if (this.existingForm) {
      this.form = { ...this.form, ...this.existingForm };
      this.attachmentBack = !!this.form.attachment_back;
    }
    this.europeList = this.europeanGroup.map(el => el.country);
    this.TRAVEL = [{ value: true, text: this.$t("Yes") }, { value: false, text: this.$t("No") }];
  },

  methods: {
    moment,
  },
};
</script>
