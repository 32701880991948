<template>
  <div class="form row">
    <div class="col-12">
      <validation-provider v-slot="{ errors }" :name="$t('Country')" vid="country" rules="required">
        <euro-select v-model="form.country" searchable :options="countriesSelects" :label="`${$t('Country')}*`"
          :error-messages="errors" />
      </validation-provider>
    </div>
    <div class="col-12">
      <validation-provider v-slot="{ errors }" :name="$t('Residence permit kind')" vid="permit_kind" rules="required">
        <euro-select v-model="form.permit_kind" searchable :multiselect-props="permitKindSelectProps"
          :label="`${$t('Residence permit kind')}*`" :error-messages="errors" />
      </validation-provider>
    </div>
    <div class="col-12">
      <validation-provider v-slot="{ errors }" :name="$t('Document number')" vid="number" rules="required">
        <euro-input v-model="form.number" :label="`${$t('Document number')}*`" :error-messages="errors"></euro-input>
      </validation-provider>
    </div>
    <div class="col-6">
      <validation-provider v-slot="{ errors }" :name="$t('Valid from')" vid="valid_from"
        rules="required|before_if:@date_to">
        <date-picker v-model="form.date_from" :label="`${$t('Valid from')}*`" :overrides="validFromOverrides"
          :error-messages="errors" />
      </validation-provider>
    </div>
    <div class="col-6">
      <validation-provider v-slot="{ errors }" :name="$t('Valid to')" vid="valid_to"
        rules="required|after_if:@date_from}">
        <date-picker v-model="form.date_to" :label="`${$t('Valid to')}*`" :error-messages="errors"
          :overrides="validToOverrides" />
      </validation-provider>
    </div>
    <div class="col-12">
      <validation-provider v-slot="{ errors }" :name="$t('Attachment')" vid="attachment_tus" class="w-100">
        <div id="attachmentFile">
          <euro-tus-file-input :label="attachmentFrontLabel" :error-messages="errors" accept="application/pdf"
            :disabled="!form.permit_kind || !form.country || !form.number || !form.date_from || !form.date_to"
            :hint="$t('Accept .pdf - max size 20MB')" :attachment="existingForm.attachment"
            @change="onAttachmentChange"></euro-tus-file-input>
          <b-tooltip v-if="!form.permit_kind || !form.country || !form.number || !form.date_from || !form.date_to"
            target="attachmentFile">
            {{ $t("Enter the above required data first") }}
          </b-tooltip>
        </div>
      </validation-provider>
    </div>
    <div class="col-12">
      <div class="row m-1 my-3">
        <span class="col- switch">
          <label>
            <input v-model="attachmentBack" type="checkbox" name="select" />
            <span></span>
          </label>
        </span>
        <label class="col- col-form-label">{{ $t('Add attachment back') }}</label>
        <span data-toggle="tooltip"
          :title="$t('Switch on for uploading front and back of the document separately. You can also combine front/back in a single file in the attachment field.')">
          <i class="fa fa-sm fa-info-circle text-primary"></i>
        </span>
      </div>
    </div>
    <div v-if="attachmentBack" class="col-12">
      <validation-provider v-slot="{ errors }" :name="$t('Attachment')" vid="attachment_back_tus" class="w-100">
        <div id="attachmentFileBack">
          <euro-tus-file-input :label="$t('Attachment back')" :error-messages="errors" accept="application/pdf"
            :disabled="!form.permit_kind || !form.country || !form.number || !form.date_from || !form.date_to"
            :hint="$t('Accept .pdf - max size 20MB')" :attachment="existingForm.attachment_back" @change="tusId => {
              form.attachment_back_tus = tusId;
            }
              "></euro-tus-file-input>
          <b-tooltip v-if="!form.permit_kind || !form.country || !form.number || !form.date_from || !form.date_to"
            target="attachmentFileBack">
            {{ $t("Enter the above required data first") }}
          </b-tooltip>
        </div>
      </validation-provider>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { AdministrativeStructuresMixin } from "@/core/mixins";
import DateService from "@/core/services/date.service";
import PhysicalPersonResidencePermitService from "@/core/services/physical-person/physical-person-residence-permit.service";
import { mapGetters } from "vuex";


import DatePicker from "@/view/components/datePicker/datePicker.vue";
import { backendErrorSwal } from "@/core/helpers/swal";
import { getPhysicalPersonHomeResidenceCountry } from "@/core/abstractions/physicalPerson";

export default {
  components: {
    DatePicker,
  },

  mixins: [AdministrativeStructuresMixin],
  props: {
    physicalPerson: {
      type: Object,
      default: null
    },
    existingForm: {
      type: Object,
      default: null,
    },
    countries: {
      type: Array,
      required: true,
    },

    permitOptions: {
      type: Array,
      default: () => [],
    },
  },

  data: () => ({
    residencePermitOptions: [],
    attachmentBack: false,

    form: {
      country: "",
      counterpart: "",
      number: "",
      date_of_issue: null,
      valid_from: null,
      valid_to: null,
      doc_language: "",
      attachment_tus: null,
      attachment_tus_back: null,

    },
    DateService,
  }),

  computed: {
    ...mapGetters("constants", ["europeanGroup"]),

    filteredCountries() {
      return this.countries.filter(country => this.europeanGroup.find(el => el.country == country.iso))
    },

    computedPermitOptions() {
      return this.permitOptions?.length ? this.permitOptions : this.residencePermitOptions;
    },

    permitKindSelectProps() {
      return {
        options: this.computedPermitOptions,
        label: "display_name",
        "track-by": "value",
        multiple: false,
        "allow-empty": false,
        searchable: true,
        "show-labels": false,
      };
    },
    validFromOverrides() {
      const out = {
        useCurrent: false,
      };

      if (this.form.date_of_issue) {
        out.minDate = moment(this.form.date_of_issue).toDate();
      }

      if (this.form.valid_to && this.form.valid_to != "Invalid date") {
        out.maxDate = moment(this.form.valid_to).toDate();
      }

      return out;
    },

    validToOverrides() {
      const out = {
        useCurrent: false,
      };

      if (this.form.valid_from) {
        out.minDate = DateService.toDate(this.form.valid_from);
      }

      return out;
    },

    dateOfIssueOverrides() {
      return {
        maxDate: moment().toDate(),
      };
    },

    countriesSelects() {
      return this.filteredCountries.map(el => ({ value: el.iso, text: el.name }));
    },
    attachmentFrontLabel() {
      return this.attachmentBack ? this.$t('Attachment front') + '*' : this.$t('Attachment') + '*'
    }
  },

  watch: {
    form: {
      deep: true,
      handler(val) {
        this.$emit("input", val);
      },
    },
    "form.country"(val, oldVal) {
      if (!val || (!!oldVal && oldVal !== val)) {
        this.form.counterpart = "";
      }
      if (val) {
        this.loadAdministrativesStructuresByCountry(val);
      } else {
        this.clearAdministrativeStrucutres();
      }
    },
    attachmentBack() {
      if (!this.attachmentBack) {
        this.form.attachment_back_tus = null
      }
    }
  },

  async mounted() {
    if (this.existingForm) {
      this.form = { ...this.form, ...this.existingForm };
      this.attachmentBack = this.form.attachment_back ? true : false;

    } else {
      this.form.country = getPhysicalPersonHomeResidenceCountry(this.physicalPerson) ?? "";
    }

    if (!this.permitOptions?.length) {
      try {
        const {
          actions: {
            POST: {
              permit_kind: { choices },
            },
          },
        } = await PhysicalPersonResidencePermitService.getOptions();

        this.residencePermitOptions = choices;
      } catch (err) {
        backendErrorSwal(err?.response?.data?.detail, err);
      }
    }
  },

  methods: {
    moment,

    onAttachmentChange(e) {
      this.form.attachment_tus = e;
    },
  },
};
</script>
