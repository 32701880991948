import CompanyAddressService from "@/core/services/company/company-address.service";

async function getCompanyAddresses({ companyId, perPage, page, sortBy, search, sortDesc, paginated = true } = {}) {
  if (!paginated) {
    const { count } = await CompanyAddressService.getAllByCompanyId(companyId, {
      perPage: 1,
      page: 1,
      sortBy,
      sortDesc,
      search,
    });
    const { results } = await CompanyAddressService.getAllByCompanyId(companyId, {
      perPage: count,
      page: 1,
      sortBy,
      sortDesc,
      search,
    });
    return results;
  }

  return await CompanyAddressService.getAllByCompanyId(companyId, {
    perPage,
    page,
    sortBy,
    sortDesc,
    search,
  });
}

export { getCompanyAddresses };
