<template>
  <div class="form row">
    <div class="col-12">
      <validation-provider v-slot="{ errors }" :name="$t('Country')" vid="country" rules="required">
        <euro-select v-model="form.country" :options="countriesSelect" :label="`${$t('Country')}*`" :disabled="isEdit"
          :error-messages="errors" searchable></euro-select>
      </validation-provider>
    </div>
    <div class="col-7">
      <validation-provider v-slot="{ errors }" :name="$t('Fiscal ID')" vid="fiscal_ID" :rules="rules.fiscalcode">
        <euro-input v-model="form.fiscal_ID" :label="`${$t('Fiscal ID')}*`" :error-messages="errors">
        </euro-input>
      </validation-provider>
    </div>
    <div class="col-5">
      <validation-provider v-slot="{ errors }" :name="$t('Expire date')" vid="valid_to">
        <date-picker v-model="form.valid_to" :overrides="toDateOverrides" :error-messages="errors"
          placeholder="YYYY-MM-DD" :label="`${$t('Expire date')}`">
        </date-picker>
      </validation-provider>
    </div>
    <div class="col-12">
      <validation-provider v-slot="{ errors }" :name="$t('Attachment')" class="w-100">
        <div id="attachmentFile">
          <euro-tus-file-input :label="attachmentFrontLabel" :error-messages="errors" accept="application/pdf"
            :attachment="existingForm.attachment" :disabled="!form?.fiscal_ID?.length" @change="tusId => {
        form.attachment_tus = tusId;
      }
        "></euro-tus-file-input>
          <b-tooltip v-if="!form?.fiscal_ID?.length" target="attachmentFile">
            {{ $t("Enter fiscal ID first") }}
          </b-tooltip>
        </div>
      </validation-provider>
    </div>
    <div class="col-12">
      <div class="row m-1 my-3">
        <span class="col- switch">
          <label>
            <input v-model="attachmentBack" type="checkbox" name="select" />
            <span></span>
          </label>
        </span>
        <label class="col- col-form-label">{{ $t('Add attachment back') }}</label>
        <span data-toggle="tooltip"
          :title="$t('Switch on for uploading front and back of the document separately. You can also combine front/back in a single file in the attachment field.')">
          <i class="fa fa-sm fa-info-circle text-primary"></i>
        </span>
      </div>
    </div>
    <div v-if="attachmentBack" class="col-12">
      <validation-provider v-slot="{ errors }" :name="$t('Attachment')" vid="attachment_back_tus" class="w-100">
        <div id="attachmentFileBack">
          <euro-tus-file-input :label="$t('Attachment back')" :error-messages="errors" accept="application/pdf"
            :disabled="!form?.fiscal_ID?.length" :hint="$t('Accept .pdf - max size 20MB')"
            :attachment="existingForm.attachment_back" @change="tusId => {
        form.attachment_back_tus = tusId;
      }
        "></euro-tus-file-input>
          <b-tooltip v-if="!form?.fiscal_ID?.length" target="attachmentFileBack">
            {{ $t("Enter the above required data first") }}
          </b-tooltip>
        </div>
      </validation-provider>
    </div>
    <div v-if="form.country == 'IT'" class="col-12">
      <div class="separator separator-dashed my-8"></div>
    </div>
    <div v-if="form.country == 'IT'" class="col-12">
      <div class="row" :class="{ 'form-group': form.to_generate }">
        <label class="col-8 col-form-label">Do you want to generate the fiscal code?</label>
        <div class="col-4 col-form-label">
          <div class="checkbox-inline">
            <label class="checkbox checkbox-primary">
              <input v-model="form.to_generate" type="checkbox" name="Checkboxes11" />
              <span></span>
            </label>
          </div>
        </div>
      </div>
    </div>
    <template v-if="form.country == 'IT' && form.to_generate">
      <div class="col-6">
        <validation-provider v-slot="{ errors }" :name="$t('First name')" vid="name" rules="required|max:30" tag="div">
          <euro-input v-model="form_person.first_name" :label="`${$t('First name')}*`" :disabled="!form_person.name"
            :error-messages="errors"></euro-input>
        </validation-provider>
      </div>
      <div class="col-6">
        <validation-provider v-slot="{ errors }" :name="$t('Last name')" vid="surname" rules="required|max:30"
          tag="div">
          <euro-input v-model="form_person.last_name" :label="`${$t('Last name')}*`" :disabled="!form_person.surname"
            :error-messages="errors"></euro-input>
        </validation-provider>
      </div>
      <div class="col-6">
        <validation-provider v-slot="{ errors }" :name="$t('Date of birth')" vid="date_birth" :rules="`required`"
          tag="div">
          <date-picker v-model="form_person.birth_date" :overrides="fromDateOverrides" :error-messages="errors"
            :label="`${$t('Date of birth')}*`">
          </date-picker>
        </validation-provider>
      </div>
      <div class="col-6">
        <validation-provider v-slot="{ errors }" :name="$t('Gender')" vid="gender" rules="required" tag="div">
          <euro-radio v-model="form_person.sex" :options="GENDERS" :label="`${$t('Gender')}*`" :error-messages="errors"
            size="lg"></euro-radio>
        </validation-provider>
      </div>
      <div class="col-12">
        <validation-provider v-slot="{ errors }" :name="$t('Place of birth')" vid="place_birth" rules="required"
          tag="div">
          <euro-place-input v-model="form_person.birth_place" :label="`${$t('Place of birth')}*`"
            :error-messages="errors"></euro-place-input>
        </validation-provider>
      </div>
      <div class="col-12">
        <b-button
          :disabled="!form_person.birth_place && !form_person.gender && !form_person.birth_date && !form_person.surname && !form_person.name"
          class="btn font-weight-bold w-100" variant="light-primary" @click="onGenerateFiscalIdClick">
          {{ $t('Generate fiscal ID') }}
        </b-button>
      </div>
    </template>
  </div>
</template>

<script>
import moment from "moment";
import DatePicker from "@/view/components/datePicker/datePicker.vue";
import { AdministrativeStructuresMixin } from "@/core/mixins";
import icons from "@/core/config/icons";
import { generateFiscalId } from "@/core/abstractions/physicalPerson/fiscalId";
import { backendErrorSwal } from "@/core/helpers/swal";
import PhysicalPersonDetailService from "@/core/services/physical-person/physical-person-detail.service";

export default {
  components: {
    DatePicker,
  },

  mixins: [AdministrativeStructuresMixin],
  props: {
    existingForm: {
      type: Object,
      default: null,
    },
    countries: {
      type: Array,
      required: true,
    },
    persona: {
      type: [Number, Object],
      default: () => null,
    },
    physicalPerson: {
      type: Object,
      required: true,
    },
    existingCountries: {
      type: Array,
      default: () => [],
    }
  },

  data() {
    return {
      icons,
      maxDate: new Date(),
      GENDERS: [],
      attachmentBack: false,
      form: {
        country: "",
        counterpart: "",
        fiscal_ID: "",
        date_birth: "",
        valid_to: null,
        attachment_tus: "",
        attachment_back_tus: "",
        to_generate: false
      },
      form_person: {
        name: null,
        surname: null,
        date_birth: "",
        place_birth: null,
        gender: "",
        iso: null,
      },
    };
  },

  computed: {
    isEdit() {
      return Object.keys(this.existingForm).length > 0
    },
    toDateOverrides() {
      return this.form.from_date !== "Invalid date" ? { minDate: this.form.from_date } : null;
    },

    fromDateOverrides() {
      return this.form.from_date ? { maxDate: moment() } : null;
    },

    birthDateOverrides() {
      return this.form.date_birth ? { maxDate: moment() } : null;
    },

    fiscalValidatorArgs() {
      const country = this.form.country || null;
      const persona = this.persona || this.existingForm?.persona || this.existingForm?.id || null;
      const physicalPerson = this.physicalPerson;
      const params = [!this.existingForm, country, false, persona];

      if (!persona)
        params.push({
          name: physicalPerson.name,
          surname: physicalPerson.surname,
          gender: physicalPerson.gender,
          birth_date: physicalPerson.date_birth,
          birth_place: this.personBirthCity,
        });
      return params;
    },

    personBirthCity() {
      const val = this.physicalPerson?.place_birth?.address_components?.filter(comp => comp.types[0] === "locality")[0];
      const fallBack = this.physicalPerson?.place_birth?.address_components?.filter(
        comp => comp.types[0] === "administrative_area_level_3",
      )[0];
      if (val) {
        if (val.short_name != undefined) {
          return val.short_name
        }
        return val?.long_name;
      } else if (fallBack) {
        if (fallBack.short_name != undefined) {
          return fallBack.short_name
        }
        return fallBack?.long_name;
      } else return null;
    },

    rules() {
      return {
        fiscalcode: {
          required: true,
          fiscal_id: [...this.fiscalValidatorArgs],
        },
      };
    },

    stringMaxDate() {
      return moment(this.maxDate, true).format("YYYY-MM-DD");
    },

    countriesSelect() {
      let selectedCountries = this.countries.map((x) => ({ text: this.$t(x.name), value: x.iso }))
      return selectedCountries.filter(el => {
        if ((this.form.country != '' & el.value == this.form.country) || this.existingCountries.find(country => el.value == country)) {
          el.$isDisabled = true;
        } else {
          el.$isDisabled = false;
        }
        return el;
      });;
    },

    canGenerateFiscalId() {
      const pp = this.physicalPerson;
      return pp?.gender && pp?.date_birth && pp?.place_birth && pp?.name && pp?.surname && this.form.country === "IT";
    },
    attachmentFrontLabel() {
      return this.attachmentBack ? this.$t('Attachment front') : this.$t('Attachment')
    }
  },

  watch: {
    attachmentBack() {
      if (!this.attachmentBack) {
        this.form.attachment_back_tus = null
      }
    },
    form: {
      deep: true,
      handler(val) {
        this.$emit("input", val);
      },
    },
    "form.country"(val, oldVal) {
      if (!val || (!!oldVal && oldVal !== val)) {
        this.form.counterpart = "";
      }
      if (val) {
        this.loadAdministrativesStructuresByCountry(val);
      } else {
        this.clearAdministrativeStrucutres();
      }
    },
  },

  async created() {
    this.fillForm();
    PhysicalPersonDetailService.getGenders()
      .then(res => res.map(el => ({ value: el.value, text: el.display_name })))
      .then(genders => {
        genders.forEach(gender => {
          if (gender.value != 'NA') {
            this.GENDERS.push(gender)
          }
        })
      })
      .catch(err => {
        console.error(err);
        backendErrorSwal(err?.response?.data?.detail, err);
      });
  },

  mounted() {
    if (this.existingForm) {
      this.form = { ...this.form, ...this.existingForm };
      this.attachmentBack = this.form.attachment_back ? true : false;
    } else {
      const citizenship = this.physicalPerson.citizenship_set[0] ?? null;
      if (citizenship) {
        this.form.country = citizenship.country;
      }
    }
  },

  methods: {
    fillForm() {
      this.form_person = {
        first_name: this.physicalPerson?.name,
        last_name: this.physicalPerson?.surname,
        birth_date: this.physicalPerson?.date_birth,
        birth_place: this.physicalPerson?.place_birth,
        sex: this.physicalPerson?.gender,
      };
    },

    async onGenerateFiscalIdClick() {
      try {
        const data_person = Object.assign({}, this.form_person)

        // Strim sex data, only accept S o M
        data_person.sex = this.form_person.sex[0]
        let birth_place_name;
        let iso_code;
        // Strim birth_place accepted only STRING
        this.form_person.birth_place.address_components.forEach(element => {
          if (element.types[0] == 'administrative_area_level_3' || element.types[0] == 'locality') {
            birth_place_name = element.short_name;
          }
          if (element.types[0] == "country" && element.types.length == 1 || element.types[0] == "country" && element.types[1] == "political" && element.short_name != "") {
            iso_code = element.short_name;
          }
        });
        data_person.birth_place = birth_place_name;
        data_person.iso = iso_code;
        const fiscal = await generateFiscalId(this.form.country, data_person);

        this.form.fiscal_ID = fiscal?.fiscal_id;
        this.form.to_generate = false
      } catch (err) {
        const message = "Cannot generate fiscal code"
        backendErrorSwal(message);
      }
    },
  },
};
</script>
