import api from "../api.service"

class PhysicalPersonPaycheckService {
  get PATH() {
    return "/employee-paycheck";
  }

  async getAll(employeeID, {
    perPage,
    page,
    sortBy = null,
    search = null,
    sortDesc = null,
    expand = null,
    fields = null,
    omit = null
  }) {
    const offset = (page - 1) * perPage;
    const params = {
      employee: employeeID,
      limit: perPage,
      offset,
      search
    };
    if (sortBy) {
      params.ordering = sortBy;
    }
    if (sortDesc && sortBy) {
      sortBy = "-" + sortBy;
      params.ordering = sortBy;
    }
    if (omit && omit.length) {
      params.omit = omit;
    }
    if (fields && fields.length) {
      params.fields = fields;
    }
    if (expand && expand.length) {
      params.expand = expand;
    }
    const res = await api.get(`${this.PATH}/`, {
      params,
    });
    return res.data;
  }
  async getOne(paycheck_id) {
    const res = await api.get(`${this.PATH}/${paycheck_id}/`);
    return res.data;
  }

  async create(body) {
    const res = await api.post(`${this.PATH}/`, body);
    return res.data;
  }
  async update(paycheck, id) {
    const res = await api.patch(`${this.PATH}/${id}/`, paycheck);
    return res.data;
  }
  async delete(paycheck_id) {
    await api.delete(`${this.PATH}/${paycheck_id}/`);
  }
}
export default new PhysicalPersonPaycheckService();