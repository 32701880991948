<template>
  <div>
    <div class="form row">
      <div class="col-12">
        <validation-provider v-slot="{ errors }" :name="$t('Country')" vid="country" rules="required">
          <euro-select v-model="form.country" :multiselect-props="{
            options: filteredCountries,
            label: 'name',
            'track-by': 'iso',
            searchable: true,
          }" :error-messages="errors" searchable :label="`${$t('Country')}*`">
          </euro-select>
        </validation-provider>
      </div>

      <div class="col-6">
        <validation-provider v-slot="{ errors }" :name="$t('Personal ID number')" vid="number" rules="required">
          <euro-input v-model="form.number" :label="`${$t('Personal ID number')}*`" :error-messages="errors"></euro-input>
        </validation-provider>
      </div>

      <div class="col-6">
        <validation-provider v-slot="{ errors }" :name="$t('Card number')" vid="card_identification_number"
          rules="numeric|min:20|max:20">
          <euro-input v-model="form.card_identification_number" :label="`${$t('Card number')}`"
            :error-messages="errors"></euro-input>
        </validation-provider>
      </div>

      <div class="col-6">
        <validation-provider v-slot="{ errors }" :name="$t('Valid from')" vid="valid_from" rules="required">
          <date-picker v-model="form.valid_from" :label="`${$t('Valid from')}*`" :overrides="validFromOverrides"
            :error-messages="errors"></date-picker>
        </validation-provider>
      </div>

      <div class="col-6">
        <validation-provider v-slot="{ errors }" :name="$t('Valid to')" vid="valid_to" rules="required">
          <date-picker v-model="form.valid_to" :label="`${$t('Valid to')}*`" :overrides="validToOverrides"
            :error-messages="errors"></date-picker>
        </validation-provider>
      </div>

      <div class="col-12">
        <validation-provider v-slot="{ errors }" :name="$t('Institution number')" vid="institution_identification_number">
          <euro-input v-model="form.institution_identification_number" :label="`${$t('Institution number')}`"
            :error-messages="errors"></euro-input>
        </validation-provider>
      </div>

      <div class="col-12">
        <validation-provider v-slot="{ errors }" :name="$t('Attachment')" vid="attachment_tus" class="w-100">
          <div id="attachmentFile">
            <euro-tus-file-input :label="attachmentFrontLabel + '*'" :error-messages="errors" accept="application/pdf"
              :disabled="!form?.number?.length" :hint="$t('Accept .pdf - max size 20MB')"
              :attachment="existingForm.attachment" @change="tusId => {
                form.attachment_tus = tusId;
              }
                "></euro-tus-file-input>
            <b-tooltip v-if="!form?.number?.length" target="attachmentFile">
              {{ $t("Enter the above required data first") }}
            </b-tooltip>
          </div>
        </validation-provider>
      </div>
      <div class="col-12">
        <div class="row m-1 my-3">
          <span class="col- switch">
            <label>
              <input v-model="attachmentBack" type="checkbox" name="select" />
              <span></span>
            </label>
          </span>
          <label class="col- col-form-label">{{ $t('Add attachment back') }}</label>
          <span data-toggle="tooltip"
            :title="$t('Switch on for uploading front and back of the document separately. You can also combine front/back in a single file in the attachment field.')">
            <i class="fa fa-sm fa-info-circle text-primary"></i>
          </span>
        </div>
      </div>
      <div v-if="attachmentBack" class="col-12">
        <validation-provider v-slot="{ errors }" :name="$t('Attachment')" vid="attachment_back_tus" class="w-100">
          <div id="attachmentFileBack">
            <euro-tus-file-input :label="$t('Attachment back')" :error-messages="errors" accept="application/pdf"
              :disabled="!form?.number?.length" :hint="$t('Accept .pdf - max size 20MB')"
              :attachment="existingForm.attachment_back" @change="tusId => {
                form.attachment_back_tus = tusId;
              }
                "></euro-tus-file-input>
            <b-tooltip v-if="!form?.number?.length" target="attachmentFileBack">
              {{ $t("Enter the above required data first") }}
            </b-tooltip>
          </div>
        </validation-provider>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from "@/view/components/datePicker/datePicker.vue";
import icons from "@/core/config/icons";
import { mapGetters, mapState } from "vuex";
import { getPhysicalPersonHomeResidenceCountry, getValidFiscalCode } from "@/core/abstractions/physicalPerson";
import DateService from "@/core/services/date.service";
export default {
  components: {
    DatePicker,
  },

  props: {
    existingForm: {
      type: Object,
      default: null,
    },
    physicalPerson: {
      type: Object,
      default: () => null,
    },
  },

  data() {
    return {
      icons,
      administrativeStructures: [],
      attachmentBack: false,
      form: {
        country: "",
        counterpart: "",
        valid_from: null,
        valid_to: null,
        number: "",
        institution_identification_number: "",
        card_identification_number: "",
        attachment_tus: null,
        attachment_back_tus: null
      },
    };
  },

  computed: {
    ...mapState("constants", ["countries"]),
    ...mapGetters("constants", ["ehicGroup"]),

    filteredCountries() {
      return this.countries.filter(country => this.ehicGroup.find(el => el.country == country.iso));
    },

    administrativeStructuresByCountry() {
      if (!this.form.country) return this.administrativeStructures;

      return this.administrativeStructures.filter(as => as.country_of_establishment === this.form.country);
    },

    validFromOverrides() {
      if (!this.form.valid_to || this.form.valid_to == "Invalid date") return {};

      return {
        maxDate: this.form.valid_to,
      };
    },

    validToOverrides() {
      if (!this.form.valid_from) {
        return {
          minDate: DateService.addYears(6, new Date()),
        };
      }

      return {
        minDate: DateService.addYears(6, this.form.valid_from),
      };
    },
    attachmentFrontLabel() {
      return this.attachmentBack ? this.$t('Attachment front') : this.$t('Attachment')
    }
  },

  watch: {
    form: {
      deep: true,
      handler(val) {
        this.$emit("input", val);
      },
    },
    attachmentBack() {
      if (!this.attachmentBack) {
        this.form.attachment_back_tus = null
      }
    },
  },

  async mounted() {
    if (this.existingForm) {
      this.form = { ...this.form, ...this.existingForm };
      this.attachmentBack = this.form.attachment_back ? true : false;
    } else {
      const fiscalCode = await getValidFiscalCode(this.physicalPerson);
      if (fiscalCode) {
        this.form.number = fiscalCode.fiscal_ID;
      }
      const country = getPhysicalPersonHomeResidenceCountry(this.physicalPerson);
      if (country) {
        this.form.country = country;
      }
    }
  },
};
</script>
