import i18n from "@/core/plugins/vue-i18n";
import CompanyVatService from "@/core/services/company/company-vat.service";

/**
 * getVats returns a list of VATs of a given company.
 */
async function getVats({ companyId, perPage, page, sortBy, search, sortDesc, paginated = true } = {}) {
  if (!paginated) {
    const { results } = await CompanyVatService.getAllByCompanyPaginated(companyId, {
      perPage: 2000,
      page: 0,
      sortBy,
      search,
      sortDesc,
    });
    return results;
  }

  return await CompanyVatService.getAllByCompanyPaginated(companyId, { perPage, page, sortBy, search, sortDesc });
}

/**
 * getVat returns a VAT object given an ID.
 */
async function getVat(vatId) {
  try {
    return await CompanyVatService.getOne(vatId);
  } catch (err) {
    throw err?.response?.data?.detail ?? "Failed to get VAT";
  }
}

/**
 * createVat creates a VAT.
 */
async function createVat(vat) {
  try {
    return await CompanyVatService.create(vat);
  } catch (err) {
    if (err?.response?.status === 400) {
      throw err.response.data;
    }

    throw err?.response?.data?.detail ?? i18n.t("COMMON.SOMETHING_WENT_WRONG");
  }
}

/**
 * updateVat updates the given VAT.
 */
async function updateVat(vat) {
  try {
    return await CompanyVatService.update(vat, vat.id);
  } catch (err) {
    if (err?.response?.status === 400) {
      throw err.response.data;
    }

    throw err?.response?.data?.detail ?? i18n.t("COMMON.SOMETHING_WENT_WRONG");
  }
}

/**
 * deleteVat deletes a VAT given the ID.
 */
async function deleteVat(vatId) {
  try {
    return await CompanyVatService.delete(vatId);
  } catch (err) {
    throw err?.response?.data?.detail ?? i18n.t("COMMON.SOMETHING_WENT_WRONG");
  }
}

export { createVat, updateVat, getVats, getVat, deleteVat };
