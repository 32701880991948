var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "form row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('Country'),
      "vid": "country",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('euro-select', {
          attrs: {
            "options": _vm.countriesSelect,
            "label": "".concat(_vm.$t('Country'), "*"),
            "disabled": _vm.isEdit,
            "error-messages": errors,
            "searchable": ""
          },
          model: {
            value: _vm.form.country,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "country", $$v);
            },
            expression: "form.country"
          }
        })];
      }
    }])
  })], 1), _c('div', {
    staticClass: "col-7"
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('Fiscal ID'),
      "vid": "fiscal_ID",
      "rules": _vm.rules.fiscalcode
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('euro-input', {
          attrs: {
            "label": "".concat(_vm.$t('Fiscal ID'), "*"),
            "error-messages": errors
          },
          model: {
            value: _vm.form.fiscal_ID,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "fiscal_ID", $$v);
            },
            expression: "form.fiscal_ID"
          }
        })];
      }
    }])
  })], 1), _c('div', {
    staticClass: "col-5"
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('Expire date'),
      "vid": "valid_to"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var errors = _ref3.errors;
        return [_c('date-picker', {
          attrs: {
            "overrides": _vm.toDateOverrides,
            "error-messages": errors,
            "placeholder": "YYYY-MM-DD",
            "label": "".concat(_vm.$t('Expire date'))
          },
          model: {
            value: _vm.form.valid_to,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "valid_to", $$v);
            },
            expression: "form.valid_to"
          }
        })];
      }
    }])
  })], 1), _c('div', {
    staticClass: "col-12"
  }, [_c('validation-provider', {
    staticClass: "w-100",
    attrs: {
      "name": _vm.$t('Attachment')
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var _vm$form, _vm$form$fiscal_ID, _vm$form2, _vm$form2$fiscal_ID;
        var errors = _ref4.errors;
        return [_c('div', {
          attrs: {
            "id": "attachmentFile"
          }
        }, [_c('euro-tus-file-input', {
          attrs: {
            "label": _vm.attachmentFrontLabel,
            "error-messages": errors,
            "accept": "application/pdf",
            "attachment": _vm.existingForm.attachment,
            "disabled": !((_vm$form = _vm.form) !== null && _vm$form !== void 0 && (_vm$form$fiscal_ID = _vm$form.fiscal_ID) !== null && _vm$form$fiscal_ID !== void 0 && _vm$form$fiscal_ID.length)
          },
          on: {
            "change": function change(tusId) {
              _vm.form.attachment_tus = tusId;
            }
          }
        }), !((_vm$form2 = _vm.form) !== null && _vm$form2 !== void 0 && (_vm$form2$fiscal_ID = _vm$form2.fiscal_ID) !== null && _vm$form2$fiscal_ID !== void 0 && _vm$form2$fiscal_ID.length) ? _c('b-tooltip', {
          attrs: {
            "target": "attachmentFile"
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("Enter fiscal ID first")) + " ")]) : _vm._e()], 1)];
      }
    }])
  })], 1), _c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "row m-1 my-3"
  }, [_c('span', {
    staticClass: "col- switch"
  }, [_c('label', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.attachmentBack,
      expression: "attachmentBack"
    }],
    attrs: {
      "type": "checkbox",
      "name": "select"
    },
    domProps: {
      "checked": Array.isArray(_vm.attachmentBack) ? _vm._i(_vm.attachmentBack, null) > -1 : _vm.attachmentBack
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.attachmentBack,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && (_vm.attachmentBack = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.attachmentBack = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.attachmentBack = $$c;
        }
      }
    }
  }), _c('span')])]), _c('label', {
    staticClass: "col- col-form-label"
  }, [_vm._v(_vm._s(_vm.$t('Add attachment back')))]), _c('span', {
    attrs: {
      "data-toggle": "tooltip",
      "title": _vm.$t('Switch on for uploading front and back of the document separately. You can also combine front/back in a single file in the attachment field.')
    }
  }, [_c('i', {
    staticClass: "fa fa-sm fa-info-circle text-primary"
  })])])]), _vm.attachmentBack ? _c('div', {
    staticClass: "col-12"
  }, [_c('validation-provider', {
    staticClass: "w-100",
    attrs: {
      "name": _vm.$t('Attachment'),
      "vid": "attachment_back_tus"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var _vm$form3, _vm$form3$fiscal_ID, _vm$form4, _vm$form4$fiscal_ID;
        var errors = _ref5.errors;
        return [_c('div', {
          attrs: {
            "id": "attachmentFileBack"
          }
        }, [_c('euro-tus-file-input', {
          attrs: {
            "label": _vm.$t('Attachment back'),
            "error-messages": errors,
            "accept": "application/pdf",
            "disabled": !((_vm$form3 = _vm.form) !== null && _vm$form3 !== void 0 && (_vm$form3$fiscal_ID = _vm$form3.fiscal_ID) !== null && _vm$form3$fiscal_ID !== void 0 && _vm$form3$fiscal_ID.length),
            "hint": _vm.$t('Accept .pdf - max size 20MB'),
            "attachment": _vm.existingForm.attachment_back
          },
          on: {
            "change": function change(tusId) {
              _vm.form.attachment_back_tus = tusId;
            }
          }
        }), !((_vm$form4 = _vm.form) !== null && _vm$form4 !== void 0 && (_vm$form4$fiscal_ID = _vm$form4.fiscal_ID) !== null && _vm$form4$fiscal_ID !== void 0 && _vm$form4$fiscal_ID.length) ? _c('b-tooltip', {
          attrs: {
            "target": "attachmentFileBack"
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("Enter the above required data first")) + " ")]) : _vm._e()], 1)];
      }
    }], null, false, 2013416842)
  })], 1) : _vm._e(), _vm.form.country == 'IT' ? _c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "separator separator-dashed my-8"
  })]) : _vm._e(), _vm.form.country == 'IT' ? _c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "row",
    class: {
      'form-group': _vm.form.to_generate
    }
  }, [_c('label', {
    staticClass: "col-8 col-form-label"
  }, [_vm._v("Do you want to generate the fiscal code?")]), _c('div', {
    staticClass: "col-4 col-form-label"
  }, [_c('div', {
    staticClass: "checkbox-inline"
  }, [_c('label', {
    staticClass: "checkbox checkbox-primary"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.to_generate,
      expression: "form.to_generate"
    }],
    attrs: {
      "type": "checkbox",
      "name": "Checkboxes11"
    },
    domProps: {
      "checked": Array.isArray(_vm.form.to_generate) ? _vm._i(_vm.form.to_generate, null) > -1 : _vm.form.to_generate
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.form.to_generate,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.form, "to_generate", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.form, "to_generate", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.form, "to_generate", $$c);
        }
      }
    }
  }), _c('span')])])])])]) : _vm._e(), _vm.form.country == 'IT' && _vm.form.to_generate ? [_c('div', {
    staticClass: "col-6"
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('First name'),
      "vid": "name",
      "rules": "required|max:30",
      "tag": "div"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var errors = _ref6.errors;
        return [_c('euro-input', {
          attrs: {
            "label": "".concat(_vm.$t('First name'), "*"),
            "disabled": !_vm.form_person.name,
            "error-messages": errors
          },
          model: {
            value: _vm.form_person.first_name,
            callback: function callback($$v) {
              _vm.$set(_vm.form_person, "first_name", $$v);
            },
            expression: "form_person.first_name"
          }
        })];
      }
    }], null, false, 7519184)
  })], 1), _c('div', {
    staticClass: "col-6"
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('Last name'),
      "vid": "surname",
      "rules": "required|max:30",
      "tag": "div"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var errors = _ref7.errors;
        return [_c('euro-input', {
          attrs: {
            "label": "".concat(_vm.$t('Last name'), "*"),
            "disabled": !_vm.form_person.surname,
            "error-messages": errors
          },
          model: {
            value: _vm.form_person.last_name,
            callback: function callback($$v) {
              _vm.$set(_vm.form_person, "last_name", $$v);
            },
            expression: "form_person.last_name"
          }
        })];
      }
    }], null, false, 1775802756)
  })], 1), _c('div', {
    staticClass: "col-6"
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('Date of birth'),
      "vid": "date_birth",
      "rules": "required",
      "tag": "div"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref8) {
        var errors = _ref8.errors;
        return [_c('date-picker', {
          attrs: {
            "overrides": _vm.fromDateOverrides,
            "error-messages": errors,
            "label": "".concat(_vm.$t('Date of birth'), "*")
          },
          model: {
            value: _vm.form_person.birth_date,
            callback: function callback($$v) {
              _vm.$set(_vm.form_person, "birth_date", $$v);
            },
            expression: "form_person.birth_date"
          }
        })];
      }
    }], null, false, 536227830)
  })], 1), _c('div', {
    staticClass: "col-6"
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('Gender'),
      "vid": "gender",
      "rules": "required",
      "tag": "div"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref9) {
        var errors = _ref9.errors;
        return [_c('euro-radio', {
          attrs: {
            "options": _vm.GENDERS,
            "label": "".concat(_vm.$t('Gender'), "*"),
            "error-messages": errors,
            "size": "lg"
          },
          model: {
            value: _vm.form_person.sex,
            callback: function callback($$v) {
              _vm.$set(_vm.form_person, "sex", $$v);
            },
            expression: "form_person.sex"
          }
        })];
      }
    }], null, false, 4050805049)
  })], 1), _c('div', {
    staticClass: "col-12"
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('Place of birth'),
      "vid": "place_birth",
      "rules": "required",
      "tag": "div"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref10) {
        var errors = _ref10.errors;
        return [_c('euro-place-input', {
          attrs: {
            "label": "".concat(_vm.$t('Place of birth'), "*"),
            "error-messages": errors
          },
          model: {
            value: _vm.form_person.birth_place,
            callback: function callback($$v) {
              _vm.$set(_vm.form_person, "birth_place", $$v);
            },
            expression: "form_person.birth_place"
          }
        })];
      }
    }], null, false, 1588855741)
  })], 1), _c('div', {
    staticClass: "col-12"
  }, [_c('b-button', {
    staticClass: "btn font-weight-bold w-100",
    attrs: {
      "disabled": !_vm.form_person.birth_place && !_vm.form_person.gender && !_vm.form_person.birth_date && !_vm.form_person.surname && !_vm.form_person.name,
      "variant": "light-primary"
    },
    on: {
      "click": _vm.onGenerateFiscalIdClick
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('Generate fiscal ID')) + " ")])], 1)] : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }