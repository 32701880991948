var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('validation-observer', {
    ref: "validationObserver",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var handleSubmit = _ref.handleSubmit;
        return [_c('b-modal', {
          attrs: {
            "id": "create-identify-modal",
            "hide-footer": ""
          },
          on: {
            "hidden": _vm.onModalHidden
          },
          scopedSlots: _vm._u([{
            key: "modal-header",
            fn: function fn() {
              return [_c('div', {
                staticClass: "w-100 h-100 d-flex justify-content-between align-items-center"
              }, [_vm.isEdit ? [_c('h4', {
                staticClass: "mb-0"
              }, [_vm._v(_vm._s(_vm.$t("Update ID document")))])] : [_c('h4', {
                staticClass: "mb-0"
              }, [_vm._v(_vm._s(_vm.$t("Create ID document")))])], _c('div', {
                staticClass: "d-flex align-items-center"
              }, [_c('b-button', {
                staticClass: "mr-2",
                class: {
                  'spinner spinner-light spinner-right': _vm.isSaving
                },
                attrs: {
                  "variant": "success"
                },
                on: {
                  "click": function click($event) {
                    return handleSubmit(_vm.submit);
                  }
                }
              }, [_vm._v(" " + _vm._s(_vm.$t("Save")) + " ")]), _c('b-button', {
                attrs: {
                  "variant": "secondary"
                },
                on: {
                  "click": _vm.closeModal
                }
              }, [_vm._v(" " + _vm._s(_vm.$t("Cancel")) + " ")])], 1)], 2)];
            },
            proxy: true
          }], null, true)
        }, [_c('physical-person-identity-form', {
          attrs: {
            "countries": _vm.countries,
            "languages": _vm.languages,
            "existing-form": _vm.existingForm
          },
          on: {
            "input": _vm.onIdentityFormInput
          }
        })], 1)];
      }
    }])
  });

}
var staticRenderFns = []

export { render, staticRenderFns }