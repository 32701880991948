var render = function render(){
  var _vm$physicalPerson, _vm$physicalPerson2, _vm$physicalPerson3, _vm$physicalPerson3$p;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "form row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('Country'),
      "vid": "country",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('euro-select', {
          attrs: {
            "searchable": "",
            "options": _vm.countriesSelects,
            "label": "".concat(_vm.$t('Country'), "*"),
            "error-messages": errors
          },
          model: {
            value: _vm.form.country,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "country", $$v);
            },
            expression: "form.country"
          }
        })];
      }
    }])
  })], 1), _c('div', {
    staticClass: "col-12"
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('Language'),
      "vid": "doc_language"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('euro-select', {
          attrs: {
            "label": _vm.$t('Language'),
            "options": _vm.languageSelect,
            "error-messages": errors,
            "searchable": ""
          },
          model: {
            value: _vm.form.doc_language,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "doc_language", $$v);
            },
            expression: "form.doc_language"
          }
        })];
      }
    }])
  })], 1), _c('div', {
    staticClass: "col-12 col-lg-6"
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('Passport number'),
      "vid": "number",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var errors = _ref3.errors;
        return [_c('euro-input', {
          attrs: {
            "label": "".concat(_vm.$t('Passport number'), "*"),
            "error-messages": errors
          },
          model: {
            value: _vm.form.number,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "number", $$v);
            },
            expression: "form.number"
          }
        })];
      }
    }])
  })], 1), _c('div', {
    staticClass: "col-12 col-lg-6"
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('Release date'),
      "vid": "date_of_issue",
      "rules": "required|before_now"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var errors = _ref4.errors;
        return [_c('date-picker', {
          attrs: {
            "error-messages": errors,
            "label": "".concat(_vm.$t('Release date'), "*"),
            "overrides": _vm.dateOfIssueOverrides
          },
          model: {
            value: _vm.form.date_of_issue,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "date_of_issue", $$v);
            },
            expression: "form.date_of_issue"
          }
        })];
      }
    }])
  })], 1), _c('div', {
    staticClass: "col-12 col-lg-6"
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('Valid from'),
      "vid": "valid_from",
      "rules": "required|after_if:@date_of_issue|before_if:@valid_to"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var errors = _ref5.errors;
        return [_c('date-picker', {
          attrs: {
            "error-messages": errors,
            "overrides": _vm.validFromOverrides,
            "label": "".concat(_vm.$t('Valid from'), "*")
          },
          model: {
            value: _vm.form.valid_from,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "valid_from", $$v);
            },
            expression: "form.valid_from"
          }
        })];
      }
    }])
  })], 1), _c('div', {
    staticClass: "col-12 col-lg-6"
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('Valid to'),
      "vid": "valid_to",
      "rules": "required|after_if:@valid_from}|after_if:@date_of_issue}"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var errors = _ref6.errors;
        return [_c('date-picker', {
          attrs: {
            "error-messages": errors,
            "overrides": _vm.validToOverrides,
            "label": "".concat(_vm.$t('Valid to'), "*")
          },
          model: {
            value: _vm.form.valid_to,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "valid_to", $$v);
            },
            expression: "form.valid_to"
          }
        })];
      }
    }])
  })], 1), _c('div', {
    staticClass: "col-12"
  }, [_c('validation-provider', {
    staticClass: "w-100",
    attrs: {
      "name": _vm.$t('Attachment'),
      "vid": "attachment_tus"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var _vm$form, _vm$form$number, _vm$form2, _vm$form2$number;
        var errors = _ref7.errors;
        return [_c('div', {
          attrs: {
            "id": "attachmentFile"
          }
        }, [_c('euro-tus-file-input', {
          attrs: {
            "label": "".concat(_vm.$t('Attachment'), "*"),
            "error-messages": errors,
            "accept": "application/pdf",
            "disabled": !((_vm$form = _vm.form) !== null && _vm$form !== void 0 && (_vm$form$number = _vm$form.number) !== null && _vm$form$number !== void 0 && _vm$form$number.length),
            "hint": _vm.$t('Accept .pdf - max size 20MB'),
            "attachment": _vm.existingForm.attachment
          },
          on: {
            "change": function change(tusId) {
              _vm.form.attachment_tus = tusId;
            }
          }
        }), !((_vm$form2 = _vm.form) !== null && _vm$form2 !== void 0 && (_vm$form2$number = _vm$form2.number) !== null && _vm$form2$number !== void 0 && _vm$form2$number.length) ? _c('b-tooltip', {
          attrs: {
            "target": "attachmentFile"
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("Enter passport number first")) + " ")]) : _vm._e()], 1)];
      }
    }])
  })], 1)]), _vm.physicalPerson ? [_c('div', {
    staticClass: "separator separator-dashed my-6"
  }), _c('div', {
    staticClass: "text-center font-weight-bold mb-5 pt-2"
  }, [_vm._v(" " + _vm._s(_vm.$t("Please make sure you are inserting the correct data for")) + ": ")]), _c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "card-body px-2 py-2"
  }, [_c('table', {
    staticClass: "table table-borderless mb-0"
  }, [_c('tbody', [_c('tr', [_c('td', [_vm._v(_vm._s(_vm.$t("Name")))]), _c('td', [_vm._v(_vm._s((_vm$physicalPerson = _vm.physicalPerson) === null || _vm$physicalPerson === void 0 ? void 0 : _vm$physicalPerson.name))])]), _c('tr', [_c('td', [_vm._v(_vm._s(_vm.$t("Surname")))]), _c('td', [_vm._v(_vm._s((_vm$physicalPerson2 = _vm.physicalPerson) === null || _vm$physicalPerson2 === void 0 ? void 0 : _vm$physicalPerson2.surname))])]), _c('tr', [_c('td', [_vm._v(_vm._s(_vm.$t("Date of birth")))]), _c('td', [_vm._v(_vm._s(_vm.physicalPerson.date_birth ? _vm.DateService.format(_vm.physicalPerson.date_birth, _vm.date_format) : ""))])]), _c('tr', [_c('td', [_vm._v(_vm._s(_vm.$t("Place of birth")))]), _c('td', [_vm._v(_vm._s((_vm$physicalPerson3 = _vm.physicalPerson) === null || _vm$physicalPerson3 === void 0 ? void 0 : (_vm$physicalPerson3$p = _vm$physicalPerson3.place_birth) === null || _vm$physicalPerson3$p === void 0 ? void 0 : _vm$physicalPerson3$p.formatted_address))])])])])])])] : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }