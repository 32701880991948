<template>
  <validation-observer ref="validationObserver" v-slot="{ handleSubmit }">
    <b-modal id="create-suitability-work" hide-footer @hidden="onModalHidden">
      <template #modal-header>
        <div class="w-100 h-100 d-flex justify-content-between align-items-center">
          <template v-if="isEdit">
            <h4 class="mb-0">{{ $t("Edit certificate of suitability for work") }}</h4>
          </template>
          <template v-else>
            <h4 class="mb-0">{{ $t("Create certificate of suitability for work") }}</h4>
          </template>
          <div class="d-flex align-items-center">
            <b-button variant="success" class="mr-2" :class="{ 'spinner spinner-light spinner-right': isSaving }"
              @click="handleSubmit(submit)">
              {{ $t("Save") }}
            </b-button>
            <b-button @click="closeModal">{{ $t("Cancel") }}</b-button>
          </div>
        </div>
      </template>

      <physical-person-suitability-work-form :physical-person="physicalPerson" :existing-form="existingForm"
        @input="$e => (item = $e)"></physical-person-suitability-work-form>
    </b-modal>
  </validation-observer>
</template>

<script>
import PhysicalPersonSuitabilityWorkForm from "../../forms/physical-person/PhysicalPersonSuitabilityWorkForm.vue";
import { backendErrorSwal } from "@/core/helpers/swal";
import { getValidationErrors, successToast } from "@/core/helpers";
import PhysicalPersonSuitabilityWorkService from "@/core/services/physical-person/physical-person-suitability-work.service";
import icons from "@/core/config/icons";

export default {
  components: {
    PhysicalPersonSuitabilityWorkForm,
  },

  props: {
    physicalPerson: {
      type: Object,
      required: true,
    },
    existingForm: {
      type: Object,
      default: () => true,
    },
  },

  data() {
    return {
      item: null,
      isSaving: false,
      apiErrors: null,
      icons,
    };
  },

  computed: {
    isEdit() {
      return Object.keys(this.existingForm).length > 0;
    },
  },

  watch: {
    apiErrors: {
      deep: true,
      handler(val) {
        const formObs = this.$refs.validationObserver;
        const newErrors = getValidationErrors(val, this.item);
        formObs.setErrors({ ...newErrors });
      },
    },
  },

  methods: {
    closeModal() {
      this.$bvModal.hide("create-suitability-work");
    },

    onModalHidden() {
      this.item = null;
      this.isSaving = false;
    },

    async submit() {
      this.isSaving = true;
      try {
        if (Object.keys(this.existingForm).length) {
          const form = { ...this.item };
          await PhysicalPersonSuitabilityWorkService.update(form, form.id);
        } else {
          await PhysicalPersonSuitabilityWorkService.create({ ...this.item, persona: this.physicalPerson.id });
        }
        this.$emit("refresh");
        this.closeModal();

        let title = this.$t("Document created");
        if (this.isEdit) {
          title = this.$t("Document Updated");
        }
        successToast(title);
      } catch (err) {
        if (err?.response?.status === 400) {
          this.apiErrors = err.response.data;
        } else {
          let message = err?.response?.data?.detail ?? this.$t("Failed to create the document. Please try again.");
          if (this.isEdit) {
            message = err?.response?.data?.detail ?? this.$t("Failed to update the document. Please try again.");
          }
          backendErrorSwal(message, err);
        }
      } finally {
        this.isSaving = false;
      }
    },
  },
};
</script>
