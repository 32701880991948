var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "form row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('validation-provider', {
    staticClass: "w-100",
    attrs: {
      "name": _vm.$t('File attachment'),
      "vid": "attachments_tus"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('div', {
          attrs: {
            "id": "attachmentFile"
          }
        }, [_c('euro-tus-file-input', {
          attrs: {
            "label": "".concat(_vm.$t('Attachment'), "*"),
            "error-messages": errors,
            "accept": "application/pdf",
            "multiple-attachment": _vm.existingForm.attachments,
            "hint": _vm.$t('Accept .pdf - max size 5MB'),
            "multiple": ""
          },
          on: {
            "remove-attachment": _vm.removeAttachment,
            "change": _vm.addTusAttachment,
            "remove": _vm.removeTusAttachment
          }
        })], 1)];
      }
    }])
  })], 1), _c('div', {
    staticClass: "col-12"
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('Period'),
      "vid": "date",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('date-picker', {
          attrs: {
            "overrides": _vm.periodOverride,
            "error-messages": errors,
            "placeholder-string": "YYYY-MM",
            "label": "".concat(_vm.$t('Period'), "*")
          },
          model: {
            value: _vm.form.date,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "date", $$v);
            },
            expression: "form.date"
          }
        })];
      }
    }])
  })], 1), _c('div', {
    staticClass: "col-12"
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('Description'),
      "vid": "description"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var errors = _ref3.errors;
        return [_c('euro-text-area', {
          attrs: {
            "label": "".concat(_vm.$t('Description')),
            "error-messages": errors
          },
          model: {
            value: _vm.form.description,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "description", $$v);
            },
            expression: "form.description"
          }
        })];
      }
    }])
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }