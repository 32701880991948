<template>
  <div class="form row">
    <div class="col-12">
      <validation-provider v-slot="{ errors }" :name="$t('Country')" vid="country" rules="required">
        <euro-select v-model="form.country" :label="`${$t('Country')}*`" :error-messages="errors"
          :options="countriesSelectable" searchable></euro-select>
      </validation-provider>
    </div>
    <div class="col-12">
      <validation-provider v-slot="{ errors }" :name="$t('Insurance Company')" vid="insurance_company" rules="required">
        <euro-select v-model="form.insurance_company" :label="`${$t('Counterpart')}*`" :error-messages="errors"
          :multiselect-props="{
        options: counterpartsByCountry,
        label: 'name',
        'track-by': 'id',
        multiple: false,
        searchable: true,
      }">
        </euro-select>
      </validation-provider>
    </div>

    <div class="col-12">
      <validation-provider v-slot="{ errors }" :name="$t('Insurance notes')" vid="insurance">
        <euro-input v-model="form.insurance" :error-messages="errors" :label="$t('Insurance notes')"
          :hint="$t('Notes about your insurance policy')">
        </euro-input>
      </validation-provider>
    </div>

    <div class="col-12">
      <validation-provider v-slot="{ errors }" :name="$t('Insurance Number')" vid="number" rules="required">
        <euro-input v-model="form.number" :error-messages="errors" :label="`${$t('Insurance Number')}*`"
          :hint="$t('The identification number of your insurance policy')">
        </euro-input>
      </validation-provider>
    </div>

    <div class="col-6">
      <validation-provider v-slot="{ errors }" vid="from_date" :name="$t('From Date')" rules="required">
        <date-picker v-model="form.from_date" :error-messages="errors" :overrides="fromDateOverrides"
          :label="`${$t('From Date')}*`"></date-picker>
      </validation-provider>
    </div>

    <div class="col-6">
      <validation-provider v-slot="{ errors }" :name="$t('To Date')" vid="to_date" rules="required">
        <date-picker v-model="form.to_date" :error-messages="errors" :overrides="toDateOverrides"
          :label="`${$t('To Date')}*`"></date-picker>
      </validation-provider>
    </div>

    <div class="col-12">
      <validation-provider v-slot="{ errors }" :name="$t('Currency')" vid="insured_max_currency" rules="required">
        <euro-select v-model="form.insured_max_currency" :error-messages="errors" :label="`${$t('Currency')}*`"
          :options="currenciesSelectable" searchable></euro-select>
      </validation-provider>
    </div>

    <div class="col-12">
      <validation-provider v-slot="{ errors }" :name="$t('Insured Max')" vid="insured_max" rules="required">
        <euro-input v-model="form.insured_max" :error-messages="errors" :label="`${$t('Insured Max')}*`"
          type="number"></euro-input>
      </validation-provider>
    </div>

    <div class="col-12">
      <validation-provider v-slot="{ errors }" :name="$t('Attachment')" vid="attachment_tus" class="w-100">

        <div id="attachmentFile">
          <euro-tus-file-input :label="`${$t('Attachment')}*`" :error-messages="errors" vid="attachment_tus"
            accept="application/pdf" :disabled="!form?.insurance_company ||
        !form?.number ||
        !form?.from_date ||
        !form?.insured_max_currency ||
        !form?.insured_max
        " :hint="$t('Accept .pdf - max size 20MB')" :attachment="existingForm.attachment"
            @change="onAttachmentChange"></euro-tus-file-input>
          <b-tooltip v-if="!form?.insurance_company ||
        !form?.number ||
        !form?.from_date ||
        !form?.insured_max_currency ||
        !form?.insured_max
        " target="attachmentFile">
            {{ $t("Enter the above required data first") }}
          </b-tooltip>
        </div>
      </validation-provider>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import CompanyService from "@/core/services/company/company.service";
import NationCurrenciesService from "@/core/services/nation/nation-currencies.service";
import { countryChangeSwal } from "@/core/helpers/swal";
import { getCounterparts } from "@/core/abstractions/company";
import datePicker from "@/view/components/datePicker/datePicker.vue";
import icons from "@/core/config/icons";

export default {
  components: {
    datePicker,
  },
  props: {
    existingForm: {
      type: Object,
      default: null,
    },
    countries: {
      type: Array,
      required: true,
    },
    defaultCountry: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      icons,
      form: {
        country: "",
        insurance_company: "",
        insurance: "",
        number: "",
        from_date: "",
        to_date: "",
        attachment_tus: null,
        insured_max: "",
        insured_max_currency: "",
      },
      companiesTwo: [],
      currencies: [],
      counterparts: [],
    };
  },

  computed: {
    countriesSelectable() {
      return this.countries
        .map((x) => ({ text: this.$t(x.name), value: x.iso }))
        .sort((a, b) => a.text.localeCompare(b.text));
    },

    // Counterparts

    companiesSelectable() {
      return this.companiesTwo.map(el => ({
        value: el.id,
        text: `${el.name} - ${el.country_of_establishment}`,
      }));
    },

    companyAddresses() {
      if (!this.form.company) return [];

      const company = this.companiesTwo.find(x => x.id == this.form.company);

      return company.companyaddress_set.map(el => ({
        value: el.id,
        text: el.address.formatted,
      }));
    },

    currenciesSelectable() {
      return this.currencies.map(el => ({ value: el.code, text: el.name }));
    },

    fromDateOverrides() {
      return this.form.from_date ? { maxDate: moment() } : null;
    },

    toDateOverrides() {
      return this.form.from_date && this.form.from_date !== "Invalid date" ? { minDate: this.form.from_date } : null;
    },

    counterpartsByCountry() {
      if (!this.form.country) {
        return this.counterparts;
      }

      return this.counterparts.filter(el => el.country_of_establishment === this.form.country);
    },
  },

  watch: {
    form: {
      deep: true,
      handler(val) {
        this.$emit("input", val);
      },
    },

    "form.company_nation"(val) {
      if (!val) return;

      this.loadCompaniesByCountry();
    },

    'form.country': {
      handler(newVal, oldVal) {
        //control the swal for dont do loop
        if (this.isChangingCountry) {
          this.isChangingCountry = false;
          return;
        }
        if (newVal !== oldVal && this.form.country !== this.existingForm.country && newVal !== null) {
          countryChangeSwal({
            text: this.$t("You are about to change the country. Are you sure?"),
          }).then(res => {
            if (!res.isConfirmed) {
              this.isChangingCountry = true;
              this.form.country = oldVal;
            }
          });
        }
      },
      inmediate: true
    },
  },

  mounted() {
    if (this.existingForm) {
      this.form = { ...this.form, ...this.existingForm };
    }

    this.loadCurrencies();
    this.loadCounterparts();
  },

  methods: {
    onAttachmentChange(f) {
      this.form.attachment_tus = f;
    },

    async loadCompaniesByCountry() {
      this.form.company = "";
      this.form.company_address = null;
      const c = await CompanyService.getAllNotPaginatedFilterable({ country: this.form.company_nation });
      this.companiesTwo = c.results;
    },

    async loadCurrencies() {
      const res = await NationCurrenciesService.getAll();
      this.currencies = res.results;
    },

    async loadCounterparts() {
      this.counterparts = await getCounterparts({ isAdministrativeStructure: false, fields: "id,name" });
    },

    onCounterpartCreated(counterpart) {
      this.loadCounterparts();
      this.form.insurance_company = counterpart.id;
    },
  },
};
</script>
