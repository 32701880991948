<template>
  <validation-observer ref="validationObserver" v-slot="{ handleSubmit }">
    <b-modal id="associate-contract-modal" hide-footer size="lg" @shown="loadContract">
      <template #modal-header>
        <div class="w-100 h-100 d-flex justify-content-between align-items-center">
          <div>
            <h4 class="mb-0">{{ $t("Edit Associate Contract") }}</h4>
          </div>
          <div class="d-flex align-items-center">
            <b-button variant="success" class="mr-2" :class="{ 'spinner spinner-light spinner-right': isSaving }"
              :disabled="oldVersion" @click="handleSubmit(submit)">
              {{ $t("Save") }}
            </b-button>
            <b-button @click="closeModal">{{ $t('Cancel') }}</b-button>
          </div>
        </div>
      </template>
      <b-overlay :show="isLoading">
        <div v-if="currentContract">
          <div class="form row">
            <div v-if="showVersions" class="col-2">
              <div class="mt-3 font-weight-bold">{{ $t("History") }}</div>
            </div>
            <div v-if="showVersions" class="col-10">
              <euro-select v-model="version" class-name="mb-0" :options="versions"></euro-select>
            </div>
            <div v-if="showVersions" class="col-12">
              <div class="separator separator-dashed my-5"></div>
            </div>
          </div>
          <div class="form row">
            <div class="col-12">
              <h5 class="mb-6">{{ currentContract.national_contract.name }}</h5>
            </div>
          </div>
          <div v-if="!oldVersion">
            <template>
              <div class="form row">
                <div class="col-md-12"> 
                  <validation-provider v-slot="{ errors }" :name="$t('Level')" vid="national_contract_level" rules="required">
                    <div class="d-flex align-items-center" style="min-height: 50px;">
                      <div style="flex-grow: 1; max-width: 50%; padding-right: 20px;">
                        <label>{{ $t('Level') }}</label>
                        <euro-select v-model="form.national_contract_level"
                          :error-messages="errors.concat(errorMessages['national_contract_level'] || [])"
                          :options="nationalContractLevelsSelectable" searchable :disabled="oldVersion"></euro-select>
                      </div>
                      <div style="flex-basis: 50%; display: flex; justify-content: flex-start; padding-left: 20px;">
                        <euro-checkbox v-model="form.isIndeterminate" :text="$t('Indeterminate')" is-switch size="lg"
                          :disabled="oldVersion" @change="handleIndeterminateChange"></euro-checkbox>
                      </div>
                    </div>
                  </validation-provider>
                  </div>
                </div>
              <div class="form row">
                <div class="col-md-6">
                  <validation-provider v-slot="{ errors }" vid="start_date" :name="$t('Employement start date')" :rules="`required`">
                    <date-picker v-model="form.start_date" :label="`${$t('Start date')}*`"
                      :overrides="{ useCurrent: false }" :error-messages="errors.concat(errorMessages['start_date'] || [])"
                      disabled></date-picker>
                  </validation-provider>
                </div>
                <div class="col-md-6">
                  <validation-provider v-slot="{ errors }" :name="$t('Termination date')" :vid="`end_date`"
                    rules="after:@start_date">
                    <date-picker v-model="form.end_date"
                      :error-messages="errors.concat(errorMessages['end_date'] || [])" :overrides="endDateOverrides"
                      :disabled="form.isIndeterminate || oldVersion" :label="`${$t('Termination date')}`"></date-picker>
                  </validation-provider>
                </div>
              </div>
            </template>
              <div class="col-12">
                <validation-provider v-slot="{ errors }" :name="$t('Attachment')" vid="attachment_tus">
                  <euro-tus-file-input :label="$t('Attachment')"
                    :error-messages="errors.concat(errorMessages['attachment'] || [])" accept="application/pdf"
                    :hint="$t('Accept .pdf - max size 20MB')" :disabled="oldVersion" @change="tusId => {
                      form.attachment_tus = tusId;
                    }
                      "></euro-tus-file-input>
                </validation-provider>
              </div>
              <div class="col-12">
                <validation-provider v-slot="{ errors }" :name="$t('Notes')" vid="name" rules="max:200">
                  <euro-text-area v-model="form.description" :label="$t('Notes')" :disabled="oldVersion"
                    :error-messages="errors.concat(errorMessages['description'] || [])" :rows="2"></euro-text-area>
                </validation-provider>
              </div>
              <div class="col-12">
                <div class="separator separator-dashed mb-10 mt-4"></div>
              </div>
              <div class="col-12">
                <div class="d-flex justify-content-between align-items-center">
                  <div>
                    <h4 class="mb-6 font-weight-bold text-dark">{{ $t("Select Renewal Dates") }}</h4>
                  </div>
                  <div v-if="!oldVersion && !disablePpCreation">
                    <b-button variant="light-primary btn-sm" @click="addRenewalDate">
                      <span class="svg-icon menu-icon">
                        <inline-svg src="/media/svg/icons/Code/Plus.svg" />
                      </span>
                      {{ $t("Renewal date") }}
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
            <div v-for="(renewal, renewalIdx) in form.renewals" :key="`${renewalIdx}-ren`">
              <div v-if="renewalIdx > 0">
                <div class="separator separator-dashed my-5"></div>
              </div>
              <div class="d-flex">
                <div class="flex-grow-1">
                  <validation-provider v-slot="{ errors }" :name="$t('Employement start date')"
                    :vid="`date_valid_from[${renewalIdx}]`">
                    <date-picker v-model="renewal.renewal_date" :label="$t('Employement start date')"
                      :error-messages="errors.concat(errorMessages['renewal_date'] || [])"
                      :overrides="renewalDateOverrides" :disabled="oldVersion"></date-picker>
                  </validation-provider>
                </div>
                <div class="flex-grow-1 px-6">
                  <validation-provider v-slot="{ errors }" :name="$t('Termination date')"
                    :vid="`date_valid_to[${renewalIdx}]`" :rules="{ after: renewal.renewal_date }">
                    <date-picker v-model="renewal.end_date" :label="$t('Termination date')"
                      :overrides="{ useCurrent: false }" :error-messages="errors.concat(errorMessages['end_date'] || [])"
                      :disabled="oldVersion"></date-picker>
                  </validation-provider>
                </div>
                <div v-if="!oldVersion" class="flex-grow-0">
                  <div class="form-row">
                    <label class="col-12 col-form-label">&nbsp;</label>
                    <b-btn variant="light" :disabled="form.renewals.length == 1" @click="removeRenewalDate(renewalIdx)">
                      <span class="svg-icon mr-0">
                        <inline-svg src="/media/svg/icons/Code/Minus.svg" />
                      </span>
                    </b-btn>
                  </div>
                </div>
              </div>
            </div>
            <div class="form row">
              <div class="col-12">
                <div class="separator separator-dashed mb-10 mt-4"></div>
              </div>
              <div class="col-12">
                <div class="d-flex justify-content-between align-items-center">
                  <div>
                    <h4 class="mb-6 font-weight-bold text-dark">{{ $t("Set work location") }}</h4>
                  </div>
                  <div v-if="!oldVersion">
                    <b-button variant="light-primary btn-sm" block @click="addWorkLocation">
                      <span class="svg-icon">
                        <inline-svg src="/media/svg/icons/Code/Plus.svg" />
                      </span>
                      {{ $t("Work location") }}
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
            <div v-for="(location, locationIdx) in form.work_locations" :key="`${locationIdx}-loc`" class="from row">
              <div v-if="locationIdx > 0" class="col-12">
                <div class="separator separator-dashed my-5"></div>
              </div>
              <div class="col-12">
                <div v-if="form.work_locations.length > 1" class="d-flex align-items-center">
                  <h6 class="mb-0 font-weight-bold text-dark">{{ $t("Location") }}</h6>
                  <b-button v-if="!oldVersion" variant="light-danger btn-sm ml-6"
                    :disabled="form.work_locations.length < 2" @click="removeWorkLocation(locationIdx)">
                    <span class="svg-icon">
                      <inline-svg src="/media/svg/icons/Code/Minus.svg" />
                    </span>
                    {{ $t("Remove") }}
                  </b-button>
                </div>
              </div>
              <div class="col-12">
                <div class="row">
                  <div class="col-9">
                    <validation-provider v-slot="{ errors }" :name="$t('Work location')"
                      :vid="`work_location[${locationIdx}]`" rules="required">
                      <euro-select v-model="location.work_location" :label="`${$t('Work location')}*`"
                        :error-messages="errors.concat(errorMessages['work_location'] || [])"
                        :options="companyAddressesSelectable" searchable :disabled="oldVersion">
                      </euro-select>
                    </validation-provider>
                  </div>
                  <div class="col-3 pt-14">
                    <euro-checkbox v-model="location.remote_work" :text="$t('Remote work')" is-switch size="lg"
                      :disabled="oldVersion"></euro-checkbox>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="row">
                  <div class="col-4">
                    <validation-provider v-slot="{ errors }" :name="$t('Valid from')" :vid="`valid_from[${locationIdx}]`">
                      <date-picker v-model="location.valid_from" :label="$t('Valid from')"
                        :disabled="location.is_permanent"
                        :error-messages="errors.concat(errorMessages['valid_from'] || [])"
                        :overrides="locationValidFromDateOverrides"></date-picker>
                    </validation-provider>
                  </div>
                  <div class="col-4">
                    <validation-provider v-slot="{ errors }" :name="$t('Valid to')" :vid="`valid_to[${locationIdx}]`"
                      :rules="`after:@valid_from[${locationIdx}]`">
                      <date-picker v-model="location.valid_to" :disabled="location.is_permanent" :label="$t('Valid to')"
                        :overrides="{ useCurrent: false }"
                        :error-messages="errors.concat(errorMessages['valid_to'] || [])"></date-picker>
                    </validation-provider>
                  </div>
                  <div class="col-4 pt-11">
                    <euro-checkbox v-model="location.is_permanent" :text="$t('Permanent')" is-switch size="lg"
                      class="mt-2" :disabled="oldVersion" @change="onLocationPermanentChange(location)"></euro-checkbox>
                  </div>
                </div>
              </div>
            </div>
            <div class="form row">
              <div class="col-12">
                <div class="separator separator-dashed mb-10 mt-4"></div>
              </div>
              <div class="col-12">
                <h4 class="mb-6 font-weight-bold text-dark">{{ $t("Salary conditions") }}</h4>
              </div>
              <div class="col-4">
                <validation-provider v-slot="{ errors }" :name="$t('Monthly superminimum')" vid="monthly_superminimum"
                  :rules="{ required: true, min_value: 0 }">
                  <euro-input v-model="form.monthly_superminimum" type="number" step="any"
                    :label="`${$t('Monthly superminimum')} (${form.currency})`"
                    :error-messages="errors.concat(errorMessages['monthly_superminimum'] || [])"
                    :disabled="oldVersion"></euro-input>
                </validation-provider>
              </div>
              <div class="col-4">
                <validation-provider v-slot="{ errors }" :name="$t('Advanced seniority pay')" vid="advanced_seniority_pay"
                  :rules="{ required: true, min_value: 0 }">
                  <euro-input v-model="form.advanced_seniority_pay" type="number" step="any"
                    :label="`${$t('Advanced seniority pay')} (${form.currency})`"
                    :error-messages="errors.concat(errorMessages['advanced_seniority_pay'] || [])"
                    :disabled="oldVersion"></euro-input>
                </validation-provider>
              </div>
              <div class="col-12">
                <div class="separator separator-dashed mt-5 mb-10"></div>
              </div>
              <div class="col-12">
                <h5 class="mb-6 font-weight-bold text-dark">{{ $t("Travel and posting abroad reimbursement") }}</h5>
                <p class="text-muted">
                  {{ $t("Is the employee entitled to reimbursement of expenses when travelling abroad?") }}<br />
                </p>
              </div>
              <div class="col-12">
                <validation-provider v-slot="{ errors }" :name="$t('Reimbursement')" vid="reimbursment">
                  <euro-radio v-model="form.reimbursment"
                    :error-messages="errors.concat(errorMessages['reimbursment'] || [])" :options="reimbursmentSelectable"
                    :disabled="oldVersion"
                    :class="{ 'mb-0': form.reimbursment == 'FULL' || form.reimbursment == 'PARTIAL' }"></euro-radio>
                </validation-provider>
              </div>
              <div v-if="form.reimbursment == 'FULL'" class="col-4">
                <validation-provider v-slot="{ errors }" :name="$t('Daily compensation')" vid="daily_posting_compensation"
                  :rules="{ required: true, min_value: 0 }">
                  <euro-input v-model="form.daily_posting_compensation" type="number"
                    :label="`${$t('Daily compensation')} (${form.currency})`" step="any"
                    :error-messages="errors.concat(errorMessages['daily_posting_compensation'] || [])"
                    :disabled="oldVersion"></euro-input>
                </validation-provider>
              </div>
              <div v-if="form.reimbursment == 'PARTIAL'" class="col-4">
                <validation-provider v-slot="{ errors }" :name="$t('Travel compensation amount')"
                  vid="travel_compensation_amount" :rules="{ required: true, min_value: 0 }">
                  <euro-input v-model="form.travel_compensation_amount"
                    :disabled="form.reimbursment === 'NOT' || oldVersion" class="mr-5"
                    :label="`${$t('Amount limit')} (${form.currency})`" type="number" step="any"
                    :error-messages="errors.concat(errorMessages['travel_compensation_amount'] || [])"></euro-input>
                </validation-provider>
              </div>
              <div class="col-12">
                <div class="separator separator-dashed mt-0 mb-10"></div>
              </div>
              <div class="col-12 mb-4">
                <div class="d-flex align-items-center justify-content-between">
                  <div>
                    <h4 class="font-weight-bold text-dark">{{ $t("Bonus") }}</h4>
                    <p class="text-muted">{{ $t("An extra payment that can be awarded occasionally.") }}</p>
                  </div>
                  <div v-if="!oldVersion">
                    <b-btn variant="light-primary btn-sm ml-4" @click="addBonus">
                      <span class="svg-icon">
                        <inline-svg src="/media/svg/icons/Code/Plus.svg" />
                      </span>
                      {{ $t("Add Bonus") }}
                    </b-btn>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div v-for="(bonus, bonusIdx) in form.bonuses" :key="bonusIdx">
                  <div v-if="bonusIdx > 0" class="w-100">
                    <div class="separator separator-dashed my-2"></div>
                  </div>
                  <div class="d-flex">
                    <div class="flex-grow-1">
                      <validation-provider v-slot="{ errors }" :name="$t('Bonus')" :vid="`bonus[${bonusIdx}]`"
                        rules="required|min_value:0">
                        <euro-input v-model="bonus.bonus" type="number" step="any"
                          :label="`${$t('Amount')} (${form.currency})`"
                          :error-messages="errors.concat(errorMessages['bonus'] || [])"
                          :disabled="oldVersion"></euro-input>
                      </validation-provider>
                    </div>
                    <div class="flex-grow-1 px-4">
                      <validation-provider v-slot="{ errors }" :name="$t('Valid from')"
                        :vid="`bonus_valid_from[${bonusIdx}]`" :rules="{ required: bonus.bonus }">
                        <date-picker v-model="bonus.bonus_valid_from" :label="$t('Valid from')"
                          :error-messages="errors.concat(errorMessages['bonus_valid_from'] || [])"
                          :overrides="bonusValidFromDateOverrides" :disabled="oldVersion"></date-picker>
                      </validation-provider>
                    </div>
                    <div class="flex-grow-1 pr-4">
                      <validation-provider v-slot="{ errors }" :name="$t('Valid to')" :vid="`bonus_valid_to[${bonusIdx}]`"
                        :rules="`after:@bonus_valid_from[${bonusIdx}]`">
                        <date-picker v-model="bonus.bonus_valid_to" :label="$t('Valid to')"
                          :overrides="{ useCurrent: false }"
                          :error-messages="errors.concat(errorMessages['bonus_valid_to'] || [])"
                          :disabled="oldVersion"></date-picker>
                      </validation-provider>
                    </div>
                    <div class="flex-grow-0">
                      <div class="form-row">
                        <label class="col-12 col-form-label">&nbsp;</label>
                        <b-btn v-if="!oldVersion" variant="danger" class="d-flex align-items-center"
                          @click="removeBonus(bonusIdx)">
                          <span class="svg-icon mr-2">
                            <inline-svg src="/media/svg/icons/Code/Minus.svg" />
                          </span>
                          <span>{{ $t("Remove") }}</span>
                        </b-btn>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="font-weight-bold mb-2">{{ $t("Changes") }}</div>
            <div v-for="difference in versionChanges" :key="difference.field" class="alert alert-light mb-3">
              <div class="font-weight-bold">{{ texts[difference.field] ?? difference.field }}:</div>
              <div v-if="difference.field == 'national_contract_level'">
                {{ $t("Changed from") }} <span
                  class="label label-lg label-inline label-light-success font-weight-bolder mr-1">{{
                    nationalContractLevelsName(previousHistoryContract.national_contract_level) }}</span>
                {{ $t("to") }} <span class="label label-lg label-inline label-light-success font-weight-bolder">{{
                  nationalContractLevelsName(historyContract.national_contract_level) }}</span>
              </div>
              <div v-else-if="difference.field == 'travel_posting_reimbursement'">
                {{ $t("Reimbursement") }}:
                <span v-if="historyContract.travel_posting_reimbursement == FULL">{{ $t("Full reimbursement") }}</span>
                <span v-else-if="historyContract.travel_posting_reimbursement == PARTIAL">{{ $t("Partial reimbursement")
                }}</span>
                <span v-else-if="historyContract.travel_posting_reimbursement == NOT">{{ $t("No reimbursement") }}</span>
              </div>
              <div v-else-if="difference.field == 'employeecontractworklocation_set'">
                <div v-for="location in historyContract.work_locations" :key="location.id" class="mb-2">
                  {{ $t('Remote work') }}: {{ location.remote_work }}<br>
                  {{ $t('Permanent') }}: {{ location.is_permanent }}<br>
                  {{ $t('Valid from') }}: {{ DateService.format(location.valid_from, dateFormat) }}<br>
                  <div v-if="location.valid_to">{{ $t('Valid to') }}: {{ DateService.format(location.valid_to,
                    dateFormat) }}</div>
                </div>
              </div>
              <div v-else-if="difference.field == 'employeecontractbonus_set'">
                <div v-for="bonus in historyContract.bonuses" :key="bonus.id" class="mb-2">
                  {{ $t('Amount') }}: {{ bonus.bonus }} {{ form.currency }}<br>
                  {{ $t('Valid from') }}: {{ DateService.format(bonus.bonus_valid_from, dateFormat) }}<br>
                  <div v-if="bonus.bonus_valid_to">{{ $t('Valid to') }}: {{ DateService.format(bonus.bonus_valid_to,
                    dateFormat) }}</div>
                </div>
              </div>
              <div v-else>
                <span>{{ difference.value }}</span>
              </div>
            </div>
          </div>
      </b-overlay>
    </b-modal>
  </validation-observer>
</template>

<script>
import EmployeeNationalContractLevelService from "@/core/services/employee/employee-nationalcontract-level.service";
import CompanyAddressService from "@/core/services/company/company-address.service";
import DateService from "@/core/services/date.service";
import EmployeeContractService from "@/core/services/employee/employee-contract.service";
import Swal from "sweetalert2";
import moment from "moment";
import { mapState, mapGetters } from "vuex";
import { backendErrorSwal } from "@/core/helpers/swal";
import DatePicker from "@/view/components/datePicker/datePicker.vue";

export default {
  components: {
    DatePicker,
  },
  props: {
    existingForm: {
      type: Object,
      default: () => true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: true,
      disablePpCreation: false,
      DateService,
      versions: [],
      version: null,
      versionChanges: [],
      form: {
        daily_posting_compensation: 0,
        advanced_seniority_pay: 0,
        monthly_superminimum: 0,
        employee: null,
        employer: null,
        start_date: null,
        end_date: null,
        isIndeterminate: false,
        attachment: null,
        attachment_tus: null,
        name: null,
        national_contract: "",
        national_contract_level: "",
        language: "",
        currency: "",
        description: null,
        renewals: [
          {
            id: null,
            renewal_date: null,
            end_date: null,
          },
        ],
        work_locations: [
          {
            id: null,
            work_location: null,
            remote_work: false,
            valid_from: null,
            valid_to: null,
            is_permanent: true,
          },
        ],
        bonuses: [],
        reimbursment: "NOT",
        travel_compensation_amount: 0,
        no_travel_compensation: false,
      },
      nationalContractLevels: [],
      companyAddresses: [],
      reimbursmentSelectable: [
        { value: "FULL", text: this.$t("Full reimbursement") },
        { value: "PARTIAL", text: this.$t("Partial reimbursement") },
        { value: "NOT", text: this.$t("No reimbursement") },
      ],
      isSaving: false,
      currentContract: null,
      errorMessages: {},
      historyContract: null,
      previousHistoryContract: null,
      versionOptions: [
        { value: "currentVersion", text: this.$t("Current version") },
        { value: "previousVersion", text: this.$t("Previous history version") }
      ],
      texts: {
        national_contract_level: this.$t('Level'),
        start_date: this.$t('Employement start date'),
        end_date: this.$t('Termination date'),
        description: this.$t('Notes'),
        monthly_superminimum: this.$t('Monthly superminimum'),
        advanced_seniority_pay: this.$t('Advanced seniority pay'),
        employeecontractworklocation_set: this.$t('Work location'),
        employeecontractbonus_set: this.$t('Bonus'),
        travel_posting_reimbursement: this.$t('Travel and posting abroad reimbursement'),
        daily_posting_compensation: this.$t('Daily compensation'),
        travel_compensation_amount: this.$t('Travel compensation amount')
      },
      differences: []
    };
  },

  computed: {
    ...mapState("user", ["user"]),
    ...mapState("constants", ["countries"]),
    ...mapGetters("user", ["getCurrentManagedCompanyId", "dateFormat"]),

    nationalContractLevelsSelectable() {
      return this.nationalContractLevels.map(el => ({ value: el.id, text: el.level_name }));
    },

    companyAddressesSelectable() {
      return this.companyAddresses.map(el => ({
        value: el.address?.raw || el.address.formatted,
        text: el.address?.raw || el.address.formatted,
      }));
    },

    endDateOverrides() {
      const out = {
        useCurrent: false,
      };

      if (this.form?.start_date && this.form?.start_date != "Invalid date") {
        out.minDate = moment(this.form.start_date).toDate();
      }

      return out;
    },

    renewalDateOverrides() {
      const out = {
        useCurrent: false,
      };

      if (this.form?.end_date && this.form?.start_date != "Invalid date") {
        out.minDate = moment(this.form.start_date).toDate();
      }

      return out;
    },

    locationValidFromDateOverrides() {
      const out = {
        useCurrent: false,
      };

      if (this.form?.start_date && this.form?.start_date != "Invalid date") {
        out.minDate = moment(this.form.start_date).toDate();
      }

      return out;
    },

    bonusValidFromDateOverrides() {
      const out = {
        useCurrent: false,
      };

      if (this.form?.start_date && this.form?.start_date != "Invalid date") {
        out.minDate = moment(this.form.start_date).toDate();
      }

      return out;
    },

    showVersions() {
      return this.versions.length > 1
    },

    oldVersion() {
      return this.showVersions && this.version != null || this.readOnly
    },

    employeeContractRenewalSet() {
      return this.form.renewals.filter(el => el.end_date && el.renewal_date);
    },

    employeeContractWorkLocationSet() {
      return this.form.work_locations
        .filter(el => !!el.work_location)
        .map(el => {
          return {
            work_location: this.companyAddresses.find(
              ad => ad.address.formatted === el.work_location || ad.address.raw === el.work_location,
            )?.address.id,
            remote_work: el.remote_work,
            valid_from: el.is_permanent || !el.valid_from ? this.form.start_date : el.valid_from,
            valid_to: el.is_permanent || !el.valid_to ? null : el.valid_to,
            id: el.id || null,
          };
        });
    },

    employeeContractBonusSet() {
      return this.form.bonuses
        .filter(el => !!el.bonus)
        .map(el => {
          return {
            id: el.id || null,
            bonus: el.bonus || 0,
            bonus_valid_from: el.bonus_valid_from,
            bonus_valid_to: el.bonus_valid_to,
          };
        });
    },
  },

  watch: {
    updateItem: {
      immediate: true,
      handler(val) {
        if (val) {
          this.form = { ...this.updateItem };
        }
      },
    },
    version(val) {
      this.changeVersion(val)
    }
  },
  methods: {
   handleIndeterminateChange(value) {
    if (value) {
      this.form.end_date = null;
    } else {
      this.form.end_date = "";
    }
  },
    moment,
    resetData(hard) {
      if (hard) {
        // Init form
        this.form = {
          daily_posting_compensation: 0,
          advanced_seniority_pay: 0,
          monthly_superminimum: 0,
          employee: null,
          employer: null,
          start_date: null,
          end_date: null,
          attachment: null,
          attachment_tus: null,
          name: null,
          national_contract: "",
          national_contract_level: "",
          language: "",
          currency: "",
          description: null,
          renewals: [
            {
              renewal_date: null,
              end_date: null,
            },
          ],
          work_locations: [
            {
              work_location: null,
              remote_work: false,
              valid_from: null,
              valid_to: null,
              is_permanent: true,
            },
          ],
          bonuses: [],
          reimbursment: "NOT",
          travel_compensation_amount: 0,
          no_travel_compensation: false,
        }
        this.versions = []
        this.version = null
        this.form.bonuses = []
      } else {
        this.form.daily_posting_compensation = 0;
        this.form.advanced_seniority_pay = 0;
        this.form.monthly_superminimum = 0;
        this.form.employee = null;
        this.form.employer = null;
        this.form.start_date = null;
        this.form.end_date = null;
        this.form.attachment = null;
        this.form.attachment_tus = null;
        this.form.national_contract = "";
        this.form.national_contract_level = "";
        this.form.language = "";
        this.form.currency = "";
        this.form.renewals = [{
          renewal_date: null,
          end_date: null,
        }];
        this.form.work_locations = [{
          work_location: null,
          remote_work: false,
          valid_from: null,
          valid_to: null,
          is_permanent: true,
        }];
        this.form.bonuses = [];
        this.form.reimbursment = "NOT";
        this.form.travel_compensation_amount = 0;
        this.form.no_travel_compensation = false;
      }
      this.versionChanges = [];
      this.errorMessages = [];
    },
    setData(data) {
      const form = {};
      form.start_date = data.start_date ?? null;
      form.end_date = data.end_date ?? null;
      form.monthly_superminimum = data.monthly_superminimum;
      form.advanced_seniority_pay = data.advanced_seniority_pay;
      form.daily_posting_compensation = data.daily_posting_compensation;
      form.reimbursment = data.travel_posting_reimbursement;
      form.travel_compensation_amount = data.travel_posting_compensation;
      form.national_contract_level = data.national_contract_level;
      form.description = data.description;

      // Bounus employeecontractbonus_set
      form.bonuses = [];
      if (data.employeecontractbonus_set) {
        data.employeecontractbonus_set.forEach(el => {
          form.bonuses.push({
            bonus_valid_from: el.bonus_valid_from,
            bonus_valid_to: el.bonus_valid_to,
            bonus: el.bonus,
            id: el.id || null,
          })
        })
      }

      // Locations employeecontractworklocation_set
      form.work_locations = [];
      if (data.employeecontractworklocation_set && data.employeecontractworklocation_set.length) {
        data.employeecontractworklocation_set.forEach(el => {
          form.work_locations.push({
            remote_work: el.remote_work,
            valid_from: el.valid_from,
            valid_to: el.valid_to,
            work_location: el.work_location.formatted ?? null,
            is_permanent: el.valid_to == null,
            id: el.id || null
          })
        })
      }

      // Renewal employeecontractrenewal_set
      form.renewals = [];
      if (data.employeecontractrenewal_set && data.employeecontractrenewal_set.length) {
        data.employeecontractrenewal_set.forEach(el => {
          form.renewals.push({
            end_date: el.end_date,
            renewal_date: el.renewal_date,
            id: el.id || null
          })
        })
      }

      return form;
    },
    async loadContract() {
      await EmployeeContractService.getOne(this.existingForm.id, { expand: 'history' }).then((data) => {
        this.currentContract = data
        this.loadCompanyAddress(data.company_id);
        this.loadNationalContractLevel(data.national_contract.id);
        this.form = this.setData(data)
        this.form.employer = data.company_id;
        this.form.employee = data.employee;
        this.form.national_contract = data.national_contract.id;
        this.form.currency = data.monthly_superminimum_currency;
        this.form.language = data.language;
        this.form.isIndeterminate = data.end_date === null;

        // Versions
        let firstVersion = true;
        this.versions = [];
        data.history.forEach(element => {
          let version = {
            value: element.history_id,
            text: DateService.format(element.history_date, this.dateFormat + ' H:m') + ' - edit by: ' + element.history_user_email
          }
          if (firstVersion) {
            let currentVersion = {
              value: null,
              text: 'Current'
            }
            firstVersion = false;
            this.versions.push(currentVersion);
          }
          if (element.previous_version_diff.length) {
            this.versions.push(version);
          }
        });

        this.isLoading = false
      }).catch((err) => {
        console.log('====================================');
        console.log('@err', err);
        console.log('====================================');
      })
    },

    closeModal() {
      this.$bvModal.hide("associate-contract-modal");
    },

    async submit() {
      this.isSaving = true;
      await EmployeeContractService.update({
        employeecontractrenewal_set: this.employeeContractRenewalSet,
        employeecontractworklocation_set: this.employeeContractWorkLocationSet,
        employeecontractbonus_set: this.employeeContractBonusSet,
        start_date: this.form.start_date,
        end_date: this.form.end_date ? this.form.end_date : null,
        attachment_tus: this.form.attachment_tus,
        language: this.form.language,
        national_contract_level: this.form.national_contract_level,
        monthly_superminimum: this.form.monthly_superminimum,
        advanced_seniority_pay: this.form.advanced_seniority_pay,
        daily_posting_compensation: this.form.daily_posting_compensation,
        travel_posting_reimbursement: this.form.reimbursment,
        travel_posting_compensation: this.form.reimbursment === "NOT" ? "" : this.form.travel_compensation_amount,
        employee: this.form.employee,
        description: this.form.description,
      }, this.currentContract.id)
        .then(() => {
          this.$emit("refresh");
          this.closeModal()
        })
        .catch(err => {
          // Leggo lista errori
          const out = [];
          const errorList = err.response.data
          for (const key in errorList) {
            for (const err in errorList[key]) {
              out.push(errorList[key][err])
            }
          }
          Swal.fire({
            title: "",
            html: out.join('<br/>'),
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        }).finally(() => {
          this.isSaving = false;
        });
    },

    removeRenewalDate(idx) {
      this.form.renewals.splice(idx, 1);
    },

    addRenewalDate() {
      this.form.renewals = [...this.form.renewals, { renewal_date: null, end_date: null }];
    },

    resetContractDates() {
      this.form.end_date = null;
    },

    removeWorkLocation(idx) {
      this.form.work_locations.splice(idx, 1);
    },

    addWorkLocation() {
      if (!this.form.work_locations[0].work_location) return;

      this.form.work_locations = [
        {
          work_location: null,
          remote_work: false,
          valid_from: null,
          valid_to: null,
          is_permanent: true,
        },
        ...this.form.work_locations,
      ];
    },

    resetWorkLocationDates(location) {
      location.valid_from = this.form.start_date;
      location.valid_to = this.form.end_date;
    },

    addBonus() {
      if (this.form.bonuses.length && !this.form.bonuses[0].bonus) return;
      this.form.bonuses = [
        {
          bonus_valid_from: null,
          bonus_valid_to: null,
          bonus: null,
        },
        ...this.form.bonuses,
      ];
    },

    removeBonus: function (idx) {
      this.form.bonuses.splice(idx, 1);
    },

    resetTravelCompensation() {
      this.form.travel_compensation_amount = "";
    },

    async loadNationalContractLevel(nationalContractTypeID) {
      await EmployeeNationalContractLevelService.getAllByNationalContract(nationalContractTypeID).then((res) => {
        this.nationalContractLevels = res;
        if (!this.form.currency) {
          this.form.currency = this.nationalContractLevels.find(el => el.id === this.form.national_contract_level)?.base_pay_currency ?? null;
        }
      }).catch(err => {
        console.error(err);
        backendErrorSwal(err?.response?.data?.detail, err);
      });
    },

    async loadCompanyAddress(companyID) {
      await CompanyAddressService.getAllByCompany(companyID).then((res) => {
        this.companyAddresses = res.data.results;
      }).catch(err => {
        console.error(err);
        backendErrorSwal(err?.response?.data?.detail, err);
      });
    },

    onLocationPermanentChange(location) {
      if (location.is_permanent) {
        this.resetWorkLocationDates(location);
      }
    },

    changeVersion(version) {
      if (version == null) {
        this.form = this.setData(this.currentContract)
      } else {
        this.currentContract.history.forEach(
          (value, id) => {
            if (value.history_id == version) {
              this.historyContract = this.setData(value)
              this.versionChanges = value.previous_version_diff;
              this.previousHistoryContract = this.currentContract.history?.[id + 1]
            }
          }
        )

      }
    },
    nationalContractLevelsName(id) {
      return this.nationalContractLevels.find(el => el.id == id).level_name;
    },
  },
}
</script>