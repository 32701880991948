<template>
  <div>
    <div class="card card-custom">
      <div class="card-header border-0 pt-6 pb-0">
        <div class="card-title align-items-start flex-column">
          <h3 class="card-label font-weight-bolder">
            {{ $t("Documents") }}
          </h3>
          <span class="text-muted font-weight-bold font-size-sm mt-1">{{ $t("Update your documents") }}</span>
        </div>
        <div class="card-toolbar">
          <euro-checkbox v-model="expiring" :text="$t('Expiring')" size="lg" is-switch class="mr-6 mt-5"
          @input="$refs.table.refresh()"></euro-checkbox>
          <euro-select v-model="documentType" class="min-w-200px mr-4 mb-0"
            :multiselect-props="multiselectProps" @input="$refs.table.refresh()"></euro-select>
          <b-dropdown v-if="!buttonDisabled && !loading" no-caret right no-flip text="Document" variant="light-primary"
            toggle-class="font-weight-bolder py-3">
            <template #button-content>
              <div class="dropdown-button">
                <span class="svg-icon menu-icon">
                  <inline-svg src="/media/svg/icons/Files/File.svg" />
                </span>
                <span class="">{{ $t("Add document") }}</span>
              </div>
            </template>
            <div class="navi navi-hover min-w-md-250px">
              <b-dropdown-text class="navi-item" tag="div">
                <b-button class="create-button navi-link" @click="openModal('physical-fiscal-create-modal')">
                  <span class="navi-icon">
                    <i class="fas fa-plus"></i>
                  </span>
                  <span class="navi-text">
                    {{ $t("Fiscal Code") }}
                  </span>
                </b-button>
                <b-button
                  v-b-tooltip.hover="!hasPermission('identity_document_permission') ? $t('You are not authorized to upload this document') : null"
                  class="create-button navi-link"
                  :class="{ 'disabled-btn': !hasPermission('identity_document_permission') }"
                  @click="!hasPermission('identity_document_permission') ? null : openModal('create-passport-modal')">
                  <span class="navi-icon">
                    <i class="fas fa-plus"></i>
                  </span>
                  <span class="navi-text">
                    {{ $t("Passport") }}
                  </span>
                </b-button>
                <b-button v-b-tooltip.hover="!hasValidPassport ? $t('Please add a valid passport first') : null"
                  class="create-button navi-link" :class="{ 'disabled-btn': !hasValidPassport }"
                  @click="hasValidPassport ? openModal('create-visa-modal') : null">
                  <span class="navi-icon">
                    <i class="fas fa-plus"></i>
                  </span>
                  <span class="navi-text">
                    {{ $t("Visa") }}
                  </span>
                </b-button>
                <b-button
                  v-b-tooltip.hover="!hasPermission('identity_document_permission') ? $t('You are not authorized to upload this document') : null"
                  class="create-button navi-link"
                  :class="{ 'disabled-btn': !hasPermission('identity_document_permission') }"
                  @click="!hasPermission('identity_document_permission') ? null : openModal('create-identify-modal')">
                  <span class="navi-icon">
                    <i class="fas fa-plus"></i>
                  </span>
                  <span class="navi-text">
                    {{ $t("ID document") }}
                  </span>
                </b-button>
                <b-button class="create-button navi-link" @click="openModal('create-language-modal')">
                  <span class="navi-icon">
                    <i class="fas fa-plus"></i>
                  </span>
                  <span class="navi-text">
                    {{ $t("Language") }}
                  </span>
                </b-button>
                <b-button class="create-button navi-link" @click="openModal('create-education-modal')">
                  <span class="navi-icon">
                    <i class="fas fa-plus"></i>
                  </span>
                  <span class="navi-text">
                    {{ $t("Education") }}
                  </span>
                </b-button>
                <b-button class="create-button navi-link" @click="openModal('create-vat-modal')">
                  <span class="navi-icon">
                    <i class="fas fa-plus"></i>
                  </span>
                  <span class="navi-text">
                    {{ $t("VAT") }}
                  </span>
                </b-button>
                <b-button
                  v-b-tooltip.hover="!hasPermission('certificate_suitability_for_work_permission') ? $t('You are not authorized to upload this document') : null"
                  class="create-button navi-link"
                  :class="{ 'disabled-btn': !hasPermission('certificate_suitability_for_work_permission') }"
                  @click="!hasPermission('certificate_suitability_for_work_permission') ? null : openModal('create-suitability-work')">
                  <span class="navi-icon">
                    <i class="fas fa-plus"></i>
                  </span>
                  <span class="navi-text">
                    {{ $t("Certificate of suitability of work") }}
                  </span>
                </b-button>
                <b-button 
                  v-b-tooltip.hover='!hasIndividualCompany ? $t("A VAT number is require to add this document") : null'
                  class="create-button navi-link"
                  :class="{ 'disabled-btn': !hasIndividualCompany }"
                  @click="!hasIndividualCompany ? null : openModal('create-registration-certificate-modal')">
                  <span class="navi-icon">
                    <i class="fas fa-plus"></i>
                  </span>
                  <span class="navi-text">
                    {{ $t("National CCIAA Certificate") }}
                  </span>
                </b-button>
                <b-button
                  v-b-tooltip.hover='!hasIndividualCompany ? $t("A VAT number is require to add this document") : null'
                  class="create-button navi-link"
                  :class="{ 'disabled-btn': !hasIndividualCompany }"
                  @click="!hasIndividualCompany ? null : openModal('create-ins-third-party-modal')">
                  <span class="navi-icon">
                    <i class="fas fa-plus"></i>
                  </span>
                  <span class="navi-text">
                    {{ $t("Insurance - Third Party") }}
                  </span>
                </b-button>
                <b-button
                  v-b-tooltip.hover="!hasPermission('identity_document_permission') ? $t('You are not authorized to upload this document') : null"
                  class="create-button navi-link"
                  :class="{ 'disabled-btn': !hasPermission('identity_document_permission') }"
                  @click="!hasPermission('identity_document_permission') ? null : openModal('create-eu-health-modal')">
                  <span class="navi-icon">
                    <i class="fas fa-plus"></i>
                  </span>
                  <span class="navi-text">
                    {{ $t("EU health insurance") }}
                  </span>
                </b-button>
                <b-button
                  v-b-tooltip.hover="!hasPermission('posting_certificate_permission') ? $t('You are not authorized to upload this document') : null"
                  class="create-button navi-link"
                  :class="{ 'disabled-btn': !hasPermission('posting_certificate_permission') }"
                  @click="!hasPermission('posting_certificate_permission') ? null : openModal('create-a-module')">
                  <span class="navi-icon">
                    <i class="fas fa-plus"></i>
                  </span>
                  <span class="navi-text">
                    {{ $t("A1 certificate") }}
                  </span>
                </b-button>
                <b-button 
                  v-b-tooltip.hover="!hasPermission('identity_document_permission') ? $t('You are not authorized to upload this document') : null"
                  class="create-button navi-link"
                  :class="{ 'disabled-btn': !hasPermission('identity_document_permission') }"
                  @click="!hasPermission('identity_document_permission') ? null : openModal('create-eu-driving-modal')">
                  <span class="navi-icon">
                    <i class="fas fa-plus"></i>
                  </span>
                  <span class="navi-text">
                    {{ $t("EU driving license") }}
                  </span>
                </b-button>
                <b-button v-b-tooltip.hover class="create-button navi-link"
                  :title='isResidencePermitCreationDisabled ? $t("Disabled as citizen of EU") : !hasPermission("identity_document_permission") ? null : $t("You are not authorized to upload this document")'
                  :class="{ 'disabled-btn': isResidencePermitCreationDisabled || !hasPermission('identity_document_permission') }"
                  @click="isResidencePermitCreationDisabled || !hasPermission('identity_document_permission') ? null : openModal('create-permit-modal')">
                  <span class="navi-icon">
                    <i class="fas fa-plus"></i>
                  </span>
                  <span class="navi-text">
                    {{ $t("Residence permit") }}
                  </span>
                </b-button>
                <b-button v-b-tooltip.hover class="create-button navi-link"
                  :title='!hasIndividualCompany ? $t("A VAT number is require to add this document") : null'
                  :class="{ 'disabled-btn': !hasIndividualCompany }"
                  @click="!hasIndividualCompany ? null : openModal('create-social-security-modal')">
                  <span class=" navi-icon">
                    <i class="fas fa-plus"></i>
                  </span>
                  <span class="navi-text">
                    {{ $t("Social Security Office Registration") }}
                  </span>
                </b-button>
                <b-button v-if="physicalPerson.is_employee"
                  v-b-tooltip.hover="!hasPermission('paycheck_permission') ? $t('You are not authorized to upload this document') : null"
                  :class="{ 'disabled-btn': !hasPermission('paycheck_permission') }"
                  class="create-button navi-link" 
                  @click="openModal(!hasPermission('paycheck_permission') ? null :'create-paycheck-modal')">
                  <span class="navi-icon">
                    <i class="fas fa-plus"></i>
                  </span>
                  <span class="navi-text">
                    {{ $t("Employee paycheck") }}
                  </span>
                </b-button>
                <template v-if="physicalPerson.is_employee">
                  <div v-if="!hasPermission('contract_permission')"
                       class="create-button navi-link disabled-btn"
                       v-b-tooltip.hover="$t('You are not authorized to upload this document')">
                    <span class="navi-icon">
                      <i class="fas fa-plus"></i>
                    </span>
                    <span class="navi-text">
                      {{ $t("Employement contract") }}
                    </span>
                  </div>
                  <router-link v-else
                       class="create-button navi-link"
                       :to="{ name: 'associate-contract', params: { ppId: physicalPerson.id }, query: { redirect: this.$route.fullPath } }"
                       icon="Layout/Layout-top-panel-6.svg">
                    <span class="navi-icon">
                      <i class="fas fa-plus"></i>
                    </span>
                    <span class="navi-text">
                      {{ $t("Employement contract") }}
                    </span>
                  </router-link>
                </template>
              </b-dropdown-text>
            </div>
          </b-dropdown>
        </div>
      </div>
      <div class="card-body detail">
        <!-- TABLE DOCUMENT -->
        <datatable ref="table" :table-props="tableProps" :total="total" :options.sync="tableOptions"
              :per-page-options="perPageOptions">
          <template #[`cell.show_more`]="{ row }">
            <div v-if="row.item.document_type == 'PayCheck' && row.item.data.secondments.length > 0">
              <i class="pointer" :class="[row.detailsShowing ? icons.caretUp : icons.caretDown]"
                @click="row.toggleDetails"></i>
            </div>
          </template>

          <template #row-details="item">
            <div v-if="item.row.item.document_type == 'PayCheck'">
              <b-table :items="item.row.item.data.secondments" :fields="table.nestedFields">
                <template #cell(country)="{ item }">
                  <div class="font-weight-normal">
                    <country-flag :country-iso="item.country"></country-flag>
                  </div>
                </template>
                <template #cell(client)="{ item }">
                  <div v-for="client in item.clients" :key="client.id" class="d-flex align-items-center">
                    <Avatar :avatar-image="client.avatar" :avatar-text="client.name" class="mr-2" />
                    <div class="d-flex flex-column">
                      <span>{{ client.name }}</span>
                    </div>
                  </div>
                </template>
                <template #cell(date_start)="{ item }">
                  {{ item.date_start ? DateService.format(item.date_start, dateFormat) : "" }}
                </template>
                <template #cell(date_end)="{ item }">
                  {{ item.date_end ? DateService.format(item.date_end, dateFormat) : "" }}
                </template>
                <template #cell(actions)="{ item }">
                  <router-link :to="{
              name: 'detail-secondment',
              params: { secondmentId: item.id },
            }" class="btn btn-icon btn-light btn-hover-primary btn-sm">
                    <span class="svg-icon svg-icon-md svg-icon-primary">
                      <inline-svg src="/media/svg/icons/Navigation/Right-2.svg" />
                    </span>
                  </router-link>
                </template>
              </b-table>
            </div>
          </template>
          <template #[`cell.document_type`]="{ item }">
            <span :class="`font-weight-bold label label-inline label-light-${docTypes[item.document_type].labelColor}`">
              {{ docTypes[item.document_type].typeName }}
            </span>
          </template>
          <template #[`cell.name`]="{ item }">
            <div class="text-dark-75 font-weight-bolder font-size-lg w-40">
                {{ docTypes[item.document_type].name(item) }}
            </div>

            <template v-if="item.document_type == 'PostingCertificate'">
              <span v-for="(iso, idx) in item.posting_country" :key="idx"
                class="label label-inline label-light-success font-weight-bolder mr-2 mb-2">
                {{ countriesMap[iso].name }}
              </span>
            </template>

            <template v-if="item.document_type == 'CertificateOfSuitabilityForWork'"> </template>

            <template v-if="item.document_type == 'EducationLevel' && item.attainment_date != undefined">
              <span class="text-muted text-size-sm">
                {{ $t("Attainment date") + ": " + formattedDate(item?.attainment_date, dateFormat) }}
              </span>
            </template>

            <template v-if="item.document_type == 'FiscalID'">
              <template v-if="!isExpired(item)">
                <span class="label label-success label-dot mr-2"></span><span class="font-weight-bold text-success">
                  {{ $t("Valid") }}
                </span>
              </template>
              <template v-else>
                <span class="label label-danger label-dot mr-2"></span><span class="font-weight-bold text-danger">
                  {{ $t("Expired") }}
                </span>
              </template>
            </template>

            <template v-if="item.document_type == 'Passport' && item.data?.date_of_issue">
              <div class="text-muted text-size-sm">
<!--                {{ $t("Language") + ": " + extendedLanguage(item.data?.doc_language) }}-->
              </div>
              <div class="text-muted text-size-sm">
<!--                {{ $t("Release") + ": " + formattedDate(item.data?.date_of_issue, dateFormat) }}-->
              </div>
            </template>

            <template v-if="item.document_type == 'ResidencePermit'">
              <div v-if="item.is_authorized" class="text-muted text-size-sm">
                {{ $t("Number") + ": " + item.label }}
              </div>
            </template>

            <template v-if="item.document_type == 'EmployeeContract'">
              <span class="label label-inline label-light-success font-weight-bolder mr-2 mt-2">
                {{ item.data.country.name }}
              </span>
              <span class="text-muted text-size-sm mt-2">
                {{ item.data.national_contract_level }}
              </span>
            </template>
            <template v-if="item.document_type == 'PayCheck' && item.data.description != ''">
              <div class="text-muted text-size-sm">
                {{ $t("Description") + ": " + item.data.description }}
              </div>
            </template>

            <template v-if="item.document_type === 'IdentityDocument' && item.is_authorized">
              <div class="text-muted text-size-sm">
                <span v-if="isUnionEuropeanGroup(item) && item.data.valid_for_travel"
                  :title="$t('Valid for travel in the European Union')"
                  class="label label-inline label-light-success font-weight-bolder mr-2 mb-2">
                  EU: {{ $t("VALID TRAVEL") }}</span>
                <span v-else :title="$t('NOT Valid for travel in the European Union')"
                  class="label label-inline label-light-danger font-weight-bolder mr-2 mb-2">
                  EU: {{ $t("NOT VALID TRAVEL") }}
                </span>
                <div>{{ $t("Release") + ": " + formattedDate(item.date_start, dateFormat) + " " }}</div>
              </div>
            </template>

            <template v-if="item.document_type == 'EuropeanDrivingLicense' && item.is_authorized">
              <div class="text-muted text-size-sm">
                <span v-for="(categoryItem, index) in item.data.category_set" :key="index" class="font-weight-bold">
                  <span class="text-success">
                    {{ $t(categoryItem.category + ":") }}
                  </span>
                  <span>
                    <span>{{ $t("Expire date") + " - " + formattedDate(categoryItem.date_to, dateFormat) }}</span>
                  </span>
                  <br />
                </span>
              </div>
            </template>

            <template v-if="item.document_type == 'EuropeanHealthInsuranceCard' && item.is_authorized">
              <div class="text-muted text-size-sm">
                {{ $t("Card number") + ": " + item.data.card_identification_number }}
              </div>
              <div class="text-muted text-size-sm">
                {{
              $t("Institution number") +
              ": " +
              item.data.institution_identification_number
            }}
              </div>
            </template>
            <template v-if="item.document_type == 'InsuranceCertificate'">
              <div class="text-muted text-size-sm">
                {{ $t("Counterpart") + ": " + item.data.counterpart }}
              </div>
            </template>
            <template v-if="item.document_type == 'KnownLanguage'">
              <span class="text-muted text-size-sm mr-2">{{ item.level }}</span>
              <template v-if="item.data.certification">
                <span><span class="label label-success label-dot mr-2"></span><span class="font-weight-bold text-success">{{
                  $t("Certified")
                }}</span></span>
              </template>
              <template v-else><span class="label label-danger label-dot mr-2"></span><span
                  class="font-weight-bold text-danger">{{ $t("Not Certificated") }}</span></template>
            </template>
            <template v-if="item.document_type == 'CompanyRegistrationCertificate'">
              <div class="text-muted text-size-sm">
                {{ $t("Emission date") + ": " + formattedDate(item.data.emission_date, dateFormat) }}
              </div>
            </template>
          </template>

          <template #[`cell.date_start`]="{ item }">
            <span class="font-weight-bolder text-muted">{{ formattedDate(item.date_start,
              dateFormat)
              }}</span>
          </template>
          <template #[`cell.date_end`]="{ item }">
            <span class="font-weight-bolder text-muted">{{ formattedDate(item.date_end, dateFormat)
              }}</span>
          </template>
          <template #[`cell.actions`]="{ item }">
            <div class="d-flex">
              <b-button v-if="item.document_type != 'PayCheck'" v-b-tooltip.hover
                class="btn btn-icon btn-light btn-hover-primary btn-sm mr-2" :title="titleDownloadButton(item.is_authorized)"
                :class="{ disabled: !item.attachment }" :disabled="!item.attachment"
                @click="item.is_authorized ? download(item) : null">
                <span class="svg-icon svg-icon-md" :class="{ 'svg-icon-primary': item.attachment }">
                  <inline-svg src="/media/svg/icons/General/Clip.svg" />
                </span>
              </b-button>
              <b-button v-else v-b-tooltip.hover class="btn btn-icon btn-light btn-hover-primary btn-sm mr-2"
                :title="titleDownloadButton(item.is_authorized)" :class="{ disabled: !item.attachment }" :disabled="!item.attachment"
                @click="item.is_authorized ? downloadZip(item.id) : null">
                <span class="svg-icon svg-icon-md" :class="{ 'svg-icon-primary': item.attachment }">
                  <inline-svg src="/media/svg/icons/General/Clip.svg" />
                </span>
              </b-button>
              <b-button v-if="item.attachment_back" v-b-tooltip.hover
                class="btn btn-icon btn-light btn-hover-primary btn-sm mr-2"
                :class="{ disabled: !item.attachment_back }" :title="titleDownloadBackButton(item.is_authorized)"
                :disabled="!item.attachment_back" @click="item.is_authorized ? downloadBack(item) : null">
                <span class="svg-icon svg-icon-md" :class="{ 'svg-icon-primary': item.attachment_back }">
                  <inline-svg src="/media/svg/icons/General/Clip.svg" />
                </span>
              </b-button>
              <b-button v-b-tooltip.hover class="btn btn-icon btn-light btn-hover-primary btn-sm mr-2"
                :title="titleEditButton(item.is_authorized)" :class="{ disabled: !item.is_authorized }"
                :disabled="!item.is_authorized"
                @click="item.is_authorized ? onEditBtnClick(item, docTypes[item.document_type].modal) : null">
                <span class="svg-icon svg-icon-md" :class="{ 'svg-icon-primary': item.is_authorized }">
                  <inline-svg src="/media/svg/icons/Design/Edit.svg" />
                </span>
              </b-button>
              <b-button v-b-tooltip.hover class="btn btn-icon btn-light btn-hover-primary btn-sm"
                :title="titleDeleteButton(item.is_authorized)" :class="{ disabled: !item.is_authorized }"
                :disabled="!item.is_authorized"
                @click="item.is_authorized ? onDeleteBtnClick(item) : null">
                <span class="svg-icon svg-icon-md"
                  :class="{ 'svg-icon-primary': item.is_authorized }">
                  <inline-svg src="/media/svg/icons/General/Trash.svg" />
                </span>
              </b-button>
            </div>
          </template>
        </datatable>
      </div>
    </div>

    <!-- Modal -->
    <PhysicalPersonCreateFiscalModal :existing-form="editForm" :physical-person="physicalPerson" :countries="countries"
      :existing-countries="currentFiscalIdCountryList" @refresh="$refs.table.refresh()">
    </PhysicalPersonCreateFiscalModal>
    <PhysicalPersonCreatePassportModal :existing-form="editForm" :physical-person="physicalPerson"
      :countries="countries" :languages="languages" @refresh="$refs.table.refresh()">
    </PhysicalPersonCreatePassportModal>
    <PhysicalPersonCreateVisaModal :existing-form="editForm" :physical-person="physicalPerson" :countries="countries"
      :passports="passportsData" :languages="languages" @refresh="$refs.table.refresh()">
    </PhysicalPersonCreateVisaModal>
    <PhysicalPersonCreateIdentityModal :existing-form="editForm" :physical-person="physicalPerson"
      :countries="countries" :languages="languages" @refresh="$refs.table.refresh()">
    </PhysicalPersonCreateIdentityModal>
    <PhysicalPersonCreateLanguageModal :existing-form="editForm" :physical-person="physicalPerson"
      :countries="countries" :languages="languages" :language-kind="languageLevels" @refresh="$refs.table.refresh()">
    </PhysicalPersonCreateLanguageModal>
    <PhysicalPersonCreateEducationModal :existing-form="editForm" :physical-person="physicalPerson"
      :countries="countries" :languages="languages" :education-kind="educationKinds" @refresh="$refs.table.refresh()">
    </PhysicalPersonCreateEducationModal>
    <PhysicalPersonCreateEuHealthInsuranceModal :existing-form="editForm" :physical-person="physicalPerson"
      @refresh="$refs.table.refresh()">
    </PhysicalPersonCreateEuHealthInsuranceModal>
    <PhysicalPersonCreateA1ModuleModal :existing-form="editForm" :physical-person="physicalPerson"
      @refresh="$refs.table.refresh()">
    </PhysicalPersonCreateA1ModuleModal>
    <PhysicalPersonCreateSuitabilityWorkModal :existing-form="editForm" :physical-person="physicalPerson"
      @refresh="$refs.table.refresh()">
    </PhysicalPersonCreateSuitabilityWorkModal>
    <PhysicalPersonCreateEuDrivingLicenseModal :existing-form="editForm" :physical-person="physicalPerson"
      @refresh="$refs.table.refresh()">
    </PhysicalPersonCreateEuDrivingLicenseModal>
    <PhysicalPersonCreateResidencePermitModal :existing-form="editForm" :physical-person="physicalPerson"
      :countries="countries" @refresh="$refs.table.refresh()">
    </PhysicalPersonCreateResidencePermitModal>
    <LegalPersonCreateVatModal :existing-form="editForm" :legal-person="company" :countries="countries" :saving="saving"
      :physical-person-id="physicalPerson.id" @refresh="$refs.table.refresh()">
    </LegalPersonCreateVatModal>
    <LegalPersonCreateRegistrationCertificateModal :existing-form="editForm" :legal-person="company"
      :countries="countries" @refresh="$refs.table.refresh()">
    </LegalPersonCreateRegistrationCertificateModal>
    <LegalPersonCreateInsThirdPartyModal :existing-form="editForm" :legal-person="company" :countries="countries"
      @refresh="$refs.table.refresh()">
    </LegalPersonCreateInsThirdPartyModal>
    <LegalPersonCreateSocialSecurityModal :existing-form="editForm" :legal-person="company" :countries="countries"
      @refresh="$refs.table.refresh()">
    </LegalPersonCreateSocialSecurityModal>
    <AssociateContractModal :existing-form="editForm" :physical-person="physicalPerson" @refresh="$refs.table.refresh()">
    </AssociateContractModal>
    <PhysicalPersonCreatePaycheckModal :existing-form="editForm" :physical-person="physicalPerson"
      @refresh="$refs.table.refresh()">
    </PhysicalPersonCreatePaycheckModal>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
// Modal
import PhysicalPersonCreateFiscalModal from "@/view/components/modals/physical-person/PhysicalPersonCreateFiscalModal.vue";
import PhysicalPersonCreatePassportModal from "@/view/components/modals/physical-person/PhysicalPersonCreatePassportModal.vue";
import PhysicalPersonCreateVisaModal from "@/view/components/modals/physical-person/PhysicalPersonCreateVisaModal.vue";
import PhysicalPersonCreateIdentityModal from "@/view/components/modals/physical-person/PhysicalPersonCreateIdentityModal.vue";
import PhysicalPersonCreateLanguageModal from "@/view/components/modals/physical-person/PhysicalPersonCreateLanguageModal.vue";
import PhysicalPersonCreateEducationModal from "@/view/components/modals/physical-person/PhysicalPersonCreateEducationModal.vue";
import CompanyVatService from "@/core/services/company/company-vat.service";
import LegalPersonCreateVatModal from "@/view/components/modals/legal-person/LegalPersonCreateVatModal.vue";
import LegalPersonCreateRegistrationCertificateModal from "@/view/components/modals/legal-person/LegalPersonCreateRegistrationCertificateModal.vue";
import LegalPersonCreateInsThirdPartyModal from "@/view/components/modals/legal-person/LegalPersonCreateInsThirdPartyModal.vue";
import LegalPersonCreateSocialSecurityModal from "@/view/components/modals/legal-person/LegalPersonCreateSocialSecurityModal.vue";
import PhysicalPersonCreateEuHealthInsuranceModal from "@/view/components/modals/physical-person/PhysicalPersonCreateEuHealthInsuranceModal.vue";
import PhysicalPersonCreateA1ModuleModal from "@/view/components/modals/physical-person/PhysicalPersonCreateA1ModuleModal.vue";
import PhysicalPersonCreateEuDrivingLicenseModal from "@/view/components/modals/physical-person/PhysicalPersonCreateEuDrivingLicenseModal.vue";
import PhysicalPersonCreateResidencePermitModal from "@/view/components/modals/physical-person/PhysicalPersonCreateResidencePermitModal.vue";
import PhysicalPersonCreateSuitabilityWorkModal from "@/view/components/modals/physical-person/PhysicalPersonCreateSuitabilityWorkModal.vue";
import AssociateContractModal from "@/view/components/modals/physical-person/AssociateContractModal.vue";
import PhysicalPersonCreatePaycheckModal from "@/view/components/modals/physical-person/PhysicalPersonCreatePaycheckModal.vue"
// Service
import DateService from "@/core/services/date.service";
import PhysicalPersonLanguageService from "@/core/services/physical-person/physical-person-language.service";
import PhysicalPersonEducationService from "@/core/services/physical-person/physical-person-education.service";
import PhysicalPersonService from "@/core/services/physical-person/physical-person.service";
import PhysicalPersonResidencePermitService from "@/core/services/physical-person/physical-person-residence-permit.service";
import PhysicalPersonFiscalService from "@/core/services/physical-person/physical-person-fiscal.service";
import PhysicalPersonPassportService from "@/core/services/physical-person/physical-person-passport.service";
import PhysicalPersonIdentityService from "@/core/services/physical-person/physical-person-identitydocument.service";
import PhysicalPersonEuropeanHealthInsuranceService from "@/core/services/physical-person/physical-person-europeanhealthinsurance.service";
import PhysicalPersonA1ModuleService from "@/core/services/physical-person/physical-person-a1-module.service";
import PhysicalPersonEuropeanDrivingLicenseService from "@/core/services/physical-person/physical-person-europeandrivinglicense.service";
import EmployeeContractService from "@/core/services/employee/employee-contract.service";
import EmployeeService from "@/core/services/employee/employee.service";
import CompanyRegistrationCertificateService from "@/core/services/company/company-registration-certificate.service";
import CompanyInsuranceCertificateService from "@/core/services/company/company-insurancecertificate.service";
import CompanySocialSecurityService from "@/core/services/company/company-socialsecurity.service";
import PhysicalPersonSuitabilityWorkService from "@/core/services/physical-person/physical-person-suitability-work.service.js";
import PhysicalPersonPaycheckService from "@/core/services/physical-person/physical-person-employee-paycheck.service";
// Other
import icons from "@/core/config/icons.js";
import CountryFlag from "@/view/components/CountryFlag.vue";
import { backendErrorSwal } from "@/core/helpers/swal";
import { mapState, mapGetters } from "vuex";
import moment from "moment";
const today = new Date();
import Swal from "sweetalert2";
import { perPageOptions } from "@/core/config/datatable.config.js"
import PhysicalPersonVisaService from "@/core/services/physical-person/physical-person-visa-service";

export default {
  components: {
    PhysicalPersonCreateFiscalModal,
    PhysicalPersonCreatePassportModal,
    PhysicalPersonCreateVisaModal,
    PhysicalPersonCreateIdentityModal,
    PhysicalPersonCreateLanguageModal,
    PhysicalPersonCreateEducationModal,
    LegalPersonCreateVatModal,
    LegalPersonCreateRegistrationCertificateModal,
    LegalPersonCreateInsThirdPartyModal,
    PhysicalPersonCreateEuHealthInsuranceModal,
    PhysicalPersonCreateA1ModuleModal,
    PhysicalPersonCreateEuDrivingLicenseModal,
    PhysicalPersonCreateResidencePermitModal,
    LegalPersonCreateSocialSecurityModal,
    PhysicalPersonCreateSuitabilityWorkModal,
    AssociateContractModal,
    PhysicalPersonCreatePaycheckModal,
    CountryFlag,
  },
  props: {
    physicalPerson: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      icons,
      editForm: {},
      documentType: null,
      saving: false,
      languageLevels: [],
      educationKinds: [],
      // items: [],
      residencePermitOptions: [],
      certificateOptions: [],
      DateService,
      fiscalIdCountry: null,
      currentFiscalIdCountryList: [],
      passportCount: 0,
      passportsData: [],
      total: 0,
      searchFilter: "",
      documentOptions: [],
      expiring: false,
      hasValidPassport: false,
      table: {
        fields: [
          { label: "", key: "show_more" },
          { label: this.$t("Type"), key: "document_type", sortable: true, class: "align-middle" },
          { label: this.$t("Name"), key: "name", class: "align-middle" },
          {
            label: this.$t("Valid From"),
            key: "date_start",
            sortable: true,
            class: "align-middle",
          },
          {
            label: this.$t("Valid To"),
            key: "date_end",
            sortable: true,
            class: "align-middle",
          },
          { label: this.$t("Actions"), key: "actions", class: "align-end col-actions" },
        ],
        nestedFields: [
          { key: "country", label: `${this.$t("Country")}`, class: ["table-align", "font-weight-bolder"] },
          { key: "client", label: `${this.$t("Client")}`, class: ["table-align", "font-weight-bolder"] },
          { key: "date_start", label: `${this.$t("Start date")}`, class: ["table-align"] },
          { key: "date_end", label: `${this.$t("End date")}`, class: ["table-align"] },
          { key: "actions", label: this.$t("Action"), class: ["table-align, col-actions"] }
        ],
        isLoading: true,
      },
      perPageOptions: perPageOptions,
      tableOptions: {
        "sort-by": "date_end",
        "sort-desc": false,
        "per-page": 10,
        "no-local-sorting": false,
      },
    };
  },
  computed: {
    ...mapState("constants", ["countries", "languages"]),
    ...mapGetters("user", ["dateFormat", "isAfterSale", "isApStaff", "getPersonaId", "getCurrentManagedCompany"]),
    ...mapGetters("constants", ["countriesMap"]),
    ...mapGetters("constants", ["europeanGroup", "isEUCountry"]),


    multiselectProps() {
      return {
        options: this.documentOptions,
        searchable: false,
        showLabels: false,
        "allow-empty": false,
      };
    },

    docTypes() {
      return {
        FiscalID: {
          typeName: "Fiscal ID",
          labelColor: "danger",
          name: item => item.country + " " + (item.is_authorized ? item.label : '*****'),
          isEditable: item => item.is_authorized,
          isDeletable: item => item.is_authorized,
          modal: "physical-fiscal-create-modal",
          downLoadFunc: item => PhysicalPersonFiscalService.getOne(item),
          delFunc: item => PhysicalPersonFiscalService.delete(item.id),
        },
        Passport: {
          typeName: "Passport",
          labelColor: "info",
          name: (item) =>
            item.country + " " + (item.is_authorized ? item.label : "*****"),
          isEditable: item => item.is_authorized,
          isDeletable: item => item.is_authorized,
          modal: "create-passport-modal",
          downLoadFunc: item => PhysicalPersonPassportService.getOne(item.id),
          delFunc: item => PhysicalPersonPassportService.delete(item),
        },
        Visa: {
          typeName: "Visa",
          labelColor: "warning",
          name: item => item.data.destination_country + " " + (item.is_authorized ? item.label : '*****'),
          isEditable: item => item.is_authorized,
          isDeletable: item => item.is_authorized,
          modal: "create-visa-modal",
          downLoadFunc: item => PhysicalPersonVisaService.getOne(item.id),
          delFunc: item => PhysicalPersonVisaService.delete(item),
        },
        PayCheck: {
          typeName: "Paycheck",
          labelColor: "success",
          name: item => this.formattedDate(item.label, "YYYY-MM"),
          isEditable: item => item.is_authorized,
          isDeletable: item => item.is_authorized,
          modal: 'create-paycheck-modal',
          downLoadFunc: function (item) {
            item.attachments.forEach(element => {
              window.open(element.attachment, "_blank");
            })
          },
          delFunc: item => PhysicalPersonPaycheckService.delete(item.id),
        },
        EmployeeContract: {
          typeName: "Contract",
          labelColor: "info",
          name: item => (item.is_authorized ? item.data.national_contract : '*****'),
          isEditable: item => item.is_authorized,
          isDeletable: item => item.is_authorized,
          modal: 'associate-contract-modal',
          downLoadFunc: item => EmployeeContractService.getOne(item.id),
          delFunc: item => EmployeeContractService.delete(item.id),
        },
        IdentityDocument: {
          typeName: "ID Document",
          labelColor: "info",
          name: item => item.country + " " + (item.is_authorized ? item.label : "*****"),
          isEditable: item => item.is_authorized,
          isDeletable: item => item.is_authorized,
          modal: "create-identify-modal",
          downLoadFunc: item => PhysicalPersonIdentityService.getOne(item),
          delFunc: item => PhysicalPersonIdentityService.delete(item),
        },
        EducationLevel: {
          typeName: "Education Level",
          labelColor: "success",
          name: item => item.label,
          isEditable: item => item.is_authorized,
          isDeletable: item => item.is_authorized,
          modal: "create-education-modal",
          downLoadFunc: item => PhysicalPersonEducationService.getOne(item),
          delFunc: item => PhysicalPersonEducationService.delete(item),
        },
        KnownLanguage: {
          typeName: "Language",
          labelColor: "warning",
          name: (item) => item.data.level + " " + item.label,
          isEditable: item => item.is_authorized,
          isDeletable: item => item.is_authorized,
          modal: "create-language-modal",
          downLoadFunc: item => PhysicalPersonLanguageService.getOne(item),
          delFunc: item => PhysicalPersonLanguageService.delete(item.id),
        },
        EuropeanHealthInsuranceCard: {
          typeName: "EU health insurance",
          labelColor: "warning",
          name: item => (item.is_authorized ? item.label : '*****'),
          isEditable: item => item.is_authorized,
          isDeletable: item => item.is_authorized,
          modal: "create-eu-health-modal",
          downLoadFunc: item => PhysicalPersonEuropeanHealthInsuranceService.getOne(item.id),
          delFunc: item => PhysicalPersonEuropeanHealthInsuranceService.delete(item.id),
        },
        EuropeanDrivingLicense: {
          typeName: "EU driving license",
          labelColor: "success",
          name: item => (item.is_authorized ? item.label : '*****'),
          isEditable: item => item.is_authorized,
          isDeletable: item => item.is_authorized,
          modal: "create-eu-driving-modal",
          downLoadFunc: item => PhysicalPersonEuropeanDrivingLicenseService.getOne(item.id),
          delFunc: item => PhysicalPersonEuropeanDrivingLicenseService.delete(item.id),
        },
        PostingCertificate: {
          typeName: "Posting certificate",
          labelColor: "primary",
          attachmentField: "doc",
          name: item => item.country + " " + (item.is_authorized ?  item.data.certificate_kind: '*****'),
          isEditable: item => item.is_authorized,
          isDeletable: item => item.is_authorized,
          modal: "create-a-module",
          downLoadFunc: item => PhysicalPersonA1ModuleService.getOne(item.id),
          delFunc: item => PhysicalPersonA1ModuleService.delete(item.id),
        },
        CertificateOfSuitabilityForWork: {
          typeName: "Certificate Suitability Of Work",
          labelColor: "success",
          attachmentField: "doc",
          name: item => (item.is_authorized ? item.label: '*****'),
          isEditable: item => item.is_authorized,
          isDeletable: item => item.is_authorized,
          modal: "create-suitability-work",
          updateFunc: (item, itemId) => PhysicalPersonSuitabilityWorkService.update(item, itemId),
          downLoadFunc: item => PhysicalPersonSuitabilityWorkService.getOne(item.id),
          delFunc: item => PhysicalPersonSuitabilityWorkService.delete(item.id),
        },
        ResidencePermit: {
          typeName: "Residence Permit",
          labelColor: "info",
          name: item => (item.is_authorized ?item.label: '*****'),
          isEditable: item => item.is_authorized === true,
          isDeletable: item => item.is_authorized === true,
          modal: "create-permit-modal",
          updateFunc: (item, itemId) => PhysicalPersonResidencePermitService.update(item, itemId),
          downLoadFunc: item => PhysicalPersonResidencePermitService.getOne(item.id),
          delFunc: item => PhysicalPersonResidencePermitService.delete(item),
        },
        ExtraCompanyVAT: {
          typeName: "VAT",
          labelColor: "warning",
          name: item => item.label,
          isEditable: item => item.is_authorized,
          isDeletable: item => item.is_authorized,
          modal: "create-vat-modal",
          downLoadFunc: item => CompanyVatService.getOne(item.id),
          delFunc: item => CompanyVatService.delete(item.id),
        },
        CompanyRegistrationCertificate: {
          typeName: "National CCIAA certificate",
          labelColor: "danger",
          name: item => (item.is_authorized ? item.label : '*****'),
          isEditable: item => item.is_authorized,
          isDeletable: item => item.is_authorized,
          modal: "create-registration-certificate-modal",
          downLoadFunc: item => CompanyRegistrationCertificateService.getOne(item.id),
          delFunc: item => CompanyRegistrationCertificateService.delete(item.id),
        },
        InsuranceCertificate: {
          typeName: "Insurance - Third Party",
          labelColor: "primary",
          name: item => (item.is_authorized ? item.label : '*****'),
          isEditable: item => item.is_authorized,
          isDeletable: item => item.is_authorized,
          modal: "create-ins-third-party-modal",
          downLoadFunc: item => CompanyInsuranceCertificateService.getOne(item.id),
          delFunc: item => CompanyInsuranceCertificateService.delete(item.id),
        },
        CompanySocialSecurityCertificate: {
          typeName: "Social Security Office Registration",
          labelColor: "success",
          name: item => (item.is_authorized ? item.label : '*****'),
          isEditable: item => item.is_authorized,
          isDeletable: item => item.is_authorized,
          modal: "create-social-security-modal",
          downLoadFunc: item => CompanySocialSecurityService.getOne(item.id),
          delFunc: item => CompanySocialSecurityService.delete(item.id),
        },
      };
    },

    company() {
      if (!this.hasIndividualCompany) {
        return {};
      }

      return this.physicalPerson.individual_company_manager[0];
    },

    hasIndividualCompany() {
      return this.physicalPerson.has_individual_company;
    },

    tableProps() {
      return {
        filter: this.searchFilter,
        fields: this.table.fields,
        items: this.itemProvider
      };
    },

    isResidencePermitCreationDisabled() {
      if (this.europeanGroup) {
        // Se l'utente ha una cittadinanza in un paese europeo
        let citizenships = this.physicalPerson.citizenship_set.map(cit => cit.country);
        return this.europeanGroup.some(el => citizenships.includes(el.country));
      } else {
        return false;
      }
    },

    buttonDisabled() {
      if (this.physicalPerson.id == this.getPersonaId) {
        return false;
      }
      return this.isAfterSale;
    },
    
  },
  
  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("Physical person"), route: { name: "manage-physical-person" } },
      { title: this.$t("Documents") },
    ]);
    this.table.isLoading = true;
    await this.loadCertificateKinds();
    await this.loadResidencePermitKinds();
    const [langLevels, educationKinds] = await Promise.all([
      PhysicalPersonLanguageService.getLevels(),
      PhysicalPersonEducationService.getChoices(),
    ]);
    this.languageLevels = langLevels;
    this.educationKinds = educationKinds;
    this.table.isLoading = false;
  },
  methods: {
    isUnionEuropeanGroup(item) {
      return this.isEUCountry(item.country);
    },
    
    
    itemProvider(ctx, callback) {
      let fields = "";
      if (this.documentType) {
        fields += this.documentType;
      }
      PhysicalPersonService.getDocuments({
        perPage: ctx.perPage,
        page: ctx.currentPage,
        sortBy: ctx.sortBy || "date_end",
        search: ctx.filter,
        sortDesc: ctx.sortDesc,
        fields: fields,
        id: this.physicalPerson.id,
        expiring: this.expiring,
      })
        .then((res) => {
          let out = res.results;
          if (this.documentOptions.length === 0) {
            this.documentOptions = res.document_types.map((key) => {
              return {
                value: key,
                text: this.docTypes[key].typeName,
              };
            });
          }
          try {
            out.map((item) => {
              if (!this.docTypes[item.document_type]) {
                console.error(
                  `docTypes does not have a property ${item.document_type}`
                );
              } else if (
                typeof this.docTypes[item.document_type].name !== "function"
              ) {
                console.error(
                  `docTypes[${item.document_type}] does not have a method named 'name'`
                );
              } else {
                const name = this.docTypes[item.document_type].name(item);
                if (typeof name !== "string") {
                  console.error(
                    `docTypes[${item.document_type}].name does not return a string value`
                  );
                }
              }
            });
          } catch (error) {
            console.error(error);
          }
          this.total = res.count;
          this.currentFiscalIdCountryList = res.fiscal_id_countries;
          this.hasValidPassport = res.has_valid_passport
          this.loading = false;
          callback(out);
        })
        .catch((err) => {
          this.loading = false;
          console.error(err)
          callback([]);
        });
    },
    

    async loadResidencePermitKinds() {
      try {
        const {
          actions: {
            POST: {
              permit_kind: { choices },
            },
          },
        } = await PhysicalPersonResidencePermitService.getOptions();

        this.residencePermitOptions = choices;
      } catch (err) {
        backendErrorSwal(err?.response?.data?.detail, err);
      }
    },
    async loadCertificateKinds() {
      try {
        const {
          actions: {
            POST: {
              certificate_kind: { choices },
            },
          },
        } = await PhysicalPersonSuitabilityWorkService.getOptions();

        this.certificateOptions = choices;
      } catch (err) {
        backendErrorSwal(err?.response?.data?.detail, err);
      }
    },

    isExpired(item) {
      return moment(item.valid_to).isBefore(moment(today));
    },

    formattedDate(date, format) {
      if (!date) return "";
      return DateService.format(date, format);
    },

    extendedLanguage(iso) {
      const language = this.languages.find(el => el.iso_639_1 == iso);
      if (!language) return iso;
      return language.name_en;
    },

    async openModal(id) {
      this.editForm = {};
      if (id === "create-visa-modal") {
        await this.getPassports();
        if (this.passportCount === 0) {
          return;
        }
      }
      this.$bvModal.show(id);
    },

    async download(item) {
      const downLoadFunc = this.docTypes[item.document_type].downLoadFunc;
      try {
        const res = await downLoadFunc(item);

        window.open(res.attachment || res.doc, "_blank");
      } catch (err) {
        let message = "";
        if (err?.response?.status === 400) {
          message = err.response.data;
        } else {
          message = err?.response?.data?.detail ?? this.$t("Something went wrong!");
        }
        backendErrorSwal(message, err);
      }
    },

    async downloadBack(item) {
      const downLoadFunc = this.docTypes[item.document_type].downLoadFunc;
      try {
        const res = await downLoadFunc(item);

        window.open(res.attachment_back || res.doc, "_blank");
      } catch (err) {
        let message = "";
        if (err?.response?.status === 400) {
          message = err.response.data;
        } else {
          message = err?.response?.data?.detail ?? this.$t("Something went wrong!");
        }
        backendErrorSwal(message, err);
      }
    },

    async onEditBtnClick(item, modalId) {
      let downLoadFunc;
      if (item.document_type === "PayCheck") {
        downLoadFunc = item => PhysicalPersonPaycheckService.getOne(item.id)
      } else {
        downLoadFunc = this.docTypes[item.document_type].downLoadFunc;
      }
      if (item.document_type === "Visa") {
        await this.getPassports();
        if (this.passportCount === 0) {
          return;
        }
      }
      try {
        const res = await downLoadFunc(item);
        this.editForm = { ...res };
        this.$bvModal.show(modalId);
      } catch (err) {
        let message = "";
        if (err?.response?.status === 400) {
          message = err.response.data;
        } else {
          message = err?.response?.data?.detail ?? this.$t("Something went wrong!");
        }
        backendErrorSwal(message, err);
      }
    },

    async onDeleteBtnClick(item) {
      await Swal.fire({
        title: this.$t("This document will be deleted, are you sure?"),
        icon: "warning",
        confirmButtonText: this.$t("Yes delete"),
        showCancelButton: true,
        cancelButtonText: this.$t("No, do NOT delete"),
      }).then(async result => {
        if (result.isConfirmed) {
          try {
            await this.docTypes[item.document_type].delFunc(item);
            Swal.fire(this.$t("Success"), this.$t("Document deleted"), "success").then(() => {
              this.$refs.table.refresh();
            });
            // await this.loadPersonDocuments();
            
          } catch (err) {
            let message = "";
            if (err?.response?.status === 400) {
              message = err.response.data;
            } else {
              message = err?.response?.data?.detail ?? this.$t("Unable to delete the document");
            }
            backendErrorSwal(message, err);
          }
        }
      });
    },

    async downloadZip(id) {
      await EmployeeService.donwloadPaycheckAttachmentZip(id).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        const contentDisposition = response.headers['content-disposition'];
        let fileName = 'unknown';
        if (contentDisposition) {
          const fileNameMatch = contentDisposition.match(/filename=(.+)/);
          if (fileNameMatch.length === 2)
            fileName = fileNameMatch[1];
        }
        link.setAttribute('download', fileName);
        link.click();
        link.remove();
      }).catch(err => {
        backendErrorSwal(err?.response?.data?.detail ?? this.$t("Unable to download the paycheck zip, please try again later"));
        console.log(err);
      })
    },
    
    async getPassports() {
      try {
        const res = await PhysicalPersonPassportService.getAll(this.physicalPerson.id, { perPage: 100, page: 1, sortBy: "valid_to" });
        this.passportCount = res.count;
        this.passportsData = res.results;
      } catch (err) {
        backendErrorSwal(err?.response?.data?.detail, err);
      }
    },

    titleEditButton(isAuthorized) {
      return !isAuthorized ? this.$t("You do not have these permissions") : this.$t("Edit")
    },

    titleDownloadButton(isAuthorized) {
      return !isAuthorized ? this.$t("You do not have permission to download attachment") : this.$t("Download the documents")
    },

    titleDownloadBackButton(isAuthorized) {
      return !isAuthorized ? this.$t("You do not have permission to download attachment") : this.$t("Download back of the document")
    },

    titleDeleteButton(isAuthorized) {
      return !isAuthorized ? this.$t("You do not have these permissions") : this.$t("Delete")
    },
    
    hasPermission(permission_name) {
      if (this.isApStaff) {
        return true;
      }
      if (!this.getCurrentManagedCompany) {
        return false;
      }
      if (this.physicalPerson.id === this.getPersonaId) {
        return true;
      }
      if (this.isAfterSale) {
        return false;
      }
      return this.getCurrentManagedCompany[permission_name];
    }
  },
};
</script>

<style scoped>
.create-button {
  text-align: left;
  width: 100%;
  font-weight: 400;
  background: white;
  border: none;
  border-radius: 0;
}

.create-button:hover {
  background-color: #F3F6F9 !important;
}

.create-button.disabled-btn {
  background-color: #E4E6EF !important;
  border-color: #E4E6EF !important;
  opacity: 0.5;
}

.create-button.disabled-btn.navi-link .navi-text,
.create-button.disabled-btn.navi-link:hover .navi-text {
  color: #3F4254 !important;
}

.create-button.disabled-btn.navi-link .navi-icon i,
.create-button.disabled-btn.navi-link:hover .navi-icon i {
  color: #B5B5C3 !important;
  /* opacity: 0.5; */
}

.create-button::v-deep button {
  width: 100%;
  text-align: left;
}

::v-deep .dropdown-menu {
  max-height: 325px;
  overflow-y: auto;
}

::v-deep .dropdown-item {
  padding: 5px;
}

::v-deep .btn.btn-outline-light {
  color: #fff;
}

.btn.btn-outline-light i::before {
  color: #fff;
}

.btn.btn-outline-light span {
  color: white;
}

.btn.btn-outline-light:hover span,
.btn.btn-outline-light:hover i::before,
.btn.btn-outline-light:active span,
.btn.btn-outline-light.dropdown-toggle[aria-expanded="true"] span,
.btn.btn-outline-light.dropdown-toggle[aria-expanded="true"] i::before,
.btn.btn-outline-light:active i::before {
  color: var(--primary);
}
</style>
