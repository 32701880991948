<template>
  <div class="form row">
    <div class="col-12">
      <validation-provider v-slot="{ errors }" :name="$t('Country')" vid="country" rules="required">
        <euro-select ref="countryMultiselect" v-model="form.country" :label="`${$t('CCIAA Nation')}*`"
          :error-messages="errors" :options="countriesSelectable" searchable></euro-select>
      </validation-provider>
    </div>
    <div class="col-6">
      <validation-provider v-slot="{ errors }" vid="from_date" :name="$t('From Date')">
        <date-picker v-model="form.from_date" :overrides="fromDateOverrides" :error-messages="errors"
          placeholder="YYYY-MM-DD" :label="`${$t('From Date')}`">
        </date-picker>
      </validation-provider>
    </div>
    <div class="col-6">
      <validation-provider v-slot="{ errors }" vid="to_date" :name="$t('To Date')">
        <date-picker v-model="form.to_date" :overrides="toDateOverrides" :error-messages="errors"
          :label="$t('To Date')">
        </date-picker>
      </validation-provider>
    </div>
    <div class="col-12">
      <validation-provider v-slot="{ errors }" vid="emission_date" :name="$t('Emission Date')" rules="required">
        <date-picker v-model="form.emission_date" :overrides="emissionDateOverrides" :error-messages="errors"
          placeholder="YYYY-MM-DD" :label="`${$t('Emission Date')}*`">
        </date-picker>
      </validation-provider>
    </div>
    <div class="col-12">
      <validation-provider v-slot="{ errors }" :name="$t('Registration Number')" vid="registration_number"
        rules="required">
        <euro-input v-model="form.registration_number" :label="`${$t('Registration Number')}*`"
          :error-messages="errors"></euro-input>
      </validation-provider>
    </div>

    <div class="col-12">
      <b-overlay :show="loading" rounded="sm">
        <div v-if="addressesByCountry.length > 0">
          <validation-provider v-slot="{ errors }" :name="$t('Company Address')" vid="address" rules="required">
            <euro-select v-model="form.address" :label="`${$t('Company Address')}*`" :error-messages="errors"
              :options="addressesSelectable" searchable></euro-select>
          </validation-provider>
        </div>
        <div v-else>
          <span class="mr-12">{{ $t("Add address for the selected country") }}*:</span>
          <router-link :to="{ name: 'detail-legal-address', params: { companyID: legalPerson.id, openModal: true } }"
            class="btn btn-light-primary font-weight-bolder">
            <span class="d-flex">
              <span class="svg-icon svg-icon-sm">
                <inline-svg src="/media/svg/icons/Communication/Address-card.svg" />
              </span>
              {{ $t("Add address") }}
            </span>
          </router-link>
        </div>
      </b-overlay>
    </div>

    <div class="col-12">
      <b-overlay :show="loading" rounded="sm">
        <div v-if="vatsByCountry.length > 0">
          <validation-provider v-slot="{ errors }" :name="$t('Vat Number')" vid="evat" rules="required">
            <euro-select v-model="form.evat" :label="`${$t('Vat Number')}*`" :error-messages="errors"
              :options="vatSelectable" searchable></euro-select>
          </validation-provider>
        </div>
        <div v-else class="mt-5 mb-5">
          <span class="mr-30">{{ $t("Add VAT for the selected country") }}*:</span>
          <router-link :to="{ name: 'detail-legal-vat', params: { companyID: legalPerson.id, openModal: true } }"
            class="btn btn-light-primary font-weight-bolder">
            <span class="d-flex">
              <span class="svg-icon svg-icon-sm">
                <inline-svg src="/media/svg/icons/Files/File.svg" />
              </span>
              {{ $t("Add VAT") }}
            </span>
          </router-link>
        </div>
      </b-overlay>
    </div>

    <div class="col-12">
      <validation-provider v-slot="{ errors }" :name="$t('Attachment')" vid="attachment_tus" class="w-100">
        <div id="attachmentFile">
          <euro-tus-file-input :label="`${$t('Attachment')}*`" :error-messages="errors" accept="application/pdf"
            :disabled="!form?.country || !form?.registration_number?.length || !form?.address || !form?.evat"
            :hint="$t('Accept .pdf - max size 20MB')" :attachment="existingForm.attachment"
            @change="onEuroTusFileInputChange"></euro-tus-file-input>
          <b-tooltip v-if="!form?.country || !form?.registration_number?.length || !form?.address || !form?.evat"
            target="attachmentFile">
            {{ $t("Enter the above required data first") }}
          </b-tooltip>
        </div>
      </validation-provider>
    </div>
  </div>
</template>

<script>
import DatePicker from "@/view/components/datePicker/datePicker.vue";

import moment from "moment";
import { mapState } from "vuex";
import { countryChangeSwal } from "@/core/helpers/swal";
import { getCompanyAddresses, getVats } from "@/core/abstractions/company";

const now = new Date();
export default {
  components: {
    DatePicker,
  },

  props: {
    existingForm: {
      type: Object,
      default: null,
    },
    legalPerson: {
      type: Object,
      default: null,
    },
    defaultCountry: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      maxDate: now,
      addresses: [],
      vatNumbers: [],
      form: {
        country: "",
        counterpart: "",
        registration_number: "",
        from_date: null,
        to_date: null,
        emission_date: null,
        attachment_tus: null,
        address: null,
        evat: "",
      },
      // Administrative structures
      companies: [],
      loading: false,
    };
  },

  computed: {
    ...mapState("constants", ["countries"]),

    toDateOverrides() {
      return this.form.from_date !== "Invalid date" ? { minDate: this.form.from_date } : null;
    },
    fromDateOverrides() {
      return this.form.from_date ? { maxDate: moment() } : null;
    },
    emissionDateOverrides() {
      return this.form.emission_date ? { maxDate: moment() } : null;
    },

    stringMaxDate() {
      return moment(this.maxDate, true).format("YYYY-MM-DD");
    },

    countriesSelectable() {
      return this.countries
        .map((x) => ({ text: this.$t(x.name), value: x.iso }))
        .sort((a, b) => a.text.localeCompare(b.text));
    },

    addressesByCountry() {
      if (!this.form.country) {
        return this.addresses;
      }

      return this.addresses.filter(el => el?.address?.locality?.state?.country?.code == this.form.country);
    },

    addressesSelectable() {
      return this.addressesByCountry.map(el => ({ value: el.id, text: el.address.formatted }));
    },

    vatsByCountry() {
      if (!this.form.country) {
        return this.vatNumbers;
      }

      return this.vatNumbers.filter(el => el.country === this.form.country);
    },

    vatSelectable() {
      return this.vatsByCountry.map(el => ({ value: el.id, text: el.vat }));
    },

    companiesByCountry() {
      if (!this.form.country) {
        return this.companies;
      }

      return this.companies.filter(el => el.country_of_establishment === this.form.country);
    },

    companySelectable() {
      return this.companiesByCountry.map(el => ({
        value: el.id,
        text: `${el.name} - ${el.country_of_establishment}`,
      }));
    },
  },

  watch: {
    form: {
      deep: true,
      handler(val) {
        this.$emit("input", val);
      },
    },

    defaultCountry: {
      immediate: true,
      handler(val) {
        if (val && !this.form.country) this.form.country = val;
      },
    },
    'form.country': {
      handler(newVal, oldVal) {
        //control the swal for dont do loop
        if (this.isChangingCountry) {
          this.isChangingCountry = false;
          return;
        }
        if (newVal !== oldVal && this.form.country !== this.existingForm.country && newVal !== null) {
          countryChangeSwal({
            text: this.$t("You are about to change the country, Are you sure?"),
          }).then(res => {
            if (!res.isConfirmed) {
              this.isChangingCountry = true;
              this.form.country = oldVal;
            }
            if (res.isConfirmed) {
              this.isChangingCountry = true;
              this.form.counterpart = "";
              this.form.address = null;
              this.form.evat = "";
            }
          });
        }
      },
      inmediate: true
    },
  },

  async mounted() {
    this.loading = true;
    if (this.existingForm) {
      this.form = { ...this.form, ...this.existingForm };
      this.form.address = this.existingForm.address ? this.existingForm.address.id : null;
      this.form.evat = this.existingForm.evat ? this.existingForm.evat.id : null;
    }

    const [vats, addresses] = await Promise.all([
      // disabled call to counterparts, currently not used, also don't call it without filters
      getVats({
        companyId: this.legalPerson?.id || this.existingForm?.company,
        paginated: false,
      }),
      getCompanyAddresses({
        companyId: this.legalPerson?.id || this.existingForm?.company,
        paginated: false,
      }),
    ]);

    // this.companies = companies;
    this.vatNumbers = vats.filter(el => el.is_active);
    this.addresses = addresses;
    this.loading = false;
  },

  methods: {
    onEuroTusFileInputChange(f) {
      this.form.attachment_tus = f;
    },
  },
};
</script>
