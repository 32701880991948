<template>
  <div>
    <div class="form row">
      <div class="col-6">
        <validation-provider v-slot="{ errors }" :name="$t('Country')" vid="country" rules="required">
          <euro-select v-model="form.country" :multiselect-props="{
            options: filteredCountries,
            label: 'name',
            'track-by': 'iso',
            searchable: true,
          }" :error-messages="errors" searchable :label="`${$t('Country')}*`">
          </euro-select>
        </validation-provider>
      </div>

      <div class="col-6">
        <validation-provider v-slot="{ errors }" :name="$t('Driving license number')" vid="number"
          rules="required|drivingLicenseNumber">
          <euro-input v-model="form.number" :label="`${$t('Driving license number')}*`"
            :error-messages="errors"></euro-input>
        </validation-provider>
      </div>

      <div class="col-6">
        <validation-provider v-slot="{ errors }" :name="$t('Driving license codes')" vid="license_code">
          <euro-select v-model="form.license_code" :label="$t('Driving license codes')" :error-messages="errors"
            :multiselect-props="{
              multiple: true,
              label: 'display_name',
              'track-by': 'value',
              options: drivingLicenseCodeKinds,
            }">
            <template #label>
              <i v-b-tooltip :title="$t('Select all that apply at n.12 of the EU driving license')"
                :class="icons.lineAwesome.info"></i>
            </template>
          </euro-select>
        </validation-provider>
      </div>

      <div v-if="isDuplicateNumberVisible" class="col-6">
        <validation-provider v-slot="{ errors }" :name="$t('Duplicate driving license number')" vid="duplicate_number"
          :rules="duplicateNumberRules">
          <euro-input :label="$t('Duplicate driving license number')" :error-messages="errors"
            :help-text="$t('Enter duplicate license number, mandatory when license is a duplicate')"></euro-input>
        </validation-provider>
      </div>

      <div class="col-12">
        <validation-provider v-slot="{ errors }" :name="$t('Attachment front')" vid="attachment_tus" class="w-100">
          <div id="attachmentFile">
            <euro-tus-file-input :label="`${$t('Attachment front')}*`" :placeholder="$t('Attachment')"
              :error-messages="errors" rules="required" accept="application/pdf" :disabled="!form?.number?.length"
              :hint="$t('Accept .pdf - max size 20MB')" :attachment="existingForm.attachment" @change="tusId => {
                form.attachment_tus = tusId;
              }
                "></euro-tus-file-input>
            <b-tooltip v-if="!form?.number?.length" target="attachmentFile">
              {{ $t("Enter the driving license number first") }}
            </b-tooltip>
          </div>
        </validation-provider>
      </div>
      <div class="col-12">
        <validation-provider v-slot="{ errors }" :name="$t('Attachment back')" vid="attachment_back_tus" class="w-100">
          <div class="row m-1 my-3">
            <span class="col- switch">
              <label>
                <input v-model="attachmentBack" type="checkbox" name="select" />
                <span></span>
              </label>
            </span>
            <label class="col- col-form-label">{{ $t('Add attachment back') }}
              <i v-b-tooltip
                :title="$t('Switch on for uploading front and back of the document separately. You can also combine front/back in a single file in the attachment field.')"
                :class="icons.lineAwesome.info">
              </i>
            </label>
          </div>

        </validation-provider>
      </div>
      <div v-if="attachmentBack" class="col-12">
        <validation-provider v-slot="{ errors }" :name="$t('Attachment')" vid="attachment_back_tus" class="w-100">
          <div id="attachmentFileBack">
            <euro-tus-file-input :label="$t('Attachment back')" :error-messages="errors" accept="application/pdf"
              :disabled="!form?.number?.length" :hint="$t('Accept .pdf - max size 20MB')"
              :attachment="existingForm.attachment_back" @change="tusId => {
                form.attachment_back_tus = tusId;
              }
                "></euro-tus-file-input>
            <b-tooltip v-if="!form?.number?.length" target="attachmentFileBack">
              {{ $t("Enter the above required data first") }}
            </b-tooltip>
          </div>
        </validation-provider>
      </div>

      <div class="col-12">
        <div class="separator separator-dashed my-5"></div>
      </div>

      <div class=" col-6">
        <div class="font-weight-bolder pb-4">
          {{ $t("Driving license categories") }}<br>
          <small>{{ $t("* Fill at least one driving license category") }}</small>
        </div>
      </div>
      <div class="col-6 text-right">
        <button :to="{ name: 'addNewForm' }" class="btn btn-light-primary font-weight-bolder" @click="addNewForm">
          <i class=" flaticon2-calendar-8 mr-2"></i>{{ $t("Add New") }}
        </button>
      </div>

      <div v-if="form.category_set.length" class="col-12">
        <validation-provider vid="category_set">
          <b-table small :items="form.category_set" :fields="[
            { label: $t('Category'), key: 'category' },
            { label: $t('Valid from'), key: 'date_from' },
            { label: $t('Valid to'), key: 'date_to' },
            { label: '', key: 'delete' },
          ]">
            <template #cell(category)="{ item }">
              <euro-select v-model="item.category" :options="getCategoriesSelected" class="col-10" />
            </template>
            <template #cell(date_from)="{ item }">
              <validation-provider v-slot="{ errors }" :name="$t('Valid from')"
                :vid="`category-${item.category}-valid-from`"
                :rules="{ required_if: [`category-${item.category}-valid-to`] }">
                <date-picker v-model="item.date_from" :error-messages="errors" label=""></date-picker>
              </validation-provider>
            </template>

            <template #cell(date_to)="{ item }">
              <validation-provider v-slot="{ errors }" :name="$t('Valid from')"
                :vid="`category-${item.category}-valid-to`"
                :rules="{ required_if: [`category-${item.category}-valid-from`] }">
                <date-picker v-model="item.date_to" :error-messages="errors" label="" :overrides="item.date_from
                  ? {
                    minDate: moment(item.date_from),
                  }
                  : null
                  "></date-picker>
              </validation-provider>
            </template>
            <template #cell(delete)="row">
              <b-button class="delete-button mb-7" variant="danger" @click="removeForm(row.item)">{{ $t('Delete')
              }}</b-button>
            </template>
          </b-table>
        </validation-provider>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from "@/view/components/datePicker/datePicker.vue";
import icons from "@/core/config/icons";
import { mapGetters, mapState } from "vuex";
import { getDrivingLicenseCodeKinds } from "@/core/abstractions/physicalPerson";
import moment from "moment";
export default {
  components: {
    DatePicker,
  },

  props: {
    existingForm: {
      type: Object,
      default: null,
    },
    physicalPerson: {
      type: Object,
      default: () => null,
    },
  },

  data() {
    return {
      icons,
      moment,
      administrativeStructures: [],
      categories: ["AM", "A1", "A2", "A", "B1", "B", "C1", "C", "D1", "D", "BE", "C1E", "Ce", "D1E", "DE"],
      attachmentBack: false,
      form: {
        country: "",
        number: "",
        license_code: [],
        duplicate_number: "",
        counterpart: null,
        category_set: [],
        attachment_tus: null,
        attachment_back_tus: ""
      },
      drivingLicenseCodeKinds: [],
    };
  },

  computed: {
    ...mapState("constants", ["countries"]),
    ...mapGetters("constants", ["europeanGroup"]),

    filteredCountries() {
      return this.countries.filter(country => this.europeanGroup.find(el => el.country == country.iso))
    },

    uid() {
      return this._uid;
    },

    administrativeStructuresByCountry() {
      if (!this.form.country) return this.administrativeStructures;

      return this.administrativeStructures.filter(as => as.country_of_establishment == this.form.country);
    },

    duplicateNumberRules() {
      if (this.form.license_code.includes("71")) {
        return { required: true };
      }
      return { required: false };
    },
    getCategoriesSelected() {
      const arrayOfObjects = this.categories.map(item => ({ value: item }));
      const selectedCategories = this.form.category_set.map(formCategory => formCategory.category);
      return arrayOfObjects.filter(category => !selectedCategories.includes(category)).map(category => {
        return { value: category.value, text: category.value }
      });
    },
    attachmentFrontLabel() {
      return this.attachmentBack ? this.$t('Attachment front') : this.$t('Attachment')
    },
    isDuplicateNumberVisible() {
      return this.form.license_code.includes("71");
    }
  },

  watch: {
    form: {
      deep: true,
      handler(val) {
        const out = { ...val };
        out.category_set = out.category_set.filter(category => category.date_from && category.date_to);
        this.$emit("input", out);
      },
    },
    attachmentBack() {
      if (!this.attachmentBack) {
        this.form.attachment_back_tus = null
      }
    },
  },

  created() {
    getDrivingLicenseCodeKinds().then(res => {
      this.drivingLicenseCodeKinds = res.map(item => {
        return { ...item, display_name: `(${item.value}) ${item.display_name}` };
      });
    });
  },

  mounted() {
    if (this.existingForm) {
      this.form = { ...this.form, ...this.existingForm };
      this.attachmentBack = this.form.attachment_back ? true : false;
    }

    this.form.category_set = this.form.category_set.filter((formCategory) =>
      this.categories.includes(formCategory.category));

    if (this.form.category_set.length == 0) {
      this.addNewForm();
    }
  },
  methods: {
    addNewForm() {
      const categoriesSelected = this.getCategoriesSelected;
      if (categoriesSelected.length > 0) {
        this.form.category_set.push({
          date_from: null,
          date_to: null,
          category: null,
        });
      }
    },
    removeForm(formCategory) {
      const index = this.form.category_set.indexOf(formCategory);
      if (index !== -1) {
        this.form.category_set.splice(index, 1);
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.collapsed {
  & i {
    transform: rotate(0deg);
    transition: transform 0.2s linear;
  }
}

.not-collapsed {
  & i {
    transform: rotate(180deg);
    transition: transform 0.2s linear;
  }
}

.text-small {
  font-size: 0.8rem;
}
</style>
