var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "card card-custom"
  }, [_c('div', {
    staticClass: "card-header border-0 pt-6 pb-0"
  }, [_c('div', {
    staticClass: "card-title align-items-start flex-column"
  }, [_c('h3', {
    staticClass: "card-label font-weight-bolder"
  }, [_vm._v(" " + _vm._s(_vm.$t("Documents")) + " ")]), _c('span', {
    staticClass: "text-muted font-weight-bold font-size-sm mt-1"
  }, [_vm._v(_vm._s(_vm.$t("Update your documents")))])]), _c('div', {
    staticClass: "card-toolbar"
  }, [_c('euro-checkbox', {
    staticClass: "mr-6 mt-5",
    attrs: {
      "text": _vm.$t('Expiring'),
      "size": "lg",
      "is-switch": ""
    },
    on: {
      "input": function input($event) {
        return _vm.$refs.table.refresh();
      }
    },
    model: {
      value: _vm.expiring,
      callback: function callback($$v) {
        _vm.expiring = $$v;
      },
      expression: "expiring"
    }
  }), _c('euro-select', {
    staticClass: "min-w-200px mr-4 mb-0",
    attrs: {
      "multiselect-props": _vm.multiselectProps
    },
    on: {
      "input": function input($event) {
        return _vm.$refs.table.refresh();
      }
    },
    model: {
      value: _vm.documentType,
      callback: function callback($$v) {
        _vm.documentType = $$v;
      },
      expression: "documentType"
    }
  }), !_vm.buttonDisabled && !_vm.loading ? _c('b-dropdown', {
    attrs: {
      "no-caret": "",
      "right": "",
      "no-flip": "",
      "text": "Document",
      "variant": "light-primary",
      "toggle-class": "font-weight-bolder py-3"
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function fn() {
        return [_c('div', {
          staticClass: "dropdown-button"
        }, [_c('span', {
          staticClass: "svg-icon menu-icon"
        }, [_c('inline-svg', {
          attrs: {
            "src": "/media/svg/icons/Files/File.svg"
          }
        })], 1), _c('span', {}, [_vm._v(_vm._s(_vm.$t("Add document")))])])];
      },
      proxy: true
    }], null, false, 1254096896)
  }, [_c('div', {
    staticClass: "navi navi-hover min-w-md-250px"
  }, [_c('b-dropdown-text', {
    staticClass: "navi-item",
    attrs: {
      "tag": "div"
    }
  }, [_c('b-button', {
    staticClass: "create-button navi-link",
    on: {
      "click": function click($event) {
        return _vm.openModal('physical-fiscal-create-modal');
      }
    }
  }, [_c('span', {
    staticClass: "navi-icon"
  }, [_c('i', {
    staticClass: "fas fa-plus"
  })]), _c('span', {
    staticClass: "navi-text"
  }, [_vm._v(" " + _vm._s(_vm.$t("Fiscal Code")) + " ")])]), _c('b-button', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      value: !_vm.hasPermission('identity_document_permission') ? _vm.$t('You are not authorized to upload this document') : null,
      expression: "!hasPermission('identity_document_permission') ? $t('You are not authorized to upload this document') : null",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "create-button navi-link",
    class: {
      'disabled-btn': !_vm.hasPermission('identity_document_permission')
    },
    on: {
      "click": function click($event) {
        !_vm.hasPermission('identity_document_permission') ? null : _vm.openModal('create-passport-modal');
      }
    }
  }, [_c('span', {
    staticClass: "navi-icon"
  }, [_c('i', {
    staticClass: "fas fa-plus"
  })]), _c('span', {
    staticClass: "navi-text"
  }, [_vm._v(" " + _vm._s(_vm.$t("Passport")) + " ")])]), _c('b-button', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      value: !_vm.hasValidPassport ? _vm.$t('Please add a valid passport first') : null,
      expression: "!hasValidPassport ? $t('Please add a valid passport first') : null",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "create-button navi-link",
    class: {
      'disabled-btn': !_vm.hasValidPassport
    },
    on: {
      "click": function click($event) {
        _vm.hasValidPassport ? _vm.openModal('create-visa-modal') : null;
      }
    }
  }, [_c('span', {
    staticClass: "navi-icon"
  }, [_c('i', {
    staticClass: "fas fa-plus"
  })]), _c('span', {
    staticClass: "navi-text"
  }, [_vm._v(" " + _vm._s(_vm.$t("Visa")) + " ")])]), _c('b-button', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      value: !_vm.hasPermission('identity_document_permission') ? _vm.$t('You are not authorized to upload this document') : null,
      expression: "!hasPermission('identity_document_permission') ? $t('You are not authorized to upload this document') : null",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "create-button navi-link",
    class: {
      'disabled-btn': !_vm.hasPermission('identity_document_permission')
    },
    on: {
      "click": function click($event) {
        !_vm.hasPermission('identity_document_permission') ? null : _vm.openModal('create-identify-modal');
      }
    }
  }, [_c('span', {
    staticClass: "navi-icon"
  }, [_c('i', {
    staticClass: "fas fa-plus"
  })]), _c('span', {
    staticClass: "navi-text"
  }, [_vm._v(" " + _vm._s(_vm.$t("ID document")) + " ")])]), _c('b-button', {
    staticClass: "create-button navi-link",
    on: {
      "click": function click($event) {
        return _vm.openModal('create-language-modal');
      }
    }
  }, [_c('span', {
    staticClass: "navi-icon"
  }, [_c('i', {
    staticClass: "fas fa-plus"
  })]), _c('span', {
    staticClass: "navi-text"
  }, [_vm._v(" " + _vm._s(_vm.$t("Language")) + " ")])]), _c('b-button', {
    staticClass: "create-button navi-link",
    on: {
      "click": function click($event) {
        return _vm.openModal('create-education-modal');
      }
    }
  }, [_c('span', {
    staticClass: "navi-icon"
  }, [_c('i', {
    staticClass: "fas fa-plus"
  })]), _c('span', {
    staticClass: "navi-text"
  }, [_vm._v(" " + _vm._s(_vm.$t("Education")) + " ")])]), _c('b-button', {
    staticClass: "create-button navi-link",
    on: {
      "click": function click($event) {
        return _vm.openModal('create-vat-modal');
      }
    }
  }, [_c('span', {
    staticClass: "navi-icon"
  }, [_c('i', {
    staticClass: "fas fa-plus"
  })]), _c('span', {
    staticClass: "navi-text"
  }, [_vm._v(" " + _vm._s(_vm.$t("VAT")) + " ")])]), _c('b-button', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      value: !_vm.hasPermission('certificate_suitability_for_work_permission') ? _vm.$t('You are not authorized to upload this document') : null,
      expression: "!hasPermission('certificate_suitability_for_work_permission') ? $t('You are not authorized to upload this document') : null",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "create-button navi-link",
    class: {
      'disabled-btn': !_vm.hasPermission('certificate_suitability_for_work_permission')
    },
    on: {
      "click": function click($event) {
        !_vm.hasPermission('certificate_suitability_for_work_permission') ? null : _vm.openModal('create-suitability-work');
      }
    }
  }, [_c('span', {
    staticClass: "navi-icon"
  }, [_c('i', {
    staticClass: "fas fa-plus"
  })]), _c('span', {
    staticClass: "navi-text"
  }, [_vm._v(" " + _vm._s(_vm.$t("Certificate of suitability of work")) + " ")])]), _c('b-button', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      value: !_vm.hasIndividualCompany ? _vm.$t("A VAT number is require to add this document") : null,
      expression: "!hasIndividualCompany ? $t(\"A VAT number is require to add this document\") : null",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "create-button navi-link",
    class: {
      'disabled-btn': !_vm.hasIndividualCompany
    },
    on: {
      "click": function click($event) {
        !_vm.hasIndividualCompany ? null : _vm.openModal('create-registration-certificate-modal');
      }
    }
  }, [_c('span', {
    staticClass: "navi-icon"
  }, [_c('i', {
    staticClass: "fas fa-plus"
  })]), _c('span', {
    staticClass: "navi-text"
  }, [_vm._v(" " + _vm._s(_vm.$t("National CCIAA Certificate")) + " ")])]), _c('b-button', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      value: !_vm.hasIndividualCompany ? _vm.$t("A VAT number is require to add this document") : null,
      expression: "!hasIndividualCompany ? $t(\"A VAT number is require to add this document\") : null",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "create-button navi-link",
    class: {
      'disabled-btn': !_vm.hasIndividualCompany
    },
    on: {
      "click": function click($event) {
        !_vm.hasIndividualCompany ? null : _vm.openModal('create-ins-third-party-modal');
      }
    }
  }, [_c('span', {
    staticClass: "navi-icon"
  }, [_c('i', {
    staticClass: "fas fa-plus"
  })]), _c('span', {
    staticClass: "navi-text"
  }, [_vm._v(" " + _vm._s(_vm.$t("Insurance - Third Party")) + " ")])]), _c('b-button', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      value: !_vm.hasPermission('identity_document_permission') ? _vm.$t('You are not authorized to upload this document') : null,
      expression: "!hasPermission('identity_document_permission') ? $t('You are not authorized to upload this document') : null",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "create-button navi-link",
    class: {
      'disabled-btn': !_vm.hasPermission('identity_document_permission')
    },
    on: {
      "click": function click($event) {
        !_vm.hasPermission('identity_document_permission') ? null : _vm.openModal('create-eu-health-modal');
      }
    }
  }, [_c('span', {
    staticClass: "navi-icon"
  }, [_c('i', {
    staticClass: "fas fa-plus"
  })]), _c('span', {
    staticClass: "navi-text"
  }, [_vm._v(" " + _vm._s(_vm.$t("EU health insurance")) + " ")])]), _c('b-button', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      value: !_vm.hasPermission('posting_certificate_permission') ? _vm.$t('You are not authorized to upload this document') : null,
      expression: "!hasPermission('posting_certificate_permission') ? $t('You are not authorized to upload this document') : null",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "create-button navi-link",
    class: {
      'disabled-btn': !_vm.hasPermission('posting_certificate_permission')
    },
    on: {
      "click": function click($event) {
        !_vm.hasPermission('posting_certificate_permission') ? null : _vm.openModal('create-a-module');
      }
    }
  }, [_c('span', {
    staticClass: "navi-icon"
  }, [_c('i', {
    staticClass: "fas fa-plus"
  })]), _c('span', {
    staticClass: "navi-text"
  }, [_vm._v(" " + _vm._s(_vm.$t("A1 certificate")) + " ")])]), _c('b-button', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      value: !_vm.hasPermission('identity_document_permission') ? _vm.$t('You are not authorized to upload this document') : null,
      expression: "!hasPermission('identity_document_permission') ? $t('You are not authorized to upload this document') : null",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "create-button navi-link",
    class: {
      'disabled-btn': !_vm.hasPermission('identity_document_permission')
    },
    on: {
      "click": function click($event) {
        !_vm.hasPermission('identity_document_permission') ? null : _vm.openModal('create-eu-driving-modal');
      }
    }
  }, [_c('span', {
    staticClass: "navi-icon"
  }, [_c('i', {
    staticClass: "fas fa-plus"
  })]), _c('span', {
    staticClass: "navi-text"
  }, [_vm._v(" " + _vm._s(_vm.$t("EU driving license")) + " ")])]), _c('b-button', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "create-button navi-link",
    class: {
      'disabled-btn': _vm.isResidencePermitCreationDisabled || !_vm.hasPermission('identity_document_permission')
    },
    attrs: {
      "title": _vm.isResidencePermitCreationDisabled ? _vm.$t("Disabled as citizen of EU") : !_vm.hasPermission("identity_document_permission") ? null : _vm.$t("You are not authorized to upload this document")
    },
    on: {
      "click": function click($event) {
        _vm.isResidencePermitCreationDisabled || !_vm.hasPermission('identity_document_permission') ? null : _vm.openModal('create-permit-modal');
      }
    }
  }, [_c('span', {
    staticClass: "navi-icon"
  }, [_c('i', {
    staticClass: "fas fa-plus"
  })]), _c('span', {
    staticClass: "navi-text"
  }, [_vm._v(" " + _vm._s(_vm.$t("Residence permit")) + " ")])]), _c('b-button', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "create-button navi-link",
    class: {
      'disabled-btn': !_vm.hasIndividualCompany
    },
    attrs: {
      "title": !_vm.hasIndividualCompany ? _vm.$t("A VAT number is require to add this document") : null
    },
    on: {
      "click": function click($event) {
        !_vm.hasIndividualCompany ? null : _vm.openModal('create-social-security-modal');
      }
    }
  }, [_c('span', {
    staticClass: "navi-icon"
  }, [_c('i', {
    staticClass: "fas fa-plus"
  })]), _c('span', {
    staticClass: "navi-text"
  }, [_vm._v(" " + _vm._s(_vm.$t("Social Security Office Registration")) + " ")])]), _vm.physicalPerson.is_employee ? _c('b-button', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      value: !_vm.hasPermission('paycheck_permission') ? _vm.$t('You are not authorized to upload this document') : null,
      expression: "!hasPermission('paycheck_permission') ? $t('You are not authorized to upload this document') : null",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "create-button navi-link",
    class: {
      'disabled-btn': !_vm.hasPermission('paycheck_permission')
    },
    on: {
      "click": function click($event) {
        _vm.openModal(!_vm.hasPermission('paycheck_permission') ? null : 'create-paycheck-modal');
      }
    }
  }, [_c('span', {
    staticClass: "navi-icon"
  }, [_c('i', {
    staticClass: "fas fa-plus"
  })]), _c('span', {
    staticClass: "navi-text"
  }, [_vm._v(" " + _vm._s(_vm.$t("Employee paycheck")) + " ")])]) : _vm._e(), _vm.physicalPerson.is_employee ? [!_vm.hasPermission('contract_permission') ? _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      value: _vm.$t('You are not authorized to upload this document'),
      expression: "$t('You are not authorized to upload this document')",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "create-button navi-link disabled-btn"
  }, [_c('span', {
    staticClass: "navi-icon"
  }, [_c('i', {
    staticClass: "fas fa-plus"
  })]), _c('span', {
    staticClass: "navi-text"
  }, [_vm._v(" " + _vm._s(_vm.$t("Employement contract")) + " ")])]) : _c('router-link', {
    staticClass: "create-button navi-link",
    attrs: {
      "to": {
        name: 'associate-contract',
        params: {
          ppId: _vm.physicalPerson.id
        },
        query: {
          redirect: this.$route.fullPath
        }
      },
      "icon": "Layout/Layout-top-panel-6.svg"
    }
  }, [_c('span', {
    staticClass: "navi-icon"
  }, [_c('i', {
    staticClass: "fas fa-plus"
  })]), _c('span', {
    staticClass: "navi-text"
  }, [_vm._v(" " + _vm._s(_vm.$t("Employement contract")) + " ")])])] : _vm._e()], 2)], 1)]) : _vm._e()], 1)]), _c('div', {
    staticClass: "card-body detail"
  }, [_c('datatable', {
    ref: "table",
    attrs: {
      "table-props": _vm.tableProps,
      "total": _vm.total,
      "options": _vm.tableOptions,
      "per-page-options": _vm.perPageOptions
    },
    on: {
      "update:options": function updateOptions($event) {
        _vm.tableOptions = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "cell.show_more",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [row.item.document_type == 'PayCheck' && row.item.data.secondments.length > 0 ? _c('div', [_c('i', {
          staticClass: "pointer",
          class: [row.detailsShowing ? _vm.icons.caretUp : _vm.icons.caretDown],
          on: {
            "click": row.toggleDetails
          }
        })]) : _vm._e()];
      }
    }, {
      key: "row-details",
      fn: function fn(item) {
        return [item.row.item.document_type == 'PayCheck' ? _c('div', [_c('b-table', {
          attrs: {
            "items": item.row.item.data.secondments,
            "fields": _vm.table.nestedFields
          },
          scopedSlots: _vm._u([{
            key: "cell(country)",
            fn: function fn(_ref2) {
              var item = _ref2.item;
              return [_c('div', {
                staticClass: "font-weight-normal"
              }, [_c('country-flag', {
                attrs: {
                  "country-iso": item.country
                }
              })], 1)];
            }
          }, {
            key: "cell(client)",
            fn: function fn(_ref3) {
              var item = _ref3.item;
              return _vm._l(item.clients, function (client) {
                return _c('div', {
                  key: client.id,
                  staticClass: "d-flex align-items-center"
                }, [_c('Avatar', {
                  staticClass: "mr-2",
                  attrs: {
                    "avatar-image": client.avatar,
                    "avatar-text": client.name
                  }
                }), _c('div', {
                  staticClass: "d-flex flex-column"
                }, [_c('span', [_vm._v(_vm._s(client.name))])])], 1);
              });
            }
          }, {
            key: "cell(date_start)",
            fn: function fn(_ref4) {
              var item = _ref4.item;
              return [_vm._v(" " + _vm._s(item.date_start ? _vm.DateService.format(item.date_start, _vm.dateFormat) : "") + " ")];
            }
          }, {
            key: "cell(date_end)",
            fn: function fn(_ref5) {
              var item = _ref5.item;
              return [_vm._v(" " + _vm._s(item.date_end ? _vm.DateService.format(item.date_end, _vm.dateFormat) : "") + " ")];
            }
          }, {
            key: "cell(actions)",
            fn: function fn(_ref6) {
              var item = _ref6.item;
              return [_c('router-link', {
                staticClass: "btn btn-icon btn-light btn-hover-primary btn-sm",
                attrs: {
                  "to": {
                    name: 'detail-secondment',
                    params: {
                      secondmentId: item.id
                    }
                  }
                }
              }, [_c('span', {
                staticClass: "svg-icon svg-icon-md svg-icon-primary"
              }, [_c('inline-svg', {
                attrs: {
                  "src": "/media/svg/icons/Navigation/Right-2.svg"
                }
              })], 1)])];
            }
          }], null, true)
        })], 1) : _vm._e()];
      }
    }, {
      key: "cell.document_type",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_c('span', {
          class: "font-weight-bold label label-inline label-light-".concat(_vm.docTypes[item.document_type].labelColor)
        }, [_vm._v(" " + _vm._s(_vm.docTypes[item.document_type].typeName) + " ")])];
      }
    }, {
      key: "cell.name",
      fn: function fn(_ref8) {
        var _item$data;
        var item = _ref8.item;
        return [_c('div', {
          staticClass: "text-dark-75 font-weight-bolder font-size-lg w-40"
        }, [_vm._v(" " + _vm._s(_vm.docTypes[item.document_type].name(item)) + " ")]), item.document_type == 'PostingCertificate' ? _vm._l(item.posting_country, function (iso, idx) {
          return _c('span', {
            key: idx,
            staticClass: "label label-inline label-light-success font-weight-bolder mr-2 mb-2"
          }, [_vm._v(" " + _vm._s(_vm.countriesMap[iso].name) + " ")]);
        }) : _vm._e(), item.document_type == 'CertificateOfSuitabilityForWork' ? void 0 : _vm._e(), item.document_type == 'EducationLevel' && item.attainment_date != undefined ? [_c('span', {
          staticClass: "text-muted text-size-sm"
        }, [_vm._v(" " + _vm._s(_vm.$t("Attainment date") + ": " + _vm.formattedDate(item === null || item === void 0 ? void 0 : item.attainment_date, _vm.dateFormat)) + " ")])] : _vm._e(), item.document_type == 'FiscalID' ? [!_vm.isExpired(item) ? [_c('span', {
          staticClass: "label label-success label-dot mr-2"
        }), _c('span', {
          staticClass: "font-weight-bold text-success"
        }, [_vm._v(" " + _vm._s(_vm.$t("Valid")) + " ")])] : [_c('span', {
          staticClass: "label label-danger label-dot mr-2"
        }), _c('span', {
          staticClass: "font-weight-bold text-danger"
        }, [_vm._v(" " + _vm._s(_vm.$t("Expired")) + " ")])]] : _vm._e(), item.document_type == 'Passport' && (_item$data = item.data) !== null && _item$data !== void 0 && _item$data.date_of_issue ? [_c('div', {
          staticClass: "text-muted text-size-sm"
        }), _c('div', {
          staticClass: "text-muted text-size-sm"
        })] : _vm._e(), item.document_type == 'ResidencePermit' ? [item.is_authorized ? _c('div', {
          staticClass: "text-muted text-size-sm"
        }, [_vm._v(" " + _vm._s(_vm.$t("Number") + ": " + item.label) + " ")]) : _vm._e()] : _vm._e(), item.document_type == 'EmployeeContract' ? [_c('span', {
          staticClass: "label label-inline label-light-success font-weight-bolder mr-2 mt-2"
        }, [_vm._v(" " + _vm._s(item.data.country.name) + " ")]), _c('span', {
          staticClass: "text-muted text-size-sm mt-2"
        }, [_vm._v(" " + _vm._s(item.data.national_contract_level) + " ")])] : _vm._e(), item.document_type == 'PayCheck' && item.data.description != '' ? [_c('div', {
          staticClass: "text-muted text-size-sm"
        }, [_vm._v(" " + _vm._s(_vm.$t("Description") + ": " + item.data.description) + " ")])] : _vm._e(), item.document_type === 'IdentityDocument' && item.is_authorized ? [_c('div', {
          staticClass: "text-muted text-size-sm"
        }, [_vm.isUnionEuropeanGroup(item) && item.data.valid_for_travel ? _c('span', {
          staticClass: "label label-inline label-light-success font-weight-bolder mr-2 mb-2",
          attrs: {
            "title": _vm.$t('Valid for travel in the European Union')
          }
        }, [_vm._v(" EU: " + _vm._s(_vm.$t("VALID TRAVEL")))]) : _c('span', {
          staticClass: "label label-inline label-light-danger font-weight-bolder mr-2 mb-2",
          attrs: {
            "title": _vm.$t('NOT Valid for travel in the European Union')
          }
        }, [_vm._v(" EU: " + _vm._s(_vm.$t("NOT VALID TRAVEL")) + " ")]), _c('div', [_vm._v(_vm._s(_vm.$t("Release") + ": " + _vm.formattedDate(item.date_start, _vm.dateFormat) + " "))])])] : _vm._e(), item.document_type == 'EuropeanDrivingLicense' && item.is_authorized ? [_c('div', {
          staticClass: "text-muted text-size-sm"
        }, _vm._l(item.data.category_set, function (categoryItem, index) {
          return _c('span', {
            key: index,
            staticClass: "font-weight-bold"
          }, [_c('span', {
            staticClass: "text-success"
          }, [_vm._v(" " + _vm._s(_vm.$t(categoryItem.category + ":")) + " ")]), _c('span', [_c('span', [_vm._v(_vm._s(_vm.$t("Expire date") + " - " + _vm.formattedDate(categoryItem.date_to, _vm.dateFormat)))])]), _c('br')]);
        }), 0)] : _vm._e(), item.document_type == 'EuropeanHealthInsuranceCard' && item.is_authorized ? [_c('div', {
          staticClass: "text-muted text-size-sm"
        }, [_vm._v(" " + _vm._s(_vm.$t("Card number") + ": " + item.data.card_identification_number) + " ")]), _c('div', {
          staticClass: "text-muted text-size-sm"
        }, [_vm._v(" " + _vm._s(_vm.$t("Institution number") + ": " + item.data.institution_identification_number) + " ")])] : _vm._e(), item.document_type == 'InsuranceCertificate' ? [_c('div', {
          staticClass: "text-muted text-size-sm"
        }, [_vm._v(" " + _vm._s(_vm.$t("Counterpart") + ": " + item.data.counterpart) + " ")])] : _vm._e(), item.document_type == 'KnownLanguage' ? [_c('span', {
          staticClass: "text-muted text-size-sm mr-2"
        }, [_vm._v(_vm._s(item.level))]), item.data.certification ? [_c('span', [_c('span', {
          staticClass: "label label-success label-dot mr-2"
        }), _c('span', {
          staticClass: "font-weight-bold text-success"
        }, [_vm._v(_vm._s(_vm.$t("Certified")))])])] : [_c('span', {
          staticClass: "label label-danger label-dot mr-2"
        }), _c('span', {
          staticClass: "font-weight-bold text-danger"
        }, [_vm._v(_vm._s(_vm.$t("Not Certificated")))])]] : _vm._e(), item.document_type == 'CompanyRegistrationCertificate' ? [_c('div', {
          staticClass: "text-muted text-size-sm"
        }, [_vm._v(" " + _vm._s(_vm.$t("Emission date") + ": " + _vm.formattedDate(item.data.emission_date, _vm.dateFormat)) + " ")])] : _vm._e()];
      }
    }, {
      key: "cell.date_start",
      fn: function fn(_ref9) {
        var item = _ref9.item;
        return [_c('span', {
          staticClass: "font-weight-bolder text-muted"
        }, [_vm._v(_vm._s(_vm.formattedDate(item.date_start, _vm.dateFormat)))])];
      }
    }, {
      key: "cell.date_end",
      fn: function fn(_ref10) {
        var item = _ref10.item;
        return [_c('span', {
          staticClass: "font-weight-bolder text-muted"
        }, [_vm._v(_vm._s(_vm.formattedDate(item.date_end, _vm.dateFormat)))])];
      }
    }, {
      key: "cell.actions",
      fn: function fn(_ref11) {
        var item = _ref11.item;
        return [_c('div', {
          staticClass: "d-flex"
        }, [item.document_type != 'PayCheck' ? _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "btn btn-icon btn-light btn-hover-primary btn-sm mr-2",
          class: {
            disabled: !item.attachment
          },
          attrs: {
            "title": _vm.titleDownloadButton(item.is_authorized),
            "disabled": !item.attachment
          },
          on: {
            "click": function click($event) {
              item.is_authorized ? _vm.download(item) : null;
            }
          }
        }, [_c('span', {
          staticClass: "svg-icon svg-icon-md",
          class: {
            'svg-icon-primary': item.attachment
          }
        }, [_c('inline-svg', {
          attrs: {
            "src": "/media/svg/icons/General/Clip.svg"
          }
        })], 1)]) : _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "btn btn-icon btn-light btn-hover-primary btn-sm mr-2",
          class: {
            disabled: !item.attachment
          },
          attrs: {
            "title": _vm.titleDownloadButton(item.is_authorized),
            "disabled": !item.attachment
          },
          on: {
            "click": function click($event) {
              item.is_authorized ? _vm.downloadZip(item.id) : null;
            }
          }
        }, [_c('span', {
          staticClass: "svg-icon svg-icon-md",
          class: {
            'svg-icon-primary': item.attachment
          }
        }, [_c('inline-svg', {
          attrs: {
            "src": "/media/svg/icons/General/Clip.svg"
          }
        })], 1)]), item.attachment_back ? _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "btn btn-icon btn-light btn-hover-primary btn-sm mr-2",
          class: {
            disabled: !item.attachment_back
          },
          attrs: {
            "title": _vm.titleDownloadBackButton(item.is_authorized),
            "disabled": !item.attachment_back
          },
          on: {
            "click": function click($event) {
              item.is_authorized ? _vm.downloadBack(item) : null;
            }
          }
        }, [_c('span', {
          staticClass: "svg-icon svg-icon-md",
          class: {
            'svg-icon-primary': item.attachment_back
          }
        }, [_c('inline-svg', {
          attrs: {
            "src": "/media/svg/icons/General/Clip.svg"
          }
        })], 1)]) : _vm._e(), _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "btn btn-icon btn-light btn-hover-primary btn-sm mr-2",
          class: {
            disabled: !item.is_authorized
          },
          attrs: {
            "title": _vm.titleEditButton(item.is_authorized),
            "disabled": !item.is_authorized
          },
          on: {
            "click": function click($event) {
              item.is_authorized ? _vm.onEditBtnClick(item, _vm.docTypes[item.document_type].modal) : null;
            }
          }
        }, [_c('span', {
          staticClass: "svg-icon svg-icon-md",
          class: {
            'svg-icon-primary': item.is_authorized
          }
        }, [_c('inline-svg', {
          attrs: {
            "src": "/media/svg/icons/Design/Edit.svg"
          }
        })], 1)]), _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "btn btn-icon btn-light btn-hover-primary btn-sm",
          class: {
            disabled: !item.is_authorized
          },
          attrs: {
            "title": _vm.titleDeleteButton(item.is_authorized),
            "disabled": !item.is_authorized
          },
          on: {
            "click": function click($event) {
              item.is_authorized ? _vm.onDeleteBtnClick(item) : null;
            }
          }
        }, [_c('span', {
          staticClass: "svg-icon svg-icon-md",
          class: {
            'svg-icon-primary': item.is_authorized
          }
        }, [_c('inline-svg', {
          attrs: {
            "src": "/media/svg/icons/General/Trash.svg"
          }
        })], 1)])], 1)];
      }
    }], null, true)
  })], 1)]), _c('PhysicalPersonCreateFiscalModal', {
    attrs: {
      "existing-form": _vm.editForm,
      "physical-person": _vm.physicalPerson,
      "countries": _vm.countries,
      "existing-countries": _vm.currentFiscalIdCountryList
    },
    on: {
      "refresh": function refresh($event) {
        return _vm.$refs.table.refresh();
      }
    }
  }), _c('PhysicalPersonCreatePassportModal', {
    attrs: {
      "existing-form": _vm.editForm,
      "physical-person": _vm.physicalPerson,
      "countries": _vm.countries,
      "languages": _vm.languages
    },
    on: {
      "refresh": function refresh($event) {
        return _vm.$refs.table.refresh();
      }
    }
  }), _c('PhysicalPersonCreateVisaModal', {
    attrs: {
      "existing-form": _vm.editForm,
      "physical-person": _vm.physicalPerson,
      "countries": _vm.countries,
      "passports": _vm.passportsData,
      "languages": _vm.languages
    },
    on: {
      "refresh": function refresh($event) {
        return _vm.$refs.table.refresh();
      }
    }
  }), _c('PhysicalPersonCreateIdentityModal', {
    attrs: {
      "existing-form": _vm.editForm,
      "physical-person": _vm.physicalPerson,
      "countries": _vm.countries,
      "languages": _vm.languages
    },
    on: {
      "refresh": function refresh($event) {
        return _vm.$refs.table.refresh();
      }
    }
  }), _c('PhysicalPersonCreateLanguageModal', {
    attrs: {
      "existing-form": _vm.editForm,
      "physical-person": _vm.physicalPerson,
      "countries": _vm.countries,
      "languages": _vm.languages,
      "language-kind": _vm.languageLevels
    },
    on: {
      "refresh": function refresh($event) {
        return _vm.$refs.table.refresh();
      }
    }
  }), _c('PhysicalPersonCreateEducationModal', {
    attrs: {
      "existing-form": _vm.editForm,
      "physical-person": _vm.physicalPerson,
      "countries": _vm.countries,
      "languages": _vm.languages,
      "education-kind": _vm.educationKinds
    },
    on: {
      "refresh": function refresh($event) {
        return _vm.$refs.table.refresh();
      }
    }
  }), _c('PhysicalPersonCreateEuHealthInsuranceModal', {
    attrs: {
      "existing-form": _vm.editForm,
      "physical-person": _vm.physicalPerson
    },
    on: {
      "refresh": function refresh($event) {
        return _vm.$refs.table.refresh();
      }
    }
  }), _c('PhysicalPersonCreateA1ModuleModal', {
    attrs: {
      "existing-form": _vm.editForm,
      "physical-person": _vm.physicalPerson
    },
    on: {
      "refresh": function refresh($event) {
        return _vm.$refs.table.refresh();
      }
    }
  }), _c('PhysicalPersonCreateSuitabilityWorkModal', {
    attrs: {
      "existing-form": _vm.editForm,
      "physical-person": _vm.physicalPerson
    },
    on: {
      "refresh": function refresh($event) {
        return _vm.$refs.table.refresh();
      }
    }
  }), _c('PhysicalPersonCreateEuDrivingLicenseModal', {
    attrs: {
      "existing-form": _vm.editForm,
      "physical-person": _vm.physicalPerson
    },
    on: {
      "refresh": function refresh($event) {
        return _vm.$refs.table.refresh();
      }
    }
  }), _c('PhysicalPersonCreateResidencePermitModal', {
    attrs: {
      "existing-form": _vm.editForm,
      "physical-person": _vm.physicalPerson,
      "countries": _vm.countries
    },
    on: {
      "refresh": function refresh($event) {
        return _vm.$refs.table.refresh();
      }
    }
  }), _c('LegalPersonCreateVatModal', {
    attrs: {
      "existing-form": _vm.editForm,
      "legal-person": _vm.company,
      "countries": _vm.countries,
      "saving": _vm.saving,
      "physical-person-id": _vm.physicalPerson.id
    },
    on: {
      "refresh": function refresh($event) {
        return _vm.$refs.table.refresh();
      }
    }
  }), _c('LegalPersonCreateRegistrationCertificateModal', {
    attrs: {
      "existing-form": _vm.editForm,
      "legal-person": _vm.company,
      "countries": _vm.countries
    },
    on: {
      "refresh": function refresh($event) {
        return _vm.$refs.table.refresh();
      }
    }
  }), _c('LegalPersonCreateInsThirdPartyModal', {
    attrs: {
      "existing-form": _vm.editForm,
      "legal-person": _vm.company,
      "countries": _vm.countries
    },
    on: {
      "refresh": function refresh($event) {
        return _vm.$refs.table.refresh();
      }
    }
  }), _c('LegalPersonCreateSocialSecurityModal', {
    attrs: {
      "existing-form": _vm.editForm,
      "legal-person": _vm.company,
      "countries": _vm.countries
    },
    on: {
      "refresh": function refresh($event) {
        return _vm.$refs.table.refresh();
      }
    }
  }), _c('AssociateContractModal', {
    attrs: {
      "existing-form": _vm.editForm,
      "physical-person": _vm.physicalPerson
    },
    on: {
      "refresh": function refresh($event) {
        return _vm.$refs.table.refresh();
      }
    }
  }), _c('PhysicalPersonCreatePaycheckModal', {
    attrs: {
      "existing-form": _vm.editForm,
      "physical-person": _vm.physicalPerson
    },
    on: {
      "refresh": function refresh($event) {
        return _vm.$refs.table.refresh();
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }