<template>
  <div class="form row">
    <div class="col-12">
      <validation-provider v-slot="{ errors }" :name="$t('File attachment')" vid="attachments_tus" class="w-100">
        <div id="attachmentFile">
          <euro-tus-file-input :label="`${$t('Attachment')}*`" :error-messages="errors" accept="application/pdf"
            :multiple-attachment="existingForm.attachments" :hint="$t('Accept .pdf - max size 5MB')" multiple
            @remove-attachment="removeAttachment" @change="addTusAttachment"
            @remove="removeTusAttachment"></euro-tus-file-input>
        </div>
      </validation-provider>
    </div>
    <div class="col-12">
      <validation-provider v-slot="{ errors }" :name="$t('Period')" vid="date" rules="required">
        <date-picker v-model="form.date" :overrides="periodOverride" :error-messages="errors" placeholder-string="YYYY-MM"
          :label="`${$t('Period')}*`">
        </date-picker>
      </validation-provider>
    </div>
    <div class="col-12">
      <validation-provider v-slot="{ errors }" :name="$t('Description')" vid="description">
        <euro-text-area v-model="form.description" :label="`${$t('Description')}`"
          :error-messages="errors"></euro-text-area>
      </validation-provider>
    </div>
  </div>
</template>
<script>
import DatePicker from "@/view/components/datePicker/datePicker.vue";
import moment from "moment";
const now = new Date();
export default {
  components: {
    DatePicker,
  },
  props: {
    physicalPerson: {
      type: Object,
      default: () => null,
    },
    existingForm: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      maxDate: now,
      form: {
        description: "",
        date: null,
        attachments_tus: []
      }
    }
  },
  computed: {
    periodOverride() {
      return {
        viewMode: 'years',
        format: 'YYYY-MM',
        maxDate: moment(this.maxDate, 'YYYY-MM', true).format()
      }
    },
  },
  watch: {
    form: {
      deep: true,
      handler(val) {
        this.$emit("input", val);
      },
    },
  },
  mounted() {
    if (this.existingForm) {
      this.form = { ...this.form, ...this.existingForm };
    }
  },
  methods: {
    addTusAttachment(tusId) {
      if(!this.form.attachments_tus) {
        this.form.attachments_tus = [];
      }
      this.form.attachments_tus.push(tusId);
    },
    removeTusAttachment(tusId) {
      this.form.attachments_tus = this.form.attachments_tus.filter((el) => el != tusId);
    },
    removeAttachment(id) {
      this.$emit('remove-attachment', id);
    }
  }
}
</script>
